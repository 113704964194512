<tr class="text-gray-600">
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm font-bold text-gray-800 align-middle"
  >
    {{ pitem.id }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <ui-switch
      [checked]="!!pitem.paid"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.license_plate }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm  align-middle font-bold cursor-pointer"
  >
    {{ pitem.booking?.name }}
    <span class="block font-normal">{{
      pitem.booking?.email_address
    }}</span>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.start_date }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.end_date }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(pitem.parkingPlace){
    <ui-badge
      [color]="
        pitem.parkingPlace.building?.name === 'Lehel'
          ? 'blue'
          : pitem.parkingPlace.building?.name === 'Kunigunda'
          ? 'yellow'
          : 'black'
      "
      badgeType="soft"
      cornerType="rounded"
      class="w-24 inline-block"
      >{{ pitem.parkingPlace.building?.name }}</ui-badge
    >
    <span class="inline-block ml-2">{{ pitem.parkingPlace.name }}</span>
   }
  </td>

  <td
  class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle" >
  @if(pitem.paid){
    @for (transaction of pitem.paymentTransactions; track
    transaction.id) {

    <ui-badge
      [color]="'yellow'"
      iconSrc="assets/img/credit-card.svg"
      iconClass="h-4 opacity-50"
      badgeType="soft"
      cornerType="rounded"
      class="w-24 inline-block"
      >{{ transaction.id }}</ui-badge
    >}

 }
 @else {
<!--   <ui-button
  class="mb-1 block"
    size="xsmall"
    iconSrc='assets/img/credit-card.svg'
    iconClass="invert  saturate-0  brightness-0 contrast-125"
    btnType="orange"
    > Payment transaction</ui-button>

    <ui-button
    class="block"
    size="xsmall"
    iconSrc='assets/img/credit-card.svg'
    iconClass="invert  saturate-0  brightness-0 contrast-125"
    btnType="orange"
    > Other Payment</ui-button> -->
 }
</td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm w-max">


      <ui-button
      btnType="inline"
      (click)="handleView(pitem)"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      data-hs-overlay="#hs-parking-request-editor-modal"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>

      <ui-button
      btnType="inline"
      (click)="handleEdit(pitem)"

      iconSrc="assets/img/pencil.svg"
      data-hs-overlay="#hs-parking-request-editor-modal"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>

      <ui-button
      btnType="inline"
      (click)="handleDelete(pitem)"
      inlinePos="right"

      iconSrc="assets/img/trash-2.svg"
      data-hs-overlay="#hs-delete-parking-request-confirmation-modal"
      > <span class="shrink-0 text-xs"> Delete </span>
    </ui-button>
    </div>
  </td>
</tr>
