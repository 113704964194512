import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { II11Transformer } from '../generic-api-client.service';
import { IUserRentsFlatModel } from './user-rents-flat-model.interface';
import { format, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UserRentsFlatTransformService
  implements II11Transformer<IUserRentsFlatModel>
{
  constructor(private log: LogService) {}

  transformItem(rental: IUserRentsFlatModel): IUserRentsFlatModel {
    return rental;
  }

  serializeItem(rental: IUserRentsFlatModel): IUserRentsFlatModel {
    const serializedRental: { [key: string]: any } = {};

    serializedRental['open_ended'] = rental.open_ended;
    serializedRental['openEnded'] = rental.open_ended;

    if (rental.user) {
      serializedRental['user'] = rental.user['@id'];
    }
    if (rental.flat) {
      serializedRental['flat'] = rental.flat['@id'];
    }

    try {
      if (rental.rental_start) {
        serializedRental['rental_start'] = format(
          parse(rental.rental_start, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
        serializedRental['rentalStart'] = format(
          parse(rental.rental_start, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('RentalTransformService: start_date ', error);
    }

    try {
      if (rental.rental_end) {
        serializedRental['rental_end'] = format(
          parse(rental.rental_end, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
        serializedRental['rentalEnd'] = format(
          parse(rental.rental_end, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('RentalTransformService: end_date ', error);
    }

    return serializedRental as IUserRentsFlatModel;
  }
}
