<div
  id="hs-meters-reading-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-2xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{ mode === "create" ? "Add New Meter Value" : "Edit Meter Reading" }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-meters-reading-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="reading-form"
            [formGroup]="readingForm"
            (ngSubmit)="saveMeter()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Value Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="meterValue"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Value
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <input
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      selectId="meter-value"
                      type="number"
                      formControlName="reading"
                    />
                  </div>
                </div>
                <!-- End Value Grid -->
                <!-- Timestamp Grid -->
<!--                 <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="meter-timestamp"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Timestamp
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <input
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      selectId="meter-timestamp"
                      type="datetime-local"
                      formControlName="read_at"
                    />
                  </div>
                </div> -->
                <!-- End Timestamp Grid -->
                <!-- Sampling type Grid -->
                <!-- <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="meter-sampling_type"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Sampling type
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <input
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      selectId="meter-sampling_type"
                      type="text"
                      formControlName="sampling_type"
                    />
                  </div>
                </div> -->
                <!-- End Sampling type Grid -->
                <!-- Photo Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="meter-photo"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Photo
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <input
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      selectId="meter-photo"
                      type="file"
                      (change)="onFileChange($event)"
                    />
                  </div>
                </div>
                <!-- End Photo Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-meters-reading-editor-modal"
        >
          Close
        </button>
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="readingForm.invalid"
          form="reading-form"
          data-hs-overlay="#hs-meters-reading-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
      </div>
    </div>
  </div>
</div>
