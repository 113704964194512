import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { IReadingModel } from '../../../services/api/reading/reading-model.interface';
import { ReadingClientService } from '../../../services/api/reading/reading-client.service';
import { MeterReadingTableRowComponent } from './meter-reading-table-row/meter-reading-table-row.component';
import { IMeterModel } from '../../../services/api/meter/meter-model.interface';
import { MetersReadingEditorComponent } from '../../modal-editors/meters-reading-editor/meters-reading-editor.component';

@Component({
  selector: 'meter-reading-table',
  standalone: true,
  imports: [
    ConfirmationDialogComponent,
    MeterReadingTableRowComponent,
    ReactiveFormsModule,
    TableEmptyStateComponent,
    UiButtonComponent,
    UiTablePagerComponent,
    MetersReadingEditorComponent,
  ],
  templateUrl: './meter-reading-table.component.html',
  styleUrl: './meter-reading-table.component.css',
})
export class MeterReadingTableComponent {
  @Input() meter!: IMeterModel;
  @Output() refreshData = new EventEmitter<IReadingModel[]>();
  selectedReading!: IReadingModel;
  editorMode: 'create' | 'edit' = 'create';
  readings: IReadingModel[] = [];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private readonly log: LogService,
    private readonly readingClientService: ReadingClientService,
    private readonly toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['meter']) {
      this.refresh();
    }
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedReading = {
      '@id': '',
      id: 0,
      value: 0,
      timestamp: '',
      sampling_type: '',
      photo: '',
    } as IReadingModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.log.debug('Refreshing meter reading table for meter:', this.meter);
    if (this.meter?.id) {
      this.log.debug('Fetching meter readings', this.meter.id);
      this.readingClientService
        .getMeterReadings(this.meter.id, page)
        .subscribe({
          next: (meter) => {
            this.log.debug('Readings fetched', meter);
            this.readings = meter;
            setTimeout(() => {
              this.refreshData.emit(this.readings);
              this.isLoading = false;
            }, 100);

            setTimeout(() => {
              this.log.info('Meter reading table initialized');
              window.HSStaticMethods.autoInit('overlay');
            }, 100);
          },
          error: (err) => {
            this.log.error('Error fetching meter readings', err);
            this.isLoading = false;
          },
        });
      // lekérjük az összes elem számát is a lapozónak
      this.isPagerLoading = true;
      this.readingClientService
        .getTotalItemsForMeter(this.meter.id)
        .subscribe((count) => {
          this.totalItems = count;
          this.isPagerLoading = false;
        });
    }
  }

  handleEdit(pitem: IReadingModel) {
    this.selectedReading = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IReadingModel) {
    this.selectedReading = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.readingClientService.deleteItem(this.selectedReading.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.log.info('Meter reading deleted');
        this.readings = this.readings.filter(
          (pr) => pr.id !== this.selectedReading.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.readings.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Meter reading deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting meter reading');
        this.log.error('Error deleting meter reading', err);
      },
    });
  }
}
