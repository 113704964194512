import { Component, ViewChild } from '@angular/core';
import { CleaningScheduleTableRowComponent } from './cleaning-schedule-table-row/cleaning-schedule-table-row.component';
import { CleaningScheduleEditorComponent } from '../../modal-editors/cleaning-schedule-editor/cleaning-schedule-editor.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ICleaningScheduleModel } from '../../../services/api/cleaning-schedule/cleaning-schedule-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { CleaningScheduleClientService } from '../../../services/api/cleaning-schedule/cleaning-schedule-client.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { UiButtonComponent } from "../../atomic-ui-components/button/ui-button.component";


@Component({
  selector: 'cleaning-schedule-table',
  standalone: true,
  templateUrl: './cleaning-schedule-table.component.html',
  styleUrl: './cleaning-schedule-table.component.css',
  imports: [
    CleaningScheduleTableRowComponent,
    CleaningScheduleEditorComponent,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
    UiButtonComponent
],
})
export class CleaningScheduleTableComponent {
  @ViewChild(CleaningScheduleEditorComponent)
  editor!: CleaningScheduleEditorComponent;
  selectedCleaningSchedule!: ICleaningScheduleModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  cleaningSchedules: ICleaningScheduleModel[] = [];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private cleaningScheduleService: CleaningScheduleClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: ICleaningScheduleModel) {
    this.selectedCleaningSchedule = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: ICleaningScheduleModel) {
    this.selectedCleaningSchedule = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: ICleaningScheduleModel) {
    this.selectedCleaningSchedule = pitem;
  }

  // a törlés megerősítése után töröljük a kijelölt elemet
  performDelete() {
    this.cleaningScheduleService
      .deleteCleaningSchedule(this.selectedCleaningSchedule.id)
      .subscribe({
        next: () => {
          // ha sikeres volt a törlés, akkor elhisszük, de nem kérjük le az összes elemet újra
          this.log.info('Cleaning schedule deleted successfully');
          this.cleaningSchedules = this.cleaningSchedules.filter(
            (item) => item.id !== this.selectedCleaningSchedule.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.cleaningSchedules.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Cleaning schedule deleted successfully');
        },
        error: (err) => {
          this.toastr.error('Error deleting cleaning schedule');
          this.log.error('Error deleting cleaning schedule', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedCleaningSchedule = {
      id: 0,
      cleaning_date: '',
      cleaning_done: false,
      cleaning_done_at: '',
      cleaning_note: '',
      cleaner_note: '',
      created_at: '',
      updated_at: '',
    } as ICleaningScheduleModel;
  }

  refresh(page?: number) {
    // lekérjük a takarítási beosztásokat
    this.isLoading = true;
    this.cleaningScheduleService
      .getCleaningSchedules(null, page)
      .subscribe((schedules) => {
        this.cleaningSchedules = schedules;
        setTimeout(() => {
          this.isLoading = false;
        }, 200);
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 300);
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.cleaningScheduleService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
