<div id="abc" [ngClass]="{
    'shadow-md border rounded-md p-4 bg-white': !billingItem || mode === 'edit',
'flex flex-wrap justify-between items-center': billingItem && mode !== 'create',
  }" class="">
  @if(billingItem && mode === 'view' ){
  <!-- Item -->
  <div class="flex gap-x-3">
    <!-- Item's TimeLine Icon -->
    <div class="{{
        isLast
          ? 'relative'
          : 'relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200'
      }}">
      <div class="relative z-10 size-7 flex justify-center items-center text-gray-500">
        <i-lucide [name]="billingItem.billingItemType?.['@id'] === fixedItemType ? 'pin' : 'gauge'"
          class="flex-shrink-0 mt-0.5 w-4 h-4"></i-lucide>
      </div>
    </div>
    <!-- End Item's TimeLine Icon -->


    <!-- Right Content -->
    <div class="grow pt-0.5 pb-2">
      <div class="flex flex-wrap items-center">
        <p class="mt-1 text-sm text-gray-600 w-20 basis-20 md:basis-auto mb-1">
          <ui-badge [color]="billingItem.billingItemType?.['@id'] === fixedItemType ? 'blue' : 'green'">{{
            billingItem.billingItemType?.name }}</ui-badge>
        </p>
        <p class="flex gap-x-1.5 font-semibold text-gray-800 w-24 leading-4  basis-24 md:basis-auto  mb-1">
          {{ billingItem.name }}
        </p>
        <p class="mt-1 text-sm text-gray-600 flex w-32 mr-2  basis-32 md:basis-auto  mb-1">
          <span>
            @if(!billingItem.tax){ - }@else{ {{ billingItem.tax.name }} ({{
            billingItem.tax.percentage
            }}%) }
          </span>
        </p>

        <p class="mt-1 text-sm text-gray-600 w-36 mr-2 whitespace-nowrap basis-36 md:basis-auto  mb-1">
          <span class="text-right w-24 inline-block pr-1 whitespace-nowrap">{{
            billingItem.unit_price
            | currency : billing?.currency?.code + ' '
            | correctCurrencyFormat
            }}</span>
          <span class="mt-1 text-sm w-10 inline-block whitespace-nowrap">
            @if(billingItem.billingItemType?.['@id'] !== fixedItemType){ /
            {{ billingItem.unit?.name }} }</span>
        </p>

        <p class="mt-1 text-sm text-gray-600 w-32 whitespace-nowrap  basis-32 md:basis-auto  mb-1">
          @if(billingItem.billingItemType?.['@id'] !== fixedItemType){
          {{ billingItem.meter?.meterType?.name }} }
        </p>
      </div>
      <!-- <p class="mt-1 text-sm text-gray-600">Meter: {{ item.meter?.id }}</p> -->
    </div>

    <!-- End Right Content -->
  </div>
  <!-- End Item -->
  } @else {
  <form id="billing-item-form" [formGroup]="billingItemForm" (ngSubmit)="saveBillingItem()">
    <div class="flex flex-wrap items-center mb-4 gap-2">
      <p class="text-sm text-gray-600">
        <ng-select [items]="billingItemTypeSelectItems" [multiple]="false" [clearable]="false" bindLabel="title"
          bindValue="val" class="w-32 text-left" placeholder="Type" formControlName="billingItemType"></ng-select>
      </p>
      <input type="text"
        class="py-2 px-3 inline-block w-32 border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        placeholder="Name" formControlName="name" />
      <ng-select [items]="taxSelectItems" [multiple]="false" bindLabel="title" bindValue="val" class="w-40 text-left"
        placeholder="Tax" formControlName="tax"></ng-select>
      <p class="text-sm text-gray-600 flex w-64+w-2">
        <input type="number"
          class="w-32 py-2 px-3 block border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
          placeholder="Price" formControlName="unit_price" />
        <span class="mx-1 self-center px-2" [ngClass]="{ hidden: selectedItemType === fixedItemType }">
          /</span>
        <span class="w-32" [ngClass]="{ hidden: selectedItemType === fixedItemType }">
          <ng-select [hidden]="selectedItemType === fixedItemType" [items]="unitSelectItems" [multiple]="false"
            [clearable]="false" bindLabel="title" bindValue="val" class="w-32 text-left" placeholder="Unit"
            formControlName="unit"></ng-select>
        </span>
      </p>
      <span class="w-32" [ngClass]="{ hidden: selectedItemType === fixedItemType }">
        <ng-select [hidden]="selectedItemType === fixedItemType" [items]="meterSelectItems" [multiple]="false"
          bindLabel="title" bindValue="val" class="w-32 text-left" placeholder="Meter"
          formControlName="meter"></ng-select>
      </span>
    </div>
  </form>
  <!-- START SAVE CONTROLS-->
  @if(mode === 'create'){
  <div class="flex w-full justify-center gap-4 mt-4">
    <ui-button type="submit" form="billing-form" (click)="saveBillingItem()" iconSrc="assets/img/save.svg"
      iconClass="invert  saturate-0  brightness-0 contrast-125" size="xsmall"
      [disabled]="billingItemForm.pristine || billingItemForm.invalid">Save</ui-button>

    <ui-button btnType="outline-negative" size="xsmall" (click)="cancelCreation()">Cancel</ui-button>
  </div>
  }@else {
  <div class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium">
    <div class="inline-flex gap-2 rounded-lg shadow-sm">
      <ui-button type="submit" form="billing-form" (click)="saveBillingItem()" iconSrc="assets/img/save.svg"
        iconClass="invert  saturate-0  brightness-0 contrast-125" size="xsmall"
        [disabled]="billingItemForm.pristine || billingItemForm.invalid">
        <span class="shrink-0 text-xs"> Save </span>
      </ui-button>
      <ui-button btnType="outline-negative" size="xsmall" (click)="cancelEdit()">
        <span class="shrink-0 text-xs"> Cancel </span>
      </ui-button>
    </div>
  </div>
  }
  <!-- END SAVE CONTROLS-->
  } @if(mode === 'view'){
  <div class="px-0 py-2 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium basis-full xl:basis-auto  xxBUTTON">
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button btnType="inline" (click)="editBillingItem()" inlinePos="left" iconSrc="assets/img/pencil.svg">
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>
      <ui-button btnType="inline" (click)="deleteBillingItem()" inlinePos="right" iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-billing-item-confirmation-modal">
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </div>
  }
</div>