import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { IUiSelectItem } from '../atomic-ui-components/select/ui-select-item.interface';
import { IFlatModel } from '../../services/api/flat/flat-model.interface';
import { LogService } from '../../services/utils/log.service';
import { HardCodedConfigService } from '../../services/utils/hard-coded-config.service';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import { SelectItemLoaderService } from '../../services/utils/select-item-loader.service';
import { IContractModel } from '../../services/api/contract/contract-model.interface';
import { FormsModule } from '@angular/forms';
import { LucideAngularModule } from 'lucide-angular';
import { UiButtonComponent } from '../atomic-ui-components/button/ui-button.component';
import { ContractToolsService } from '../../services/utils/contract-tools.service';

@Component({
  selector: 'property-and-contract-box',
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    LucideAngularModule,
    UiButtonComponent,
  ],
  templateUrl: './property-and-contract-box.component.html',
  styleUrls: ['./property-and-contract-box.component.css'],
})
export class PropertyAndContractBoxComponent {
  @Input() flat?: IFlatModel;
  @Output() contractChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() contractCreateInitiated: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() contractEditInitiated: EventEmitter<void> =
    new EventEmitter<void>();
  contractSelectItems: IUiSelectItem[] = [];
  selectedContract: IUiSelectItem | null = null;
  defaultContract!: IContractModel;
  propertyTitle = '';
  propertyAddress = '';
  isExpired = false;

  constructor(
    private log: LogService,
    private hardCodedConfig: HardCodedConfigService,
    private formatter: ModelFormatterService,
    private silo: SelectItemLoaderService,
    private contractTools: ContractToolsService
  ) {}

  ngOnInit() {
    if (this.flat) {
      this.log.debug('PropertyAndContractBoxComponent init', this.flat);
      // kikeressük a contractok közül azt, amelyik a legfrissebb
      const mostRecentContract =
        this.contractTools.getMostRecentContractForFlat(this.flat);
      if (mostRecentContract) {
        this.defaultContract = mostRecentContract;
      }
      // megformázzuk az ingatlan infókat
      this.propertyTitle = this.formatter.getFormattedFlat(
        this.flat,
        true,
        true
      );
      //TODO ez még be van drótozva, később majd a backendtől jöjjön
      const building = this.hardCodedConfig.BUILDING_CONFIG.find(
        (building) => building.id === this.flat?.building?.id
      );
      this.propertyAddress = building ? building.address : '';

      // betöltjük a szerződéseket a dropdownba
      this.silo.getContractSelectItemsForFlat(this.flat).subscribe({
        next: (items: IUiSelectItem[]) => {
          this.contractSelectItems = items;
          // ha van default contract, akkor azt választjuk ki
          if (!this.selectedContract && this.defaultContract) {
            this.selectedContract =
              this.contractSelectItems.find(
                (item) => item.val === this.defaultContract?.['@id']
              ) ?? this.contractSelectItems[0];
          }
          // akár van contract, akár nincs, az eventet mindenképpen küldjük
          this.contractChanged.emit(this.selectedContract?.val ?? '');
          this.checkExpiration();
        },
        error: (err) => {
          this.log.error('Error loading contract select items', err);
        },
      });
    }
  }

  onContractChange(event: IUiSelectItem) {
    this.log.debug('Contract selection changed', event);
    this.checkExpiration();
    this.contractChanged.emit(event.val);
  }

  initiateContractCreation() {
    this.contractCreateInitiated.emit();
  }

  initiateContractEdit() {
    this.contractEditInitiated.emit();
  }

  checkExpiration() {
    //kikeressük a contractok közül azt, amelyik a selectedContract
    const selectedContract = this.flat?.contracts?.find(
      (contract) => contract['@id'] === this.selectedContract?.val
    );
    if (selectedContract) {
      // ha a szerződés nyitott végű, akkor nem jár le
      if (selectedContract.open_ended) {
        this.isExpired = false;
      } else {
        // ha a szerződés lejárt, akkor lejárt
        const now = new Date().getTime();
        const end = new Date(selectedContract.tenancy_ends_at ?? '').getTime();
        this.isExpired = now > end;
      }
    }
  }
}
