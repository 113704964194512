import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { IBillingAddressModel } from './billing-address-model.interface';
@Injectable({
  providedIn: 'root',
})
export class BillingAddressClientService {
  private totalItems!: number;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * A számlázási címek lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A számlázási címek lekért oldala, nyers válaszban
   */
  private fetchBillingAddresses(page?: number) {
    // ennek segítségével tudunk lapozni pl. .../billing-addresses?page=2
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.billingAddresses), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri az a számlázási címek első oldalát, hogy megtudja az összes cím számát
   * @returns Az összes számlázási cím számossága
   */
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchBillingAddresses().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * A számlázási címek lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A számlázási címek lekért oldala
   */
  getBillingAddresses(page?: number): Observable<IBillingAddressModel[]> {
    // hívjuk a fetchBillingAddresses-t, majd a választ feldolgozzuk
    return this.fetchBillingAddresses(page).pipe(
      map((response: any) => {
        const billingAddresses = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
        // ezt adjuk vissza, ez már csak a billingAddresses tömb
        return billingAddresses;
      })
    );
  }

  /**
   * Egy számlázási cím törlése a szerverről
   * @param id A törölni kívánt számlázási cím azonosítója
   * @returns A törlés eredménye
   */
  deleteBillingAddress(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.billingAddress) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy számlázási cím létrehozása a szerveren
   * @param billingAddress A létrehozandó számlázási cím
   * @returns A létrehozott számlázási cím
   */
  createBillingAddress(
    billingAddress: IBillingAddressModel
  ): Observable<IBillingAddressModel> {
    // csinálunk egy új objektumot, amiben se id, se @id nincs
    // mert a szerver nem fogadja el
    billingAddress = { ...billingAddress };
    const { id, '@id': atId, ...newBillingAddress } = billingAddress;

    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.billingAddresses),
        newBillingAddress,
        { headers }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IBillingAddressModel)
      );
  }

  /**
   * Egy számlázási cím módosítása a szerveren
   * @param billingAddress A módosítandó számlázási cím
   * @returns A módosított számlázási cím
   */
  updateBillingAddress(billingAddress: IBillingAddressModel): Observable<any> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.billingAddress) +
          billingAddress.id,
        billingAddress,
        { headers }
      )
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'BillingAddressClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform billing addresses operation')
    );
  };
}
