<tr>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.flatType?.name}}
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.building?.name }}
  </td>  
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.floor?.name }}
  </td>    
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.name }}
  </td>      
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.flat_number }}
  </td>        
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.size }}
  </td>        
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    {{ pitem.number_of_rooms }}
  </td>  
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle ">
    <ui-switch
    [checked]="!!pitem.for_rent"
    size="x-small"
    [disabled]="true"
  ></ui-switch>
  </td>    
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle "
  >
    <div class="inline-flex rounded-lg shadow-sm w-max">
      <ui-button
      btnType="inline"
      [routerLink]="['/properties', pitem.id]"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
<!--     <ui-button
      btnType="inline"
      [routerLink]="['/properties', pitem.id, { edit: true }]"
      iconSrc="assets/img/pencil.svg"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button> -->
    <ui-button
    btnType="inline"
    (click)="handleDelete(pitem)"
    inlinePos="right"

    iconSrc="assets/img/trash-2.svg"
    data-hs-overlay="#hs-delete-issue-confirmation-modal"
    > <span class="shrink-0 text-xs"> Delete </span>
  </ui-button>

    </div>
  </td>            
  <!-- 

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle w-72"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
      btnType="inline"
      [routerLink]="['/issues', pitem.id]"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
    <ui-button
      btnType="inline"
      [routerLink]="['/issues', pitem.id, { edit: true }]"
      iconSrc="assets/img/pencil.svg"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>
    <ui-button
    btnType="inline"
    (click)="handleDelete(pitem)"
    inlinePos="right"

    iconSrc="assets/img/trash-2.svg"
    data-hs-overlay="#hs-delete-issue-confirmation-modal"
    > <span class="shrink-0 text-xs"> Delete </span>
  </ui-button>

    </div>
  </td> -->
</tr>
