import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { II11Transformer } from '../generic-api-client.service';
import { IUserModel } from './user-model.interface';

@Injectable({
  providedIn: 'root',
})
export class UserTransformService implements II11Transformer<IUserModel> {
  constructor(private log: LogService) {}

  transformItem(user: IUserModel): IUserModel {
    return user;
  }

  serializeItem(user: IUserModel): IUserModel {
    const serializedTenant: any = { ...user };

    if(user.billingAddress) {
      serializedTenant.billingAddress = user.billingAddress['@id'];
    }

    return serializedTenant;
  }
}
