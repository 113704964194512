import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { IInvoiceModel } from './invoice-model.interface';
import { format, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class InvoiceTransformService {
  constructor(private log: LogService) {}

  transformInvoice(invoice: IInvoiceModel): IInvoiceModel {
    try {
      if (invoice.fulfillment_date !== undefined) {
        invoice.fulfillment_date = format(
          parse(
            invoice.fulfillment_date,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('InvoiceTransformService: fulfillment_date ', error);
    }
    try {
      if (invoice.invoice_date !== undefined) {
        invoice.invoice_date = format(
          parse(invoice.invoice_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('InvoiceTransformService: invoice_date ', error);
    }
    try {
      if (invoice.payment_date !== undefined) {
        invoice.payment_date = format(
          parse(invoice.payment_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('InvoiceTransformService: payment_date ', error);
    }
    try {
      if (invoice.due_date !== undefined) {
        invoice.due_date = format(
          parse(invoice.due_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('InvoiceTransformService: due_date ', error);
    }
    // TODO: the rest of the fields
    return invoice;
  }

  serializeInvoice(invoice: IInvoiceModel): IInvoiceModel {
    const serializedInvoice: any = { ...invoice };

    // Dátum mezők átalakítása ISO stringgé
    if (serializedInvoice.fulfillment_date !== undefined) {
      try {
        serializedInvoice.fulfillment_date = format(
          parse(serializedInvoice.fulfillment_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('InvoiceTransformService: fulfillment_date ', error);
      }
    }
    if (serializedInvoice.invoice_date !== undefined) {
      try {
        serializedInvoice.invoice_date = format(
          parse(serializedInvoice.invoice_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('InvoiceTransformService: invoice_date ', error);
      }
    }
    if (serializedInvoice.payment_date !== undefined) {
      try {
        serializedInvoice.payment_date = format(
          parse(serializedInvoice.payment_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('InvoiceTransformService: payment_date ', error);
      }
    }
    if (serializedInvoice.due_date !== undefined) {
      try {
        serializedInvoice.due_date = format(
          parse(serializedInvoice.due_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('InvoiceTransformService: due_date ', error);
      }
    }

    // Többi mező stringgé alakítása
    for (const key in serializedInvoice) {
      if (
        typeof serializedInvoice[key] !== 'string' &&
        key !== 'paid' &&
        key !== 'issued'
      ) {
        // TODO: InvoiceItems
        serializedInvoice[key] = String(serializedInvoice[key]);
      }
    }
    this.log.debug(
      'InvoiceTransformService: serializedInvoice',
      serializedInvoice
    );
    return serializedInvoice;
  }
}
