import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { ReadingClientService } from '../../../services/api/reading/reading-client.service';
import { IReadingModel } from '../../../services/api/reading/reading-model.interface';
import { IMeterModel } from '../../../services/api/meter/meter-model.interface';

@Component({
  selector: 'meters-reading-editor',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './meters-reading-editor.component.html',
  styleUrl: './meters-reading-editor.component.css',
})
export class MetersReadingEditorComponent {
  @Input() reading!: IReadingModel;
  @Input() meter!: IMeterModel;
  @Input() mode: 'create' | 'edit' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  selectedFile: File | null = null;

  readingForm: FormGroup = this.fb.group({
    reading: ['', Validators.required],
    //read_at: [''],
    //sampling_type:  ['']
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private readingClientService: ReadingClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('Meter Editor Changed', this.reading);
    this.readingForm.reset();

    if (this.mode === 'edit') {
      this.readingForm.patchValue(this.reading);
    }
  }

  saveMeter() {
    this.log.info('Saving Meter Value');
    if (this.readingForm.valid) {
      const updatedUntypedReading: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.readingForm.controls).forEach((key) => {
        const control = this.readingForm.get(key);
        if (control?.dirty) {
          updatedUntypedReading[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedReading).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedReading = updatedUntypedReading as IReadingModel;
      updatedReading.meter = this.meter;
      this.log.debug('Meter Reading request:', updatedReading);

      if (this.mode === 'edit') {
        updatedReading.id = this.reading.id;
        this.readingClientService.updateItem(updatedReading).subscribe({
          next: (data) => {
            this.toastr.success('Meter Reading updated');
            this.log.debug('Meter Reading updated:', data);
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error updating Meter Reading');
            this.log.error('Error updating Meter Reading:', err);
          },
        });
      } else {
        this.readingClientService.createItem(updatedReading).subscribe({
          next: (data) => {
            if (this.selectedFile) {
              this.readingClientService
                .uploadReadingFile(data.id, this.selectedFile)
                .subscribe({
                  next: (response) => {
                    this.log.debug('Upload successful:', response);
                    this.toastr.success('Meter Reading created!');
                    this.onCreate.emit();
                  },
                  error: (error) => {
                    this.log.error('Upload failed, starting cleanup', error);
                    this.readingClientService.deleteItem(data.id).subscribe({
                      next: () => {
                        this.log.debug('Meter Reading deleted after a failed upload');
                        this.toastr.error('Document upload failed!');
                      },
                      error: (error) => {
                        this.log.error(
                          'Error deleting meter reading after failed upload',
                          error
                        );
                      },
                    });
                  },
                });
            } else {
              this.toastr.success('Meter Reading created');
              this.log.debug('Meter Reading created without file:', data);
              this.onCreate.emit();
            }
          },
          error: (err) => {
            this.toastr.error('Error creating Meter Reading');
            this.log.error('Error creating Meter Reading:', err);
          },
        });
      }
    }
  }
  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
    this.log.debug('Selected file: ' + this.selectedFile?.name);
  }
}
