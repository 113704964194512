<tr>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    <ui-badge
      [color]="
        pitem.building === 'Lehel'
          ? 'blue'
          : pitem.building === 'Kunigunda'
          ? 'yellow'
          : 'black'
      "
      badgeType="soft"
      cornerType="rounded"
      class="w-24 inline-block"
      >{{ pitem.building }}</ui-badge
    >
    <span class="inline-block ml-2">{{ pitem.flat }}</span>
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    <div class="pt-2 inline-block ">
      <span
        class="inline-block h-4 w-4 mr-1 stroke-gray-500 leading-4 {{
          flagColor(pitem.priority)
        }}"
        style="mask-image: url('assets/img/flag.svg')"
      ></span>
    </div>
  </td>
  <!-- whitespace-nowrap nélkül ez a cella kifolyik a méretből -->
  <td class="px-4 py-3 ps-4 leading-4  text-sm text-gray-800">
    <ui-badge
      [color]="pitem.typeColor"
      badgeType="soft"
      cornerType="rounded"
      class="w-full inline-block"
      >{{ pitem.type }}</ui-badge
    >
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    @if(pitem.cleanable === false){
    <ui-badge color="orange" badgeType="soft">knock-knock</ui-badge>
    } @else if(pitem.cleanable === true){
    <ui-badge color="green" badgeType="solid">yes</ui-badge>
    } @else {
    <div class="px-4 ps-4 leading-4 whitespace-nowrap animate-pulse">
      <div class="h-4 bg-gray-200 rounded w-full"></div>
    </div>
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-center whitespace-nowrap text-sm text-gray-800"
  >
    @if(pitem.done){
    <div class="mb-2 leading-4">{{ pitem.done }}</div>
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800"
  >
    <div class="hs-tooltip inline-block">
      <span class="hs-tooltip-toggle"
        >{{ pitem.cleaningNote | slice : 0 : 30 }}@if (pitem.cleaningNote && pitem.cleaningNote.length >
        30){ ... }</span
      >
      <span
        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm"
        role="tooltip"
      >
        {{ pitem.cleaningNote }}
      </span>
    </div>
  </td>
  <td
  class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800"
>
  <div class="hs-tooltip inline-block">
    <span class="hs-tooltip-toggle"
      >{{ pitem.cleanerNote | slice : 0 : 30 }}@if (pitem.cleanerNote && pitem.cleanerNote.length >
      30){ ... }</span
    >
    <span
      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm"
      role="tooltip"
    >
      {{ pitem.cleanerNote }}
    </span>
  </div>
</td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-end text-xs font-medium align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm w-max">
      @if(!pitem.done){
      <ui-button

        btnType="inline"
        inlinePos="left"
        iconSrc="assets/img/check.svg"
        iconClass="invert saturate-0 brightness-0 contrast-125"
        (click)="handleMarkDone(pitem)"
        ><span class="shrink-0 text-xs"> Mark Done </span></ui-button
      >
      }
      <ui-button

        btnType="inline"
        [inlinePos]="pitem.done ? 'solo' : 'right'"
        iconSrc="assets/img/document-text.svg"
        iconClass="invert saturate-0 brightness-0 contrast-125"
        data-hs-overlay="#hs-cleaning-schedule-note-editor-modal"
        (click)="handleEdit(pitem)"
        ><span class="shrink-0 text-xs"> Edit Notes </span></ui-button
      >
    </div>
  </td>
</tr>
