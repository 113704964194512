<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0 overflow-x-auto"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-brand-colored-canvas focus:outline-none focus:text-brand-colored-canvas"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible cursor-default text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span class="flex items-center text-sm cursor-default leading-5">
        Long-term leasing
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-brand-colored-canvas leading-5 font-semibold"
      >
        Properties
      </span>
    </li>
  </ol>
  <!-- BREADCRUMB -->
  @if(isFiltered){
  <div
    class="p-4 bg-white border border-gray-200 shadow-sm rounded-xl text-gray-800"
  >
    <properties-table [ids]="filter"></properties-table>
  </div>
  }@else{
  <!-- TODO: Egyelőre flat table a properties helyett :(  -->
  <flat-table></flat-table>
  }
</layout-main>
