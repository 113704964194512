import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from './api/api.service';
import { LocalStorageService } from './storage/local-storage.service';
import { AppThemes, ThemeService } from './theme/theme.service';
import { LogService } from './utils/log.service';

const STORAGE_TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  //JWT token
  private token: string = '';

  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService,
    private readonly storageClient: LocalStorageService,
    private readonly themeService: ThemeService,
    private readonly log: LogService
  ) {}

  /**
   * Hitelesíti a felhasználót. Ha elsőre nem sikerül, 3x próbálja meg újra, mielőtt elhasalna.
   * @param email A felhasználónév
   * @param password A jelszó
   * @returns
   */
  loginUser(email: string, password: string) {
    return this.http
      .post<{ token: string }>(this.apiService.getUrlFor(API_ENDPOINTS.login), {
        email,
        password,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        tap(({ token }) => {
          this.token = token;
          this.storageClient.setItem(STORAGE_TOKEN_KEY, token);
          this.setThemeBasedOnUsername(email);
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Kijelentkezteti a felhasználót
   */
  logoutUser() {
    this.storageClient.removeItem(STORAGE_TOKEN_KEY);
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Login error:', error.status, error.error);
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => error);
  }

  /**
   * Visszaadja az autentikációs JWT tokent
   * @returns Autentikációs token
   */
  getUserToken(): string {
    return this.storageClient.getItem(STORAGE_TOKEN_KEY);
  }

  /**
   * Visszaadja a bejelentkezett felhasználó nevét a JWT-ből
   * @returns A felhasználó nevét, ha be van jelentkezve, egyébként üres stringet
   */
  getUserName(): string {
    const token = this.getUserToken();
    if (token) {
      const tokenPayload = this.parseJwt(token);
      return tokenPayload.username;
    }
    return '';
  }

  /**
   * Visszaadja a bejelentkezett felhasználó szerepköreit a JWT-ből
   * @returns A bejelentkezett felhasználó szerepköreit
   */
  getUserRoles(): string[] {
    const token = this.getUserToken();
    if (token) {
      const tokenPayload = this.parseJwt(token);
      return tokenPayload.roles;
    }
    return [];
  }

  /**
   * Ellenőrzi, hogy be van-e jelentkezve a user
   * @returns Igaz, ha be a user van jelentkezve és még valid a tokenje, egyébként hamis
   */
  isUserLoggedIn(): boolean {
    // check for the jwt token expiration
    const token = this.getUserToken();
    if (token) {
      const tokenPayload = this.parseJwt(token);
      const expirationDate = new Date(tokenPayload.exp * 1000);
      const currentDate = new Date();
      return expirationDate > currentDate;
    }
    return false;
  }

  /**
   * Felhasználói adatokat tartalmazó JWT token dekódolása
   * @param token A JWT token
   * @returns A tokenben található adatok
   */
  private parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  /**
   * Beállítja a felhasználóhoz tartozó témát
   * @param email a felhasználó email címe
   */
  private setThemeBasedOnUsername(email: string) {
    let theme = this.themeService.defaultTheme;
    if (
      email.includes('grandum@i11.hu') ||
      email.includes('grandum-admin@i11.hu')
    ) {
      theme = AppThemes.grandum;
    } else if (
      email.includes('urbanrent@i11.hu') ||
      email.includes('urbanrent-admin@i11.hu')
    ) {
      theme = AppThemes.urbanrent;
    } else if (
      email.includes('rentingo@i11.hu') ||
      email.includes('rentingo-admin@i11.hu')
    ) {
      theme = AppThemes.rentingo;
    } else if (email.includes('gordon@i11.hu')) {
      theme = AppThemes.grandum;
    }

    // ITT LEHET DEBUGNÁL TÉMÁT VÁLTANI
    // theme = AppThemes.rentingo;
    // KI KELL KOMMENTELNI MUNKA UTÁN

    this.themeService.setTheme(theme);
    this.log.info('Setting theme to: ' + theme);
  }
}
