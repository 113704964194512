<header
  class="lg:ms-[260px] fixed top-0 inset-x-0 flex w-screen lg:w-auto flex-wrap md:justify-start md:flex-nowrap z-50 bg-brand-colored-canvas border-b border-gray-200 text-brand-colored-canvas-text ">
  <div class="flex justify-between xl:grid xl:grid-cols-3 basis-full items-center w-full py-2.5 px-2 sm:px-5"
    aria-label="Global">
    <div class="xl:col-span-1 flex items-center md:gap-x-3">
      <!-- Sidebar Toggle -->
      <div class="lg:hidden">


      <button type="button"
          class="w-7 h-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
          data-hs-overlay="#hs-pro-sidebar" aria-controls="hs-pro-sidebar" aria-label="Toggle navigation">
          <img src="assets/img/toggle.svg" alt="toggle" class="">
        </button>
      </div>
      <!-- End Sidebar Toggle -->
    </div>

    <div class="h-8 xl:col-span-2 flex justify-end items-center gap-x-2">
      <div class="flex items-center">
      </div>
      <span class="text-sm font-medium">
        {{ userName}}
      </span>
      <!-- Vertical Separator -->
      <div class="border-e border-gray-200 w-px h-6 mx-1.5"></div>
      <!-- End Vertical Separator -->
      <div class="h-8">
        <!-- Logout -->
        <div class="relative inline-flex [--placement:top-right] [--strategy:absolute] [--auto-close:inside] text-brand-colored-canvas-text">
          <ui-button (click)="performLogout()" iconAlt="Log out" iconPos="right" iconSrc="assets/img/log-out.svg" btnType="outline" size="xsmall" iconClass="forced-gray">
            Logout
          </ui-button>
        </div>
        <!-- End Logout -->
      </div>
    </div>
  </div>
</header>
