<div
  id="hs-parking-request-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-2xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Parking Request"
              : mode === "edit"
              ? "Edit Parking Request"
              : "View Parking Request"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-parking-request-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="parking-request-form"
            [formGroup]="parkingRequestForm"
            (ngSubmit)="saveParkingRequest()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Booking Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="parkingRequestBooking"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Booking
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        parkingRequest.booking?.name +
                          " (" +
                          parkingRequest.booking?.start_date +
                          " - " +
                          parkingRequest.booking?.end_date +
                          ")"
                      }}
                    </p>
                    } @else {
                 <!--   <ui-select
                      selectId="parkingRequestBooking"
                      [selectItems]="bookingSelectItems"
                      [selectedOptionValue]="parkingRequest.booking?.['@id']"
                      formControlName="booking"
                    ></ui-select> -->
                    <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    selectId="booking-contact-gender-select"
                    [items]="parkingSPaceSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="booking"
                  /> 
                    }
                  </div>
                </div>
                <!-- End Booking Grid -->
                <!-- Start Date Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="hs-pro-daufnm"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Start Date
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ parkingRequest.start_date }}
                    </p>
                    } @else {
                    <input
                      id="hs-pro-daufnm"
                      type="date"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="parkingRequest.start_date"
                      formControlName="start_date"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- Start Date Grid -->

                <!-- End Date Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="hs-pro-daufnm"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      End Date
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ parkingRequest.end_date }}
                    </p>
                    } @else {
                    <input
                      id="hs-pro-daufnm"
                      type="date"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="parkingRequest.end_date"
                      formControlName="end_date"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Date Grid -->
                <!-- Paid Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="parkingRequestPaid"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Paid
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                      switchId="parkingRequestPaid"
                      [disabled]=true
                      [checked]="!!parkingRequest.paid"

                      formControlName="paid"
                    ></ui-switch>

                    </p>
                    } @else {
                    <ui-switch
                      switchId="parkingRequestPaid"
                      [checked]="!!parkingRequest.paid"
                      formControlName="paid"
                    ></ui-switch>
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Paid Grid -->

                <!-- License Plate Unkown Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="parkingRequestLicensePlateUnkown"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Unknown License Plate
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                      switchId="parkingRequestLicensePlateUnkown"
                      [disabled]=true
                      [checked]="!!parkingRequest.license_plate_not_known"
                      formControlName="license_plate_not_known"
                    ></ui-switch>
                    </p>
                    } @else {
                    <ui-switch
                      switchId="parkingRequestLicensePlateUnkown"
                      [checked]="!!parkingRequest.license_plate_not_known"
                      formControlName="license_plate_not_known"
                    ></ui-switch>
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End License Plate Unkown Grid -->

                <!-- License Plate Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="parkingRequestLicensePlate"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      License Plate
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        parkingRequest.license_plate_country_code
                          ? parkingRequest.license_plate_country_code + " | "
                          : ""
                      }}{{ parkingRequest.license_plate }}
                    </p>
                    } @else {
                    <div
                      class="grid sm:grid-cols-8 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                    >
                      <ng-select
                        class="sm:col-span-4"
                        [items]="countries"
                        bindLabel="title"
                        bindValue="val"
                        formControlName="license_plate_country_code"
                        [virtualScroll]="true"
                        placeholder="Select Country Code"
                      >
                        <ng-template ng-label-tmp let-item="item">
                          <div>
                            <div class="flex items-center">
                              <div class="me-2">
                                <img
                                  class="inline-block size-4 rounded-full"
                                  [src]="item.options?.icon"
                                  [alt]="item.options?.description"
                                />
                              </div>
                              <div class="text-gray-800">
                                {{ item.val }}
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                          <div>
                            <div class="flex items-center">
                              <div class="me-2">
                                <img
                                  class="inline-block size-4 rounded-full"
                                  [src]="item.options?.icon"
                                  [alt]="item.options?.description"
                                />
                              </div>
                              <div class="text-gray-800">
                                {{ item.val }}
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                      <input
                      id="parkingRequestLicensePlate"
                      type="text"
                      class="sm:col-span-4 py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="license_plate"
                    />
                    </div>
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End License Plate Grid -->
                <!-- Parking Place Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="parkingRequestParkingPlace"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Parking Place
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        parkingRequest.parkingPlace?.flat_number
                          ? parkingRequest.parkingPlace?.flat_number
                          : ""
                      }}{{
                        " " + parkingRequest.parkingPlace?.name
                          ? parkingRequest.parkingPlace?.name
                          : ""
                      }}
                    </p>
                    } @else {
                <!--    <ui-select
                      selectId="parkingRequestParkingPlace"
                      [selectItems]="parkingSPaceSelectItems"
                      [selectedOptionValue]="parkingRequest.parkingPlace?.['@id']"
                      formControlName="parkingPlace"
                    ></ui-select> -->
                    <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    selectId="parkingRequestParkingPlace"
                    [items]="parkingSPaceSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="parkingPlace"
                  />                      
                    }
                  </div>
                </div>
                <!-- End Parking Place Grid -->
                <!-- Disabled Parking Place Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="parkingRequestDisabledParkingPlace"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Disabled Parking Place
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ parkingRequest.disabled_parking_place }}
                    </p>
                    } @else {
                    <ui-switch
                      switchId="parkingRequestDisabledParkingPlace"
                      [checked]="!!parkingRequest.disabled_parking_place"
                      formControlName="disabled_parking_place"
                    ></ui-switch>
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Disabled Parking Place Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-parking-request-editor-modal"
        >
          Close
        </button>
        @if (mode != 'view') {
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="parkingRequestForm.invalid"
          form="parking-request-form"
          data-hs-overlay="#hs-parking-request-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
        }
      </div>
    </div>
  </div>
</div>
