import { Injectable } from '@angular/core';

// kulcs az alkalmazás localStorage-ban tárolt témájához
const STORAGE_APP_THEME_KEY: string = 'app_theme';

// milyen témában jelenhet meg egyáltalán az alkalmazás
export enum AppThemes {
  smartapart = 'theme-default',
  grandum = 'theme-grandum',
  urbanrent = 'theme-urbanrent',
  rentingo = 'theme-rentingo',
  // TODO: ide kell felvenni új sort, ha új téma kerül bevezetésre
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  // ezek az elérhető témák
  private readonly THEMES = [
    AppThemes.smartapart,
    AppThemes.grandum,
    AppThemes.urbanrent,
    AppThemes.rentingo,
    // TODO: ide kell felvenni új sort, ha új téma kerül bevezetésre
  ];
  // az alapértelmezett téma
  readonly defaultTheme = AppThemes.smartapart;
  

  constructor() {
    this.initTheme();
  }

  initTheme() {
    const theme =
      localStorage.getItem(STORAGE_APP_THEME_KEY) ?? this.defaultTheme;
    this.setTheme(theme, false);
  }

  setTheme(theme: string, saveInStore = true) {
    if (saveInStore) {
      localStorage.setItem(STORAGE_APP_THEME_KEY, theme);
    }

    const htmlClassList = document.querySelector('html')?.classList;

    htmlClassList?.remove(...this.THEMES);
    htmlClassList?.add(theme);
  }

  getTheme() {
    return localStorage.getItem(STORAGE_APP_THEME_KEY) ?? this.defaultTheme;
  }
}
