<aside
  id="hs-pro-sidebar"
  class="w-[260px] hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform fixed inset-y-0 start-0 z-[60] bg-brand-colored-canvas border-e border-brand-colored-canvas-compl lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 hidden"
>
  <div class="flex flex-col h-full max-h-full py-3">
    <!-- Header -->
    <header class="px-5 mb-2 h-[80px]">
      <!-- Logo -->
      <a
        class="flex-none rounded-md text-xl w-full inline-block font-semibold focus:outline-none focus:opacity-80"
        routerLink="/"
        aria-label="SmartApart"
      >
        @if(theme === availableThemes.smartapart) {
        <img
          src="assets/img/i11-company-logo.svg"
          alt="SmartApart"
          class="w-full h-auto"
        />
        } @if(theme === availableThemes.grandum) {
        <img
          src="assets/img/grandum_logo_feher.svg"
          alt="SmartApart"
          class="w-full h-auto"
        />
        } @if(theme === availableThemes.urbanrent) {
        <img
          src="assets/img/urbanRentLogo.svg"
          alt="SmartApart"
          class="w-full h-auto"
        />
        } @if(theme === availableThemes.rentingo) {
        <div class="px-4 pt-2">
          <img
            src="assets/img/rentingo_logo.svg"
            alt="Rentingo"
            class="w-full h-auto"
          />
        </div>
        }
        <!-- TODO: logót felvenni ha új téma van -->
      </a>
      <!-- End Logo -->
      <!-- TODO: powered by SmartApart felirat -->
    </header>
    <!-- End Header -->

    <!-- Content -->
    <div
      class="h-[calc(100%-35px)] lg:h-[calc(100%-93px)] overflow-y-auto [&amp;::-webkit-scrollbar]:w-2 [&amp;::-webkit-scrollbar-thumb]:rounded-full [&amp;::-webkit-scrollbar-track]:bg-brand-colored-canvas-highlight [&amp;::-webkit-scrollbar-thumb]:bg-brand-colored-canvas-highlight"
    >
      <!--  a scrollbar szinezés működik?  -->
      <!-- Nav -->
      <nav
        class="hs-accordion-group pb-3 w-full flex flex-col flex-wrap text-brand-colored-canvas-text"
        data-hs-accordion-always-open=""
      >
        <ul>
          @for (menuItem of menuItems; track menuItem.id) {
          <!-- Link -->
          @if (menuItem.subMenuItems.length > 0) {
          <li
            class="hs-accordion px-5 mb-1.5"
            [ngClass]="{ active: menuItem.id === activeMenuItem }"
          >
            <button
              type="button"
              class="hs-accordion-toggle hs-accordion-active:font-bold hs-accordion-active:text-brand-colored-canvas-text-highlight hs-accordion-active:bg-brand-colored-canvas-highlight w-full text-start flex gap-x-3 py-2 px-3 text-sm text-brand-colored-canvas-text rounded-lg hover:bg-brand-colored-canvas-highlight hover:text-brand-colored-canvas-text-highlight disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-brand-colored-canvas-highlight focus:text-brand-colored-canvas-text-highlight"
            >
              <i-lucide
                [name]="menuItem.icon"
                class="flex-shrink-0 mt-0.5 w-4 h-4"
                [strokeWidth]="1"
              ></i-lucide>
              {{ menuItem.name }}
              <i-lucide
                name="chevron-down"
                class="hs-accordion-active:-rotate-90 flex-shrink-0 ms-auto transition mt-0.5 w-4 h-4 stroke-brand-colored-canvas-text"
                [strokeWidth]="1"
              ></i-lucide>
              <!--<img
                src="assets/img/down.svg"
                alt=""
                class="hs-accordion-active:-rotate-180 flex-shrink-0 mt-1 w-3.5 h-3.5 ms-auto transition"
              />-->
            </button>
            <div
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              [ngClass]="{ hidden: menuItem.id !== activeMenuItem }"
            >
              <ul
                class="ps-7 mt-1.5 space-y-1.5 relative before:absolute before:top-0 before:start-[18px] before:w-0.5 before:h-full before:bg-brand-colored-canvas-highlight before:text-brand-colored-canvas-text-highlight"
              >
                @for (subMenuItem of menuItem.subMenuItems; track
                subMenuItem.id) {
                <li>
                  <a
                    [ngClass]="{
                      active:
                        activeSubmenuItem === subMenuItem.id &&
                        activeMenuItem === menuItem.id,
                      'font-bold bg-brand-colored-canvas-highlight text-brand-colored-canvas-text-highlight':
                        activeSubmenuItem === subMenuItem.id &&
                        activeMenuItem === menuItem.id
                    }"
                    class="flex gap-x-4 py-2 px-3 text-sm rounded-lg hover:bg-brand-colored-canvas-highlight hover:text-brand-colored-canvas-text-highlight focus:outline-none focus:bg-brand-colored-canvas-highlight focus:bg-brand-colored-canvas-highlight"
                    routerLink="{{ subMenuItem.link }}"
                    (click)="setActiveMenuItem(menuItem.id, subMenuItem.id)"
                  >
                    {{ subMenuItem.name }}
                  </a>
                </li>
                }
              </ul>
            </div>
          </li>
          } @else { @if(menuItem.isCategoryHeader){
          <li class="px-5 mb-1.5">
            <p
              class="py-2 px-3 text-xs font-semibold uppercase hover:text-brand-colored-canvas-text"
            >
              {{ menuItem.name }}
            </p>
          </li>
          } @else {

          <li class="px-5 mb-1.5">
            <a
              [ngClass]="{
                'font-bold bg-brand-colored-canvas-highlight text-brand-colored-canvas-text-highlight':
                  menuItem.id === activeMenuItem
              }"
              class="flex gap-x-3 py-2 px-3 text-sm rounded-lg hover:bg-brand-colored-canvas-highlight hover:text-brand-colored-canvas-text-highlight focus:outline-none focus:bg-brand-colored-canvas-highlight"
              routerLink="{{ menuItem.link }}"
              (click)="setActiveMenuItem(menuItem.id)"
            >
              <i-lucide
                [name]="menuItem.icon"
                class="flex-shrink-0 mt-0.5 w-4 h-4"
                [strokeWidth]="1"
              ></i-lucide>
              {{ menuItem.name }}
            </a>
          </li>
          } }
          <!-- End Link -->
          }
        </ul>
      </nav>
      <!-- End Nav -->
    </div>
    <!-- End Content -->

    <!-- Sidebar Footer -->
    <footer
      class="absolute bottom-0 inset-x-0 border-t border-brand-colored-canvas-compl"
    >
      <div
        class="flex justify-center items-center gap-x-2 py-2 px-5 text-xs text-brand-colored-canvas-text-slight"
      >
        <p>version: {{ version }}</p>
      </div>
    </footer>
    <!-- End Sidebar Footer -->

    <!-- Sidebar Close -->
    <!-- TODO: Sidebar Close Button témázás -->
    <div class="lg:hidden absolute top-3 -end-3 z-10">
      <button
        type="button"
        class="w-6 h-7 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
        data-hs-overlay="#hs-pro-sidebar"
        aria-controls="hs-pro-sidebar"
        aria-label="Toggle navigation"
      >
        <i-lucide
          name="chevron-left"
          class="flex-shrink-0 w-4 h-4"
          [strokeWidth]="1"
        ></i-lucide>
      </button>
    </div>
    <!-- End Sidebar Close -->
  </div>
</aside>
