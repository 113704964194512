import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';

@Component({
  selector: 'ui-switch',
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSwitchComponent),
      multi: true,
    },
  ],
  templateUrl: './ui-switch.component.html',
  styleUrls: ['./ui-switch.component.css'],
})
export class UiSwitchComponent implements ControlValueAccessor {
  @Input() swType: 'solid' | 'soft' = 'soft';
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() label: string = 'switch';
  @Input() checked = false;
  @Input() switchId: string = 'switch';
  @Input() disabled = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private log: LogService) {}

  writeValue(value: boolean): void {
/*     this.log.debug(
      'UiSwitchComponent: ' + this.switchId + ' writeValue: ' + value
    ); */
    this.checked = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changed(event: any): void {

    // a target.value mindig 'on' lesz, ezért a checked értékét használjuk, ami true vagy false
    this.onChange(event.target.checked);
  }

  getSwitchClasses() {
    // Alap osztályok
    let classes =
      'relative p-px bg-gray-200 border text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ' +
      'focus:ring-brand-button ' +
      'disabled:opacity-50 disabled:pointer-events-none ' +
      'checked:bg-none ' +
      'before:inline-block before:bg-white before:translate-x-0 before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 ' +
      'checked:before:translate-x-full before:rounded-full';

    // Méret szerinti osztályok
    switch (this.size) {
      case 'x-small':
        classes += ' w-[35px] h-[21px] before:size-4';
        break;
      case 'small':
        classes += ' w-11 h-6 before:size-5';
        break;
      case 'large':
        classes += ' w-[4.25rem] h-9 shrink-0 before:w-8 before:h-8';
        break;
      default:
        classes += ' w-[3.25rem] h-7 before:size-6';
        break;
    }

    // Típus szerinti osztályok
    if (this.swType === 'solid') {
      classes +=
        '  border-transparent before:shadow checked:before:bg-slate-200 checked:border-brand-colored-canvas focus:checked:border-brand-colored-canvas checked:text-brand-colored-canvas';
    } else {
      //soft type
      classes +=
        ' peer  border-gray-200 checked:border-brand-button-active checked:border-1 checked:border-solid checked:before:bg-brand-button checked:bg-gray-300 checked:text-brand-button-active focus:checked:border-brand-button-active';
    }

    return classes;
  }
}
