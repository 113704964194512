<tr>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-left"
  >
    {{ pitem.start_date }}
  </td>

  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    <ui-badge
    [color]="
      displayedBuilding === 'Lehel'
        ? 'blue'
        : displayedBuilding === 'Kunigunda'
        ? 'yellow'
        : 'black'
    "
    badgeType="soft"
    cornerType="rounded"
    class="w-24 inline-block"
    >{{ displayedBuilding }}</ui-badge
  >
    {{ pitem.flat | flatFormat }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    <ui-badge [color]="pitem.cleaningPackage?.id == 1 ? 'teal' : 'blue'">
      {{ pitem.cleaningPackage?.name }}
    </ui-badge>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    <ui-switch
      switchId="pitemPaid"
      [checked]="!!pitem.paid"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-left"
  >
    @if(pitem.paid){ @for (transaction of pitem.paymentTransactions; track
    transaction.id) {

    <ui-badge
      [color]="'yellow'"
      iconSrc="assets/img/credit-card.svg"
      iconClass="h-4 opacity-50"
      badgeType="soft"
      cornerType="rounded"
      class="w-24 inline-block"
      >{{ transaction.id }}</ui-badge
    >} } @else {
    <!-- <ui-button
      class="mb-1 block"
      size="xsmall"
      iconSrc="assets/img/credit-card.svg"
      iconClass="invert  saturate-0  brightness-0 contrast-125"
      btnType="orange"
    >
      Payment transaction</ui-button
    >

    <ui-button
      class="block"
      size="xsmall"
      iconSrc="assets/img/credit-card.svg"
      iconClass="invert  saturate-0  brightness-0 contrast-125"
      btnType="orange"
    >
      Other Payment</ui-button
    > -->
    }
  </td>

  <td
    class="px-4 py-3  ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium"
  >
    <div class="inline-flex rounded-lg shadow-sm w-max">
      <!--   -->

      <ui-button
      btnType="inline"
      (click)="handleView(pitem)"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      data-hs-overlay="#hs-cleaning-request-editor-modal"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
      <!--   -->

      <ui-button
      btnType="inline"
      (click)="handleEdit(pitem)"

      iconSrc="assets/img/pencil.svg"
      data-hs-overlay="#hs-cleaning-request-editor-modal"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>
      <!--   -->

      <ui-button
      btnType="inline"
      (click)="handleDelete(pitem)"
      inlinePos="right"

      iconSrc="assets/img/trash-2.svg"
      data-hs-overlay="#hs-delete-cleaning-request-confirmation-modal"
      > <span class="shrink-0 text-xs"> Delete </span>
    </ui-button>
      <!--   -->
    </div>
  </td>
</tr>
