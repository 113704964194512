import { Component, Input, SimpleChanges } from '@angular/core';
import { PropertyRowModel } from './properties-table-row-interface';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { ITenantModel } from '../../../../services/api/tenant/tenant-model.interface';
import { IssueClientService } from '../../../../services/api/issue/issue-client.service';
import { LogService } from '../../../../services/utils/log.service';
import { IIssueModel } from '../../../../services/api/issue/issue-model.interface';
import { ContractClientService } from '../../../../services/api/contract/contract-client.service';
import { IContractModel } from '../../../../services/api/contract/contract-model.interface';
import { Router } from '@angular/router';
import { HardCodedConfigService } from '../../../../services/utils/hard-coded-config.service';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'properties-table-row',
  standalone: true,
  imports: [UiBadgeComponent, UiButtonComponent],
  templateUrl: './properties-table-row.component.html',
  styleUrl: './properties-table-row.component.css',
})
export class PropertiesTableRowComponent {
  @Input() pitem = {} as PropertyRowModel;
  @Input() tenants: ITenantModel[] = [];

  openIssues = 0;
  isLoading = true;
  contract?: IContractModel;

  constructor(
    private issueService: IssueClientService,
    private contractService: ContractClientService,
    private log: LogService,
    private router: Router,
    private hardcodedConfigService: HardCodedConfigService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pitem']) {
      this.log.debug('Property item changed', this.pitem);
      if (this.pitem.hasContract) {
        this.openIssues = 0;
        this.isLoading = true;
        this.log.debug('Loading issues for flat: ' + this.pitem.id);
        this.issueService.getAllIssues({ flatId: this.pitem.id }).subscribe({
          next: (issues: IIssueModel[]) => {
            this.log.debug('Issues loade for flat: ' + this.pitem.id, issues);
            let count = 0;
            for (const element of issues) {
              if (
                element.issueStatus?.id !==
                this.hardcodedConfigService.CLOSED_ISSUE_STATUS_ID
              ) {
                count++;
              }
            }
            this.openIssues = count;
            this.isLoading = false;
          },
          error: (err) => {
            this.log.error('Error loading issues count', err);
          },
        });

        if (this.pitem.mostRecentContract) {
          this.log.debug('Loading tenant for flat: ' + this.pitem.id);
          this.contractService
            .getItemByIri(this.pitem.mostRecentContract)
            .subscribe({
              next: (contract) => {
                this.log.debug(
                  'Contract loaded for flat: ' + this.pitem.id,
                  contract
                );
                this.contract = contract;
              },
              error: (err) => {
                this.log.error('Error loading contract', err);
              },
            });
        }
      }
    }
  }

  onEditProperty() {
    this.router.navigate(['/properties', this.pitem.id], {
      queryParams: { tab: 1 },
    });
  }

  onTenantInfo() {
    this.router.navigate(['/properties', this.pitem.id], {
      queryParams: { tab: 2 },
    });
  }

  onEditBilling() {
    this.router.navigate(['/properties', this.pitem.id], {
      queryParams: { tab: 3 },
    });
  }

  onListOfBills() {
    this.router.navigate(['/properties', this.pitem.id, 'billing']);
  }

  onUtilityInfo() {
    this.router.navigate(['/properties', this.pitem.id], {
      queryParams: { tab: 4 },
    });
  }

  onInventoryInfo() {
    this.router.navigate(['/properties', this.pitem.id, 'document-inventory']);
  }
}
