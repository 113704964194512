<layout-main id="content" role="main">
  <!-- Dashboard content here -->
  <!-- GRID DASHBOARD -->
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:m-0 my-4 mx-2">
    <!-- CARD BOOKINGS STARTING TODAY -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg w-10 h-10 bg-gray-200">
              <lucide-angular name="building" class=""></lucide-angular>
            </div>

          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">Bookings starting today
              </span>
            </div>
            @if(isLoadingBookingsStartingToday){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {

            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">
                {{ bookingsStartingToday }}
              </div>
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <a [routerLink]="'/bookings'" class="px-2 py-1 flex justify-between">
        <span class="text-sm">View Bookings</span>
        <span class="">
          <lucide-angular name="chevron-right" class="inline-block h-5 w-5"></lucide-angular>
        </span>
      </a>
    </div>
    <!-- END CARD BOOKINGS STARTING TODAY -->
    <!-- CARD ACTUAL CHECKINS TODAY -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg bg-gray-200">
              <lucide-angular name="arrow-right-from-line" class=""></lucide-angular>
            </div>
            <!-- clipboard-check.svg -->
          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">Checkins today
              </span>
            </div>
            @if(isLoadingCheckins){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {

            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">{{ checkinsToday }}</div>
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <a [routerLink]="'/checkins'" class="p-2 flex justify-between">
        <span class="text-sm">View Checkins</span>
        <span class="">
          <lucide-angular name="chevron-right" class="inline-block h-5 w-5"></lucide-angular>
        </span>
      </a>
    </div>
    <!-- END CARD ACTUAL CHECKINS TODAY -->
    <!-- CARD BOOKINGS ENDING TODAY -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg bg-gray-200">
              <lucide-angular name="arrow-right-to-line" class=""></lucide-angular>
            </div>
            <!-- clipboard-check.svg -->
          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">Bookings Ending today
              </span>
            </div>
            @if(isLoadingBookingsEndingToday){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {

            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">
                {{ bookingsEndingToday }}
              </div>
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <!-- <a [routerLink]="'/checkins'" class="p-2 flex justify-between">
              <span class="text-sm">View Checkins</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a> -->
    </div>
    <!-- END CARD BOOKINGS ENDING TODAY -->
    <!-- CARD OPEN ISSUES -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg bg-gray-200">
              <lucide-angular name="clipboard-check"></lucide-angular>
            </div>
          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">Open Issues</span>
            </div>
            @if(isLoadingOpenIssues){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {
            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">{{ openIssues }}</div>
              <div class="rounded-xl space-x-1 px-2 py-1 leading-4 flex items-center" [ngClass]="
                  isHappyAboutOpenIssues ? 'bg-green-200' : 'bg-red-200'
                ">
                <span class="text-xs leading-4" [ngClass]="isHappyAboutOpenIssues ? 'green-900' : 'red-900'">{{
                  openIssues / issues.length | percent }}</span>
              </div>
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <a [routerLink]="'/issues'" class="p-2 flex justify-between">
        <span class="text-sm">View Issues</span>
        <span class="">
          <lucide-angular name="chevron-right" class="inline-block h-5 w-5"></lucide-angular>
        </span>
      </a>
    </div>
    <!-- END CARD OPEN ISSUES -->
    <!-- CARD PROPERTIES -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg bg-gray-200">
              <lucide-angular name="building2" class=""></lucide-angular>
            </div>
          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">Properties</span>
            </div>
            @if(isLoadingProperties){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {
            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">{{ numberOfFlats }}</div>
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <!--             <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""><img src="assets/img/forward.svg" alt="forward"
                  class="inline-block h-5 leading-4" /></span>
            </a> -->
    </div>
    <!-- END CARD PROPERTIES -->
    <!-- CARD CLEANINGS TODAY -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg bg-gray-200">
              <lucide-angular name="droplets" class=""></lucide-angular>
            </div>
          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">Cleanings today
              </span>
            </div>
            @if(isLoadingCleanings){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {

            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">
                <span>{{ cleaningsToday.cleaningsDone }}</span> /
                <span>{{ cleaningsToday.cleaningsTodo }}</span>
              </div>
              @if(cleaningsToday.cleaningsDone === cleaningsToday.cleaningsTodo
              && cleaningsToday.cleaningsDone > 0){
              <div class="rounded-xl space-x-1 px-2 py-1 leading-4 flex items-center bg-green-200">
                <span class="text-xs leading-4 green-900">finished</span>
              </div>
              }
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <a [routerLink]="'/cleaning'" class="p-2 flex justify-between">
        <span class="text-sm">View Cleanings</span>
        <span class="">
          <lucide-angular name="chevron-right" class="inline-block h-5 w-5"></lucide-angular>
        </span>
      </a>
    </div>
    <!-- END CARD CLEANINGS TODAY -->
    <!-- CARD EXPEDIA BOOKINGS STARTING TODAY -->
    <div class="flex flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-2">
        <div class="flex">
          <div class="basis-13 grow-0 shrink-0 mr-3">
            <div class="p-2 rounded-lg bg-gray-200">

              <lucide-angular name="triangle-alert"></lucide-angular>

            </div>
          </div>
          <div class="grow shrink">
            <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
              <span class="overflow-hidden whitespace-nowrap">New Expedia Bookings
              </span>
            </div>
            @if(isloadingExpediaBookings){
            <div class="flex space-x-2 items-center animate-pulse">
              <div class="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
            } @else {

            <div class="flex space-x-2 items-center">
              <div class="text-2xl font-bold">{{ expediaBookings }}</div>
            </div>
            }
          </div>
        </div>
      </div>

      <hr />
      <a [routerLink]="'/booking-overview'" class="p-2 flex justify-between">
        <span class="text-sm">Check Booking Overview</span>
        <span class="">
          <lucide-angular name="chevron-right" class="inline-block h-5 w-5"></lucide-angular>
        </span>
      </a>
    </div>
    <!-- END CARD EXPEDIA BOOKINGS STARTING TODAY -->
    <!-- CARD -->
    <!--           <div
            class="flex flex-col bg-white border shadow-sm rounded-xl ">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200 neutral-700 ">
                    <img src="assets/img/alert-triangle.svg" alt="occupancy ratio "
                      class="w-full h-auto " />
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap">Unpaid rental fees</span>
                    <span class="grow-0 shrink-0">
                      <img src="assets/img/help-circle.svg" alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4" />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">3</div>
                    <div class="rounded-xl bg-yellow-200 space-x-1 px-2 py-1 leading-4 flex items-center">
                      <span class="text-xs leading-4 text-red-900">1.500 €</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""><img src="assets/img/forward.svg" alt="forward"
                  class="inline-block h-5 leading-4" /></span>
            </a>
          </div> -->
    <!--  /CARD -->
    <!-- CARD -->
    <!--          <div
            class="flex flex-col bg-white border shadow-sm rounded-xl ">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200 ">
                    <img src="assets/img/plug-zap-2.svg" alt="occupancy ratio "
                      class="w-full h-auto " />
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap">High utility usage</span>
                    <span class="grow-0 shrink-0">
                      <img src="assets/img/help-circle.svg" alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4" />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">12</div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""><img src="assets/img/forward.svg" alt="forward"
                  class="inline-block h-5 leading-4" /></span>
            </a>
          </div> -->
    <!--  /CARD -->
    <!-- CARD -->
    <!--           <div
            class="flex flex-col bg-white border shadow-sm rounded-xl ">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200 ">
                    <img src="assets/img/calendar-clock.svg" alt="occupancy ratio "
                      class="w-full h-auto " />
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap">Contracts ending soon</span>
                    <span class="grow-0 shrink-0">
                      <img src="assets/img/help-circle.svg" alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4" />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">4</div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""><img src="assets/img/forward.svg" alt="forward"
                  class="inline-block h-5 leading-4" /></span>
            </a>
          </div> -->
    <!--  /CARD -->
    <!-- CARD -->
    <!--           <div
            class="flex flex-col bg-white border shadow-sm rounded-xl ">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200 ">
                    <img src="assets/img/gauge.svg" alt="occupancy ratio " class="w-full h-auto " />
                  </div>
                </div>
                <div class="grow">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap">
                      Overdue Meter INFO
                    </span>
                    <span class="grow-0 shrink-0">
                      <img src="assets/img/help-circle.svg" alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4" />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">4</div>
                    <div class="rounded-xl bg-yellow-200 space-x-1 px-2 py-1 leading-4 flex items-center">
                      <span class="text-xs leading-4 text-red-900">meters in 2 properties</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""><img src="assets/img/forward.svg" alt="forward"
                  class="inline-block h-5 leading-4" /></span>
            </a>
          </div> -->
    <!--  /CARD -->
    <!-- CARD -->
    <!-- <div
            class="flex flex-col bg-white border shadow-sm rounded-xl ">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200 ">
                    <img src="assets/img/dollar-sign.svg" alt="occupancy ratio "
                      class="w-full h-auto " />
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap">Unleased Properties</span>
                    <span class="grow-0 shrink-0">
                      <img src="assets/img/help-circle.svg" alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4 " />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">2</div>
                    <div class="rounded-xl bg-red-200 space-x-1 px-2 py-1 leading-4 flex items-center">
                      <img src="assets/img/arr.svg" alt="" class="inline-block h-4 leading-4 " />
                      <span class="text-xs text-red-900 leading-4">9.8%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""><img src="assets/img/forward.svg" alt="forward"
                  class="inline-block h-5 leading-4" /></span>
            </a>
          </div> -->
    <!--  /CARD -->
  </div>
  <!-- // GRID DASHBOARD -->
  <!-- Open Issues Table -->
  @if(openIssues){
  <div class="my-4">
    <issues-table [issueFilter]="issuesTableFilter" [showCreateButton]="false" title="Open Issues"></issues-table>
  </div>
  }
  <!-- End Open Issues Table -->
</layout-main>