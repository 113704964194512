<div
  id="hs-property-meters-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-2xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">Edit meters</h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-property-meters-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <div
        >
          <div class="p-4">
            <h3 class="text-gray-500 font-bold mb-10 uppercase text-center">
              Meters
            </h3>
          </div>
          <!-- Type Filter Grid -->
          <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
            <div class="sm:col-span-4">
              <label
                for="parkingRequestBooking"
                class="sm:mt-2.5 inline-block text-sm text-gray-500"
              >
                1. Filter by Type
              </label>
            </div>
            <div class="sm:col-span-8">
              <ng-select
                id="meterType"
                class="w-full max-w-lg inline-block mb-4"
                [items]="meterTypeSelectItems"
                bindLabel="title"
                [(ngModel)]="selectedMeterType"
                [multiple]="false"
                loadingText="Loading..."
                placeholder="Choose a Meter Type"
                (change)="filterMetersByType()"
              ></ng-select>
            </div>
          </div>
          <!-- End Type Filter Grid -->
          <!-- Meter Selection Grid -->
          <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
            <div class="sm:col-span-4">
              <label
                for="parkingRequestBooking"
                class="sm:mt-2.5 inline-block text-sm text-gray-500"
              >
                2. Select Meters
              </label>
            </div>
            <div class="sm:col-span-8">
              <ng-select
                id="meters"
                class="w-full max-w-lg inline-block mb-4"
                [items]="meterSelectItems"
                bindLabel="title"
                [(ngModel)]="selectedMeters"
                [multiple]="true"
                [virtualScroll]="true"
                loadingText="Loading..."
                placeholder="Start typing to search"
                [searchFn]="customSearchForSelect"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{
                    item.meterType.name + " #" + item.id
                  }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <span [ngOptionHighlight]="search">{{
                    item.meterType.name + " #" + item.id
                  }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <!-- End Meter Selection Grid -->
          <!--          <ui-button
            class="mt-4 flex justify-center items-center w-full"
            size="small"
            btnType="soft"
            [disabled]="!selectedMeters.length"
            (click)="addMeters()"
            >Add Meters</ui-button> -->
          <!-- Meter Cards -->
          <div
            class="grid mt-4 mb-8 gap-x-4 gap-y-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-items-stretch"
          >
            <!-- CARD -->
            <div
              *ngFor="let meter of selectedMeters"
              class="flex flex-col justify-between items-center border shadow-sm rounded-xl overflow-hidden h-32 relative"
              [ngClass]="{
                'bg-blue-50 border-blue-100': meter.meterType?.id === 3,
                'bg-orange-50 border-orange-100': meter.meterType?.id === 4,
                'bg-yellow-50 border-yellow-100':
                  meter.meterType?.id === 1 || meter.meterType?.id === 2,
                'bg-red-50 border-red-100': meter.meterType?.id === 6,
                'bg-cyan-50 border-cyan-100': meter.meterType?.id === 5
              }"
            >
  
                <div
                  class="text-xs mt-2 pl-3 text-slate-500"
                >
                  <span class="font-semibold mr-1">#{{ meter.id }}</span>
                  <span class="">{{ meter.meterType?.name }}</span>
                </div>
  
                <lucide-angular
                  [name]="
                    meter.meterType?.id === 3
                      ? 'droplet'
                      : meter.meterType?.id === 4
                      ? 'droplet'
                      : meter.meterType?.id === 1 || meter.meterType?.id === 2
                      ? 'plug'
                      : meter.meterType?.id === 6
                      ? 'thermometer-sun'
                      : 'thermometer-snowflake'
                  "
                  class="block mb-4 w-14 h-14"
                  [ngClass]="{
                    'text-blue-200': meter.meterType?.id === 3,
                    'text-orange-200': meter.meterType?.id === 4,
                    'text-yellow-300':
                      meter.meterType?.id === 1 || meter.meterType?.id === 2,
                    'text-red-200': meter.meterType?.id === 6,
                    'text-cyan-200': meter.meterType?.id === 5
                  }"
                  [size]="12"
                ></lucide-angular>
            </div>
            <!-- END CARD -->
          </div>
        </div>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-property-meters-editor-modal"
        >
          Close
        </button>
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          (click)="savePropertyMeters()"
          data-hs-overlay="#hs-property-meters-editor-modal"
        >
         Save
        </button>

      </div>
    </div>
  </div>
</div>
