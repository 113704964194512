import { Component } from '@angular/core';
import { IParkingRequestModel } from '../../services/api/parking-request/parking-request-model.interface';
import { LogService } from '../../services/utils/log.service';
import { ParkingRequestClientService } from '../../services/api/parking-request/parking-request-client.service';
import {
  subWeeks,
  addWeeks,
  format,
  addDays,
  isSameDay,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns';
import { CommonModule } from '@angular/common';
import { UiBadgeComponent } from '../atomic-ui-components/badge/ui-badge.component';

export type OccupancyData = {
  date: string;
  used: number;
  free: number;
};

@Component({
  selector: 'parking-overview',
  standalone: true,
  imports: [CommonModule, UiBadgeComponent],
  templateUrl: './parking-overview.component.html',
  styleUrl: './parking-overview.component.css',
})
export class ParkingOverviewComponent {
  parkingRequests: IParkingRequestModel[] = [];
  weekBefore: string = '';
  weeksAfter: string = '';

  isLoading = true;

  lehelP1OccupancyData: OccupancyData[] = [];
  lehelP2OccupancyData: OccupancyData[] = [];
  kunigundaOccupancyData: OccupancyData[] = [];
  lehelP1Max = 9;
  lehelP2Max = 5;
  kunigundaMax = 4;

  constructor(
    private readonly parkingRequestService: ParkingRequestClientService,
    private readonly log: LogService
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.isLoading = true;
    const today = new Date();
    this.weekBefore = format(subWeeks(today, 1), 'yyyy-MM-dd');
    this.weeksAfter = format(addWeeks(today, 3), 'yyyy-MM-dd');
    this.parkingRequestService
      .getAllParkingRequests(this.weekBefore, this.weeksAfter)
      .subscribe({
        next: (requests: IParkingRequestModel[]) => {
          this.log.info('Parkingrequests loaded');
          this.parkingRequests = requests;
          this.prepareOccupancyData();
        },
        error: (error) => {
          this.log.error('Error loading parking requests', error);
          this.isLoading = false;
        },
      });
  }

  /**
   * Elkészíti a parkolóhelyek foglaltságát mutató adatokat
   * Be van drótozva, hogy a Lehel és Kunigunda parkolóhelyeket ismerje
   */
  private prepareOccupancyData(): void {
    // A start_date és end_date között végig iterálunk minden napon
    const startDate = new Date(this.weekBefore);
    const endDate = new Date(this.weeksAfter);
    let currentDate = startDate;

    while (currentDate <= endDate) {
      let occupiedLehelP1 = 0;
      let occupiedLehelP2 = 0;
      let occupiedKunigunda = 0;

      for (const request of this.parkingRequests) {
        // Ha  foglalás kezdete <= mai nap < foglalás vége akkor beszámoljuk
        if (
          (isSameDay(
            currentDate,
            new Date(request.start_date ?? '1970-01-01')
          ) ||
            isAfter(
              currentDate,
              new Date(request.start_date ?? '1970-01-01')
            )) &&
          isBefore(currentDate, new Date(request.end_date ?? '9999-12-31'))
        ) {
          if (request.parkingPlace?.building?.id === 1) {
            if (request.parkingPlace?.name == 'P1') {
              occupiedLehelP1++;
            } else if (request.parkingPlace?.name == 'P2') {
              occupiedLehelP2++;
            } else {
              this.log.error(
                'Unknown parking place name for building 1: ',
                request
              );
            }
          } else if (request.parkingPlace?.building?.id === 2) {
            occupiedKunigunda++;
          } else {
            this.log.error('Unknown building id for parking place: ', request);
          }
        }
      }

      this.lehelP1OccupancyData.push({
        date: format(new Date(currentDate), 'MM/dd'),
        used: occupiedLehelP1,
        free: this.lehelP1Max - occupiedLehelP1,
      });

      this.lehelP2OccupancyData.push({
        date: format(new Date(currentDate), 'MM/dd'),
        used: occupiedLehelP2,
        free: this.lehelP2Max - occupiedLehelP2,
      });

      this.kunigundaOccupancyData.push({
        date: format(new Date(currentDate), 'MM/dd'),
        used: occupiedKunigunda,
        free: this.kunigundaMax - occupiedKunigunda,
      });

      currentDate = addDays(currentDate, 1);
    }
    this.isLoading = false;
  }
}
