<div
  id="hs-cleaning-schedule-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Cleaning Schedule"
              : mode === "edit"
              ? "Edit Cleaning Schedule"
              : "View Cleaning Schedule"
          }}
        </h3>

        <ui-button
          btnType="ghost"
          data-hs-overlay="#hs-cleaning-schedule-editor-modal">
          <span class="text-2xl">×</span>
        </ui-button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="cleaning-schedule-form"
            [formGroup]="cleaningScheduleForm"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Building Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleBuilding"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Building
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        cleaningSchedule.building?.name
                          ? cleaningSchedule.building?.name
                          : ""
                      }}
                    </p>
                    } @else {
                    <ng-select
                      class="w-full max-w-lg inline-block mb-4"
                      selectId="cleaningScheduleBuilding"
                      [items]="buildingSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="building"
                    />
                    }
                  </div>
                </div>
                <!-- End Building Grid -->
                <!-- Flat Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleFlat"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Flat
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        cleaningSchedule.flat?.flat_number
                          ? cleaningSchedule.flat?.flat_number
                          : ""
                      }}{{ " " + cleaningSchedule.flat?.name }}
                    </p>
                    } @else {
                    <ng-select
                      class="w-full max-w-lg inline-block mb-4"
                      selectId="cleaningScheduleFlat"
                      [items]="flatSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="flat"
                      [virtualScroll]="true"
                    />
                    }
                  </div>
                </div>
                <!-- End Flat Grid -->

                <!-- Cleaning Date Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleCleaningDate"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Cleaning Date
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ cleaningSchedule.cleaning_date }}
                    </p>
                    } @else {<input
                      id="cleaningScheduleCleaningDate"
                      type="date"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="cleaningSchedule.cleaning_date"
                      formControlName="cleaning_date"
                    />

                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Cleaning date Grid -->
                <!-- Cleaning Type Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleType"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Cleaning Type
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ cleaningSchedule.cleaningType?.name }}
                    </p>
                    } @else {
                    <ng-select
                      class="w-full max-w-lg inline-block mb-4"
                      selectId="cleaningScheduleType"
                      [items]="typeSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="cleaningType"
                    />
                    }
                  </div>
                </div>
                <!-- End Cleaning Type Grid -->
                <!-- Cleaner Note Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaner_note"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Note from staff
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ cleaningSchedule.cleaner_note }}
                    </p>
                    } @else {
                    <textarea
                      id="cleaner_note"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="cleaningSchedule.cleaner_note ?? ''"
                      formControlName="cleaner_note"
                    ></textarea>
                    }
                  </div>
                </div>
                <!-- End Cleaner Note Grid -->
                <!-- Cleaner Note Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaning_note"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Note for staff
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ cleaningSchedule.cleaning_note }}
                    </p>
                    } @else {
                    <textarea
                      id="cleaning_note"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="cleaningSchedule.cleaning_note ?? ''"
                      formControlName="cleaning_note"
                    ></textarea>
                    }
                  </div>
                </div>
                <!-- End Cleaner Note Grid -->
                <!-- Done Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleDone"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Done
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                        switchId="cleaningScheduleDone"
                        [disabled]="true"
                        [checked]="!!cleaningSchedule.cleaning_done"
                        formControlName="cleaning_done"
                      ></ui-switch>
                    </p>
                    } @else {
                    <ui-switch
                      switchId="cleaningScheduleDone"
                      [checked]="!!cleaningSchedule.cleaning_done"
                      formControlName="cleaning_done"
                    ></ui-switch>

                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Done Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <ui-button
          btnType="outline"
          size="xsmall"
          data-hs-overlay="#hs-cleaning-schedule-editor-modal">
          Close
        </ui-button>
        @if (mode != 'view') {
        <ui-button
          size="xsmall"
          data-hs-overlay="#hs-cleaning-schedule-editor-modal"
          (click)="saveCleaningSchedule()"
          [disabled]="cleaningScheduleForm.invalid">
            {{ mode === "create" ? "Create" : "Save" }}
        </ui-button>
        }
      </div>
    </div>
  </div>
</div>
