import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { II11Transformer } from '../generic-api-client.service';
import { ITenantModel } from './tenant-model.interface';

@Injectable({
  providedIn: 'root',
})
export class TenantTransformService implements II11Transformer<ITenantModel> {
  constructor(private log: LogService) {}

  transformItem(tenant: ITenantModel): ITenantModel {
    return tenant;
  }

  serializeItem(tenant: ITenantModel): ITenantModel {
    const serializedTenant: any = { ...tenant };
    return serializedTenant;
  }
}
