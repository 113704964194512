import { Component, Input } from '@angular/core';
import { PropertiesTableRowComponent } from './properties-table-row/properties-table-row.component';
import { PropertyRowModel } from './properties-table-row/properties-table-row-interface';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { TenantClientService } from '../../../services/api/tenant/tenant-client.service';
import { ITenantModel } from '../../../services/api/tenant/tenant-model.interface';
import {
  FlatClientService,
  FlatFilterType,
} from '../../../services/api/flat/flat-client.service';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { ModelFormatterService } from '../../../services/utils/model-formatter.service';
import { ContractToolsService } from '../../../services/utils/contract-tools.service';
import { ReportEditorComponent } from '../../modal-editors/report-editor/report-editor.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'properties-table',
  standalone: true,
  imports: [
    PropertiesTableRowComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
    ReportEditorComponent,
    ConfirmationDialogComponent,
  ], //
  templateUrl: './properties-table.component.html',
  styleUrl: './properties-table.component.css',
})
export class PropertiesTableComponent {
  @Input() ids?: string[];
  propertiesRows: PropertyRowModel[] = [];
  pitem: PropertyRowModel = {} as PropertyRowModel;
  tenants: ITenantModel[] = [];
  isPagerLoading = false;
  isLoading = true;
  totalItems = 0;

  constructor(
    private readonly flatClientService: FlatClientService,
    private readonly tenantService: TenantClientService,
    private readonly log: LogService,
    private readonly formatter: ModelFormatterService,
    private readonly contractTools: ContractToolsService
  ) {}

  ngOnInit() {
    this.tenantService.getItems().subscribe((tenants) => {
      this.tenants = tenants;
      this.isLoading = false;
    });
    this.refresh();
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  refresh(page?: number) {
    if (this.ids) {
      //ha kaptunk id-kat akkor csak azokat kérjük le
      this.isLoading = true;
      // végigmegyünk az id-kon és lekérjük az összes property-t egyesével, majd a végén összerakjuk a propertiesRows-ba
      this.ids.forEach((id) => {
        const parsedId = Number(id);
        if (!isNaN(parsedId)) {
          this.flatClientService.getFlat(parsedId).subscribe({
            next: (flat) => {
              this.propertiesRows.push(
                this.transformFlatsToPropertyRowModels([flat])[0]
              );
              this.isLoading = false;
            },
            error: (err) => {
              this.log.error('Error fetching properties', err);
              this.isLoading = false;
            },
          });
        } else {
          this.log.error('Invalid ID, unable to parse as number:', id);
          this.isLoading = false;
        }
      });
    } else {
      this.isLoading = true;
      this.flatClientService.getFlats(page).subscribe({
        next: (properties) => {
          this.log.debug('Properties fetched', properties);
          this.propertiesRows =
            this.transformFlatsToPropertyRowModels(properties);
          setTimeout(() => {
            this.isLoading = false;
            this.log.info('Properties table initialized');
            window.HSStaticMethods.autoInit('overlay');
          }, 100);
        },
        error: (err) => {
          this.log.error('Error fetching properties', err);
          this.isLoading = false;
        },
      });
      // lekérjük az összes elem számát is a lapozónak
      this.isPagerLoading = true;
      this.flatClientService
        .getTotalItems(FlatFilterType.FLAT)
        .subscribe((count) => {
          this.totalItems = count;
          this.isPagerLoading = false;
        });
    }
  }

  transformFlatsToPropertyRowModels(flats: IFlatModel[]): PropertyRowModel[] {
    let properties = flats.map((flat) => {
      //const tenant = this.tenants.find((t) => t.id === property.tenantId);
      const property = {
        id: flat.id,
        // TODO: buildinget külön kivenni, badge-be tenni
        name: this.formatter.getFormattedFlat(flat),
        // TODO: itt aktív contract aktív tenatja kellene (tenantjai?)
        //tenant: flat.contracts?.[0]?.tenants?.[0],
        contactPhone: '',
        contactEmail: '',
        status: [],
      } as PropertyRowModel;
      if (flat.contracts?.length) {
        property.hasContract = true;
        this.log.debug('Contract found', flat.contracts);
        const mostRecentContract =
          this.contractTools.getMostRecentContractForFlat(flat);
        if (mostRecentContract) {
          this.log.debug(
            'Most recent contract found for flat:' + flat['@id'],
            mostRecentContract
          );
          property.mostRecentContract = mostRecentContract['@id'];
          if (mostRecentContract.tenancy_ends_at) {
            const endDate = new Date(mostRecentContract.tenancy_ends_at);
            const now = new Date();
            if (
              endDate.getTime() - now.getTime() < 1000 * 60 * 60 * 24 * 60 &&
              endDate.getTime() > now.getTime()
            ) {
              property.contractEndsSoon = true;
            }
          }
        }
      }
      return property;
    });
    return properties;
  }
}
