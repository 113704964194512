import { Component, ElementRef } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MeterClientService } from '../../services/api/meter/meter-client.service';
import { IMeterModel } from '../../services/api/meter/meter-model.interface';
import { MeterReadingTableComponent } from '../../components/tables/meter-reading-table/meter-reading-table.component';
import { LogService } from '../../services/utils/log.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexXAxis,
  NgApexchartsModule,
  ApexYAxis,
  ApexMarkers,
  ApexLegend,
} from 'ng-apexcharts';
import { IReadingModel } from '../../services/api/reading/reading-model.interface';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  colors: any;
  grid: any;
  markers: ApexMarkers;
  legend: ApexLegend;
  //fill: any;
  //labels: any;
  //tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  //plotOptions: ApexPlotOptions;
};

export type ChartData = {
  x: string;
  y: number[];
  fillColor?: string;
};

@Component({
  selector: 'meter-detail',
  standalone: true,
  imports: [
    LayoutMainComponent,
    RouterLink,
    MeterReadingTableComponent,
    NgApexchartsModule,
  ],
  templateUrl: './meter-detail.component.html',
  styleUrl: './meter-detail.component.css',
})
export class MeterDetailComponent {
  meter!: IMeterModel;
  public chartOptions!: Partial<ChartOptions>;
  chartData = [] as ChartData[];

  constructor(
    private readonly meterService: MeterClientService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly log: LogService,
    private readonly elementRef: ElementRef
  ) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#77B6EA', '#545454'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Utility Consumption',
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Reading Date',
        },
      },
      yaxis: {
        title: {
          text: 'Meter reading (m3)',
        },
        min: 0,
        max: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    };
  }

  ngOnInit() {
    this.log.debug('MeterDetailComponent init');
    this.route.params.subscribe((params) => {
      this.meterService.getItem(params['id']).subscribe({
        next: (meter) => {
          if (meter) {
            this.meter = meter;
          } else {
            //irány a 404
            this.router.navigate(['/error']);
          }
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }

  ngAfterViewInit(): void {
    // a charton való átgörgetés tiltása
    // TODO: ettől még dobálja ez errort szegény
    const element = this.elementRef.nativeElement.querySelector('apx-chart');
    element.addEventListener(
      'wheel',
      (event: WheelEvent) => {
        event.preventDefault();
      },
      { passive: false }
    );
  }

  // fogyasztás kezelése
  handleReadings(readings: IReadingModel[]) {
    // a min és max beállítása az adatok alapján
    let min = 0;
    let max = 0;

    // series számítása a min-max-szal együtt
    let dataSet = readings.map((reading, index) => {
      const consumption =
        index === 0
          ? 0
          : parseFloat(readings[index - 1].reading ?? '') -
            parseFloat(reading.reading ?? '');
      if (consumption < min) {
        min = consumption;
      }
      if (consumption > max) {
        max = consumption;
      }
      return {
        x: reading.read_at ?? '',
        y: consumption.toFixed(3),
      };
    });

    // töröljük azokat az elemeket, ahol nincs értelmes adat
    dataSet = dataSet.filter((data) => data.y !== 'NaN');

    this.chartOptions.series = [
      {
        name: 'Tenant Consumption',
        data: dataSet,
      },
      /* {
        name: 'Average Consumption',
        data: readings.map((reading, index) => {
          const consumption =
            index === 0
              ? 0
              : parseFloat(readings[index - 1].reading ?? '') -
                parseFloat(reading.reading ?? '');
          return {
            x: reading.read_at ?? '',
            y: (consumption / (Math.random() * (3 - 1.2) + 1.2)).toFixed(3),
          };
        }),
      }, */
    ];

    // yaxis beállítása a min-max különbsége alapján, némi arányos esztétikai ráhagyással
    this.chartOptions.yaxis = {
      title: {
        text: `Consumption (${this.meter.meterType?.unit?.name})`,
      },
      min: min - Math.abs(min * 0.1),
      max: max + Math.abs(max * 0.1),
    };
    this.log.debug('Chart options', this.chartOptions);
  }
  // mérőállások kezelése
  /*   handleReadings(readings: IReadingModel[]) {
    this.chartOptions.series = [
      {
        name: 'Meter Readings',
        data: readings.map((reading) => {
          return {
            x: reading.read_at ?? '',
            y: parseFloat(reading.reading ?? ''),
          };
        }),
      },
      {
        name: 'Average Consumption',
        data: readings.map((reading) => {
          return {
            x: reading.read_at ?? '',
            y: (parseFloat(reading.reading ?? '')  / 2),
          };
        }),
      }
    ];
  } */
}
