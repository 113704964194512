<div
  id="hs-payment-transaction-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Payment Transaction"
              : mode === "edit"
              ? "Edit Payment Transaction"
              : "View Payment Transaction"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-payment-transaction-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="payment-transaction-form"
            [formGroup]="paymentTransactionForm"
            (ngSubmit)="savePaymentTransaction()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- CreatedAt Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionCreatedAt"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Created At
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        paymentTransaction.created_at === undefined
                          ? "-"
                          : paymentTransaction.created_at
                      }}
                    </p>
                    } @else {
                    <input
                      id="paymentTransactionCreatedAt"
                      type="datetime-local"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="paymentTransaction.created_at"
                      formControlName="created_at"
                    />
                    }
                  </div>
                </div>
                <!-- End CreatedAtGrid -->

                <!-- PaymentProvider Grid-->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPaymentProvider"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Payment Provider
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.paymentProvider?.name }}
                    </p>
                    } @else {
                    <!--<ui-select
                      selectId="paymentTransactionPaymentProvider"
                      [selectItems]="paymentProviderSelectItems"
                      [selectedOptionValue]="paymentTransaction.paymentProvider?.['@id']"
                      formControlName="paymentProvider"
                    ></ui-select>-->
                    <ng-select
                      labelForId="paymentTransactionPaymentProvider"
                      [items]="paymentProviderSelectItems"
                      bindLabel="title" 
                      bindValue="val"
                      formControlName="paymentProvider"
                      placeholder="Select payment provider">
                    </ng-select>
                    }
                  </div>
                </div>
                <!-- End PaymentProvider Grid -->

                <!-- Payee User Grid  -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPayeeUser"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Payee User
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.payee_user?.name + " (" + paymentTransaction.payee_user?.email + ")"}}
                    </p>
                    } @else {
                    <!--<ui-select
                      selectId="paymentTransactionPayeeUser"
                      [selectItems]="userSelectItems"
                      [selectedOptionValue]="paymentTransaction.payee_user?.['@id']"
                      formControlName="payee_user"
                    ></ui-select>-->
                    <ng-select
                    labelForId="paymentTransactionPayeeUser"
                    [items]="userSelectItems" 
                    bindLabel="title" 
                    bindValue="val" 
                    [clearable]="true"
                    formControlName="payee_user" 
                    placeholder="Select payee user">
                    </ng-select>
                    }
                  </div>
                </div>
                <!-- End Payee User Grid -->

                <!-- Payer User Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPayerUser"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Payer User
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.payer_user?.name + " (" + paymentTransaction.payer_user?.email + ")"}}
                    </p>
                    } @else {
                    <!--<ui-select
                      selectId="paymentTransactionPayerUser"
                      [selectItems]="userSelectItems"
                      [selectedOptionValue]="paymentTransaction.payer_user?.['@id']"
                      formControlName="payer_user"
                    ></ui-select>-->
                    <ng-select
                    labelForId="paymentTransactionPayerUser"
                    [items]="userSelectItems" 
                    bindLabel="title" 
                    bindValue="val" 
                    [clearable]="true"
                    formControlName="payer_user" 
                    placeholder="Select payee user">
                    </ng-select>                    
                    }
                  </div>
                </div>
                <!-- End Payer User Grid -->
                <!-- Invoice Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionInvoice"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Invoice
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.invoice?.invoice_number }}
                    </p>
                    } @else {
                    <!--<ui-select
                      selectId="paymentTransactionInvoice"
                      [selectItems]="invoiceSelectItems"
                      [selectedOptionValue]="paymentTransaction.invoice?.['@id']"
                      formControlName="invoice"
                    ></ui-select>-->
                    <ng-select
                    labelForId="paymentTransactionInvoice"
                    [items]="invoiceSelectItems" 
                    bindLabel="title" 
                    bindValue="val" 
                    [clearable]="true"
                    formControlName="invoice" 
                    placeholder="Select invoice">
                    </ng-select>                     
                    }
                  </div>
                </div>
                <!-- End Invoice Grid -->

                <!-- Booking Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionBooking"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Booking
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        paymentTransaction.booking?.name +
                          " (" +
                          paymentTransaction.booking?.start_date +
                          " - " +
                          paymentTransaction.booking?.end_date +
                          ")"
                      }}
                    </p>
                    } @else {
                    <!--<ui-select
                      selectId="paymentTransactionBooking"
                      [selectItems]="bookingSelectItems"
                      [selectedOptionValue]="paymentTransaction.booking?.['@id']"
                      formControlName="booking"
                    ></ui-select>-->
                    <ng-select
                    labelForId="paymentTransactionBooking"
                    [items]="bookingSelectItems" 
                    bindLabel="title" 
                    bindValue="val" 
                    [clearable]="true"
                    formControlName="booking" 
                    placeholder="Select booking">
                    </ng-select>                       
                    }
                  </div>
                </div>
                <!-- End Booking Grid -->
                <!-- Price Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPrice"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Price
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.price }}
                    </p>
                    } @else {
                    <input
                      id="paymentTransactionPrice"
                      type="number"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="
                        paymentTransaction.price ? paymentTransaction.price : 0
                      "
                      formControlName="price"
                    />
                    }
                  </div>
                </div>
                <!-- End Price Grid -->
                <!-- Currency Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceCurrency"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Currency
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.currency?.name }}
                    </p>
                    } @else { 
                      @if(!currencySelectItems){ placeholder } @else {
                    <!--<ui-select
                      selectId="invoiceCurrency"
                      [selectItems]="currencySelectItems"
                      [selectedOptionValue]="paymentTransaction.currency?.['@id']"
                      formControlName="currency"
                    ></ui-select>-->
                    <ng-select
                    labelForId="invoiceCurrency"
                    [items]="currencySelectItems" 
                    bindLabel="title" 
                    bindValue="val" 
                    [clearable]="true"
                    formControlName="currency" 
                    placeholder="Select currency">
                    </ng-select>                      
                    } 
                  }
                  </div>
                </div>
                <!-- End Currency Grid -->
                <!-- Paid Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPaid"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Paid
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                      id="paymentTransactionPaid"
                      [checked]="!!paymentTransaction.paid"
                      [disabled]=true
                      formControlName="paid"
                    ></ui-switch>
                    </p>
                    } @else {

                    <ui-switch
                      id="paymentTransactionPaid"
                      [checked]="!!paymentTransaction.paid"
                      formControlName="paid"
                    ></ui-switch>
                    }
                  </div>
                </div>
                <!-- End Paid Grid -->
                <!-- Payer Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPayerName"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Payer Name
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.payer_name }}
                    </p>
                    } @else {
                    <input
                      id="paymentTransactionPayerName"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="
                        paymentTransaction.payer_name
                          ? paymentTransaction.payer_name
                          : ''
                      "
                      formControlName="payer_name"
                    />
                    }
                  </div>
                </div>
                <!-- End Payer Name Grid -->
                <!-- Payer Email Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionPayerEmail"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Payer Email
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ paymentTransaction.payer_email }}
                    </p>
                    } @else {
                    <input
                      id="paymentTransactionPayerEmail"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="
                        paymentTransaction.payer_email
                          ? paymentTransaction.payer_email
                          : ''
                      "
                      formControlName="payer_email"
                    />
                    }
                  </div>
                </div>
                <!-- End Payer Email Grid -->
                @if(mode != 'create'){
                <!-- TransactionCode Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="paymentTransactionCode"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Payment Transaction Code
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p
                      class="py-2 px-3 block w-full text-xs text-gray-600 font-mono"
                    >
                      <span class="inline-block bg-gray-100 rounded p-1">{{
                        paymentTransaction.transaction_code
                      }}</span>
                    </p>
                    } @else {
                    <input
                      id="paymentTransactionCode"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="paymentTransaction.transaction_code"
                      disabled
                    />
                    }
                  </div>
                </div>
                <!-- End TransactionCode Grid -->
                }
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-payment-transaction-editor-modal"
        >
          Close
        </button>
        @if (mode != 'view') {
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="paymentTransactionForm.invalid"
          form="payment-transaction-form"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
        }
      </div>
    </div>
  </div>
</div>
