import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogService } from '../utils/log.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TextMessageService {
  private readonly apiKey = environment.smsApiKey;
  private readonly smsURL = environment.smsApiUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly log: LogService
  ) {}

  private sendTextMessage(text: string, phoneNumber: string) {
    const data = new FormData();
    data.append('apikey', this.apiKey);
    data.append('text', text);
    data.append('phoneNumber', phoneNumber);

    this.log.debug('Sending text message to: ' + phoneNumber, text);

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });

    return this.http.post(this.smsURL, data);
    // a válaszban lehet error_code, error_message vagy ha jó, akkor status (queued)
    // ha elcseszted, akkor nincs semmi válasz, csak 200-as státusz
  }

  sendInvoiceReminder(
    invoiceId: string,
    amountDue: string,
    phoneNumber: string
  ) {
    const text = `An unpaid SmartApart invoice (${invoiceId}) is due. Please open SmartApart app to complete your payment of ${amountDue}.`;
    return this.sendTextMessage(text, phoneNumber);
  }

  sendNewInvoiceNotification(phoneNumber: string) {
    const text = `You received a new invoice from SmartApart. Please open the app to see it.`;
    return this.sendTextMessage(text, phoneNumber);
  }
}
