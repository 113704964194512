<!-- TABLE WRAPPER ************************************************************************** -->

<h2 class="ms-1 mb-4 inline-block font-semibold">Properties</h2>

@if(isLoading){
<div
  class="bg-white border shadow-sm rounded-xl p-16 mb-4 align-middle text-center"
>
  <div
    class="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-brand-colored-canvas rounded-full"
    role="status"
    aria-label="loading"
  >
    <span class="sr-only">Loading...</span>
  </div>
  <p class="text-sm text-gray-500 mt-4">Loading properties...</p>
</div>
} @else {
<div>
  <!-- Tab Content -->
  <div
    id="hs-pro-tabs-dut-all"
    role="tabpanel"
    aria-labelledby="hs-pro-tabs-dut-item-all"
  >
    <!-- Table Section -->
    <div
      class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300"
    >
      <div class="min-w-full inline-block align-middle">
        <!-- Table -->
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr class="border-t border-gray-200 divide-x divide-gray-200">
              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Name
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutlctn"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Status Information
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-duttenant"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Tenant
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutstatus"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-end w-full flex items-right gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Contact Information
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>
              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutactions"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-end w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Actions
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>
            </tr>
          </thead>
          @if(isLoading){
          <tr>
            <td colspan="100">
              <div
                class="w-full flex justify-center content-center mt-16 min-h-28"
              >
                <img
                  src="assets/img/loading.gif"
                  alt="loading"
                  class="h-8 w-auto"
                />
                <div class="ml-4">Loading...</div>
              </div>
            </td>
          </tr>
          } @else { @for (item of propertiesRows; track item.id) {
          <properties-table-row
            [pitem]="item"
            [tenants]="tenants"
            class="divide-y divide-gray-200"
          >
          </properties-table-row>
          } @empty {
          <tr>
            <td class="text-center" colspan="100">
              <!--  EMPTY STATE  -->
              <table-empty-state
                noDataTitle="There are no properties"
              ></table-empty-state>
              <!--  END EMPTY STATE  -->
            </td>
          </tr>
          } }
        </table>
      </div>
    </div>
    <!-- Footer -->
    <div class="mt-4">
      <table-pager
        [totalItems]="totalItems"
        [isLoading]="isPagerLoading"
        (onPageChange)="handlePagination($event)"
      ></table-pager>
    </div>

    <!-- End Footer -->
  </div>
</div>
}

<!-- ========== SECONDARY CONTENT ========== -->
<report-editor> </report-editor>
<!-- ========== END SECONDARY CONTENT ========== -->
