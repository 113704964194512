<div
  id="hs-billing-address-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Billing Address"
              : mode === "edit"
              ? "Edit Billing Address"
              : "View Billing Address"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-billing-address-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="billing-address-form"
            [formGroup]="billingAddressForm"
          >
            <div class="py-2 sm:py-3 sm:px-3">
              <div class="space-y-5">
                <!-- Name Grid -->
                <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                  <div class="sm:col-span-4">
                    <label
                      for="baform-name"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Name
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ billingAddress.name }}
                    </p>
                    } @else {
                    <input
                      id="baform-name"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="billingAddress.name || ''"
                      formControlName="name"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Name Grid -->

                <!-- Company Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="baform-company"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Company name
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ billingAddress.company_name }}
                    </p>
                    } @else {<input
                      id="baform-company"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="company_name"
                      [value]="billingAddress.company_name || ''"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Company Name Grid -->
                <!-- Tax Number Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="baform-tax"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Tax Number
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ billingAddress.tax_number }}
                    </p>
                    } @else {
                    <input
                      id="baform-tax"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="tax_number"
                      [value]="billingAddress.tax_number || ''"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Tax Number Grid -->

                <!-- Bank Account Number Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="baform-account"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Bank Account Number
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ billingAddress.bank_account_number }}
                    </p>
                    } @else {
                    <input
                      id="baform-account"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="bank_account_number"
                      [value]="billingAddress.bank_account_number || ''"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Bank Account Number Grid -->

                <!-- Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="baform-address"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Address
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8 space-y-3">
                    <!-- Country Select -->
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ billingAddress.country }}
                    </p>
                    } @else {
                    <div>
                      <ng-select
                        [items]="countries"
                        bindLabel="title"
                        bindValue="val"
                        formControlName="country"
                        [virtualScroll]="true"
                        placeholder="Select Country"
                      >
                        <ng-template ng-label-tmp let-item="item">
                          <div>
                            <div class="flex items-center">
                              <div class="me-2">
                                <img
                                  class="inline-block size-4 rounded-full"
                                  [src]="item.options?.icon"
                                  [alt]="item.options?.description"
                                />
                              </div>
                              <div class="text-gray-800">
                                {{ item.title }}
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                          <div>
                            <div class="flex items-center">
                              <div class="me-2">
                                <img
                                  class="inline-block size-4 rounded-full"
                                  [src]="item.options?.icon"
                                  [alt]="item.options?.description"
                                />
                              </div>
                              <div class="text-gray-800">
                                {{ item.title }}
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                    <!-- End Select -->
                    } @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ billingAddress.street }}
                    </p>
                    } @else {
                    <input
                      id="baform-street"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder="Street"
                      formControlName="street"
                      [value]="billingAddress.street || ''"
                    />
                    }

                    <div class="grid grid-cols-2 gap-x-3">
                      <div>
                        @if (mode === 'view') {
                        <p class="py-2 px-3 block w-full font-semibold">
                          {{ billingAddress.town }}
                        </p>
                        } @else {
                        <input
                          id="baform-city"
                          type="text"
                          class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="City"
                          formControlName="town"
                          [value]="billingAddress.town || ''"
                        />
                        }
                      </div>
                      <div>
                        @if (mode === 'view') {
                        <p class="py-2 px-3 block w-full font-semibold">
                          {{ billingAddress.po_code }}
                        </p>
                        } @else {
                        <input
                          id="baform-zip"
                          type="text"
                          class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="Zip/Postal code"
                          formControlName="po_code"
                          [value]="billingAddress.po_code || ''"
                        />
                        }
                      </div>
                    </div>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
       <ui-button size="xsmall" btnType="outline"
        data-hs-overlay="#hs-billing-address-editor-modal">Close</ui-button>
        @if (mode != 'view') {
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="billingAddressForm.invalid"
          form="billing-address-form"
          data-hs-overlay="#hs-billing-address-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
        <ui-button 
          (click)="saveBillingAddress()" 
          size="xsmall" 
          [disabled]="billingAddressForm.invalid" 
          data-hs-overlay="#hs-billing-address-editor-modal"
          form="billing-address-form">
          {{ mode === "create" ? "Create" : "Save" }}
        </ui-button>
        }
      </div>
    </div>
  </div>
</div>
