import { Injectable } from '@angular/core';
import { IUiSelectItem } from '../../components/atomic-ui-components/select/ui-select-item.interface';
import { IBillingAddressModel } from '../api/billing-address/billing-address-model.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Egy service, aminek léteznie sem kéne
 * Olyan elemeket tartalmaz, amiket nem kellene hardcode-olni,
 * hanem a backendről kellene lekérni, de túl körülményes lett volna dinamikusan megoldani.
 */
export class HardCodedConfigService {
  // Ez a BookingStatus ID reprezentálja a jóváhagyott foglalásokat
  readonly FINALIZED_BOOKING_STATUS_ID = 5;

  // Document Inventoryban feltöltésnél a maximális fájlméret MB-ban
  readonly MAX_FILE_SIZE_MB = 32;

  // Nemek select itemjei, amúgy API-ból kéne jönniük
  readonly GENDER_SELECT_ITEMS = [
    {
      val: '/admin/api/genders/1',
      title: 'Férfi',
    },
    {
      val: '/admin/api/genders/2',
      title: 'Nő',
    },
    {
      val: '/admin/api/genders/3',
      title: 'Egyéb',
    },
  ] as IUiSelectItem[];

  // Épület infó, amiket amúgy nem tartunk nyilván
  readonly BUILDING_CONFIG = [
    // Lehel
    {
      id: 1,
      name: 'Lehel',
      marketName: 'SmartApart Budapest',
      address: 'Budapest, Lehel u 58, 1135',
    },
    // Kunigunda
    {
      id: 2,
      name: 'Kunigunda',
      marketName: 'SmartApart Óbuda',
      address: 'Budapest, Kunigunda útja 14, 1037',
    },
  ];

  // Ez a típus reprezentálja a fix áras számlatételeket, ezeknél nem jelenik meg unit és mérőóra
  readonly FIXED_PRICE_TYPE_FOR_BILLING_ITEM =
    '/admin/api/billing_item_types/1';

  // Ha egy issuestatus id-je ennyi, akkor az le van zárva. "És ha lezárnak, akkor te le vagy zárva. Különben véged"
  readonly CLOSED_ISSUE_STATUS_ID = 2;

  // Az expedia booking source id-je
  readonly EXPEDIA_BOOKING_SOURCE_ID = 2;

  readonly DEFAULT_BILLING_ADDRESS = {
    id: 0,
    country: 'HU',
    town: 'Budapest',
    po_code: '1135',
    street: 'Lehel u. 58.',
  } as IBillingAddressModel

  constructor() {}
}
