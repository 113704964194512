import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { Router, RouterLink } from '@angular/router';
import { FlatClientService } from '../../services/api/flat/flat-client.service';
import { LogService } from '../../services/utils/log.service';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IUiSelectItem } from '../../components/atomic-ui-components/select/ui-select-item.interface';
import { SelectItemLoaderService } from '../../services/utils/select-item-loader.service';
import { IFlatModel } from '../../services/api/flat/flat-model.interface';
import { HSStepper } from 'preline/preline';
import { UiSwitchComponent } from '../../components/atomic-ui-components/switch/ui-switch.component';
import { CommonModule } from '@angular/common';
import { format } from 'date-fns';
import { ContractClientService } from '../../services/api/contract/contract-client.service';
import { IContractModel } from '../../services/api/contract/contract-model.interface';
import { IFlatTypeModel } from '../../services/api/flat-type/flat-type-model.interface';
import { IMeterModel } from '../../services/api/meter/meter-model.interface';
import { MeterClientService } from '../../services/api/meter/meter-client.service';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { LucideAngularModule } from 'lucide-angular';
import { UiBadgeComponent } from '../../components/atomic-ui-components/badge/ui-badge.component';
import { ToastrService } from 'ngx-toastr';
import { UserRentsFlatClientService } from '../../services/api/user-rents-flat/user-rents-flat-client.service';

@Component({
  selector: 'new-property-wizard',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LayoutMainComponent,
    LucideAngularModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ReactiveFormsModule,
    RouterLink,
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
  ],
  templateUrl: './new-property-wizard.component.html',
  styleUrl: './new-property-wizard.component.css',
})
export class NewPropertyWizardComponent {
  buildingSelectItems: IUiSelectItem[] = [];
  floorSelectItems: IUiSelectItem[] = [];
  flatTypeSelectItems: IUiSelectItem[] = [];
  tenantSelectItems: IUiSelectItem[] = [];
  meterTypeSelectItems: IUiSelectItem[] = [];
  meterSelectItems: IMeterModel[] = [];
  selectedMeterType?: IUiSelectItem;
  selectedMeters: IMeterModel[] = [];
  isMeterTypeLoading = false;
  isMeterLoading = false;
  //metersToSave: Map<number, IMeterModel> = new Map();

  stepper: HSStepper | undefined;
  currentStep = 0;
  createdProperty: IFlatModel | undefined;
  isOpenEnded = true;
  createdContract: IContractModel | undefined;
  meters: IMeterModel[] = [];

  constructor(
    private flatService: FlatClientService,
    private contractService: ContractClientService,
    private meterService: MeterClientService,
    private silo: SelectItemLoaderService,
    private log: LogService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private rentalService: UserRentsFlatClientService
  ) {}

  propertyForm = this.fb.group({
    building: [''],
    //    floor: [''],
    //    flatType: [''],
    flat_number: [''],
    name: [''],
    size: [0, Validators.required],
  });

  contractForm = this.fb.group({
    contract_number: [''],
    contract_date: [''],
    tenancy_starts_at: [''],
    open_ended: [true],
    tenancy_ends_at: [''],
    tenants: [''],
  });

  ngOnInit() {
    // Betöltjük az épületeket
    this.silo.getBuildingSelectItems().subscribe({
      next: (items) => {
        if (items.length > 0) {
          this.buildingSelectItems = items;
          this.log.debug('Buildings loaded');
          this.propertyForm.controls.building.setValue(
            this.buildingSelectItems[0].val
          );
        }
      },
      error: (err) => {
        this.log.error('Could not load buildings ', err);
      },
    });

    /*     this.silo.getFloorSelectItems().subscribe({
      next: (items) => {
        this.floorSelectItems = items;
        this.log.debug('Floors loaded');
        this.propertyForm.controls.floor.setValue(this.floorSelectItems[0].val);
      },
      error: (err) => {
        this.log.error('Could not load floors ', err);
      },
    }); */
    /*     this.silo.getFlatTypeSelectItems().subscribe({
      next: (items) => {
        this.flatTypeSelectItems = items;
        this.log.debug('Flat types loaded');
        this.propertyForm.controls.flatType.setValue(this.flatTypeSelectItems[0].val);
      },
      error: (err) => {
        this.log.error('Could not load flat types ', err);
      },
    }); */

    // Betöltjük a bérlőket
    this.silo.getTenantSelectItems().subscribe({
      next: (items) => {
        this.tenantSelectItems = items;
        this.log.debug('Tenants loaded');
      },
      error: (err) => {
        this.log.error('Could not load tenants ', err);
      },
    });

    // Betöltjük a mérők típusait
    this.isMeterTypeLoading = true;
    this.silo.getMeterTypeSelectItems().subscribe({
      next: (items) => {
        this.meterTypeSelectItems = items;
        this.isMeterTypeLoading = false;
        this.log.debug('Meter types loaded');
        this.setupMeterSelectors();
      },
      error: (err) => {
        this.log.error('Could not load meter types ', err);
      },
    });

    //betöltjük az összes mérőt is
    this.isMeterLoading = true;
    this.meterService.getAllItems().subscribe({
      next: (items) => {
        this.meters = items;
        this.isMeterLoading = false;
        this.log.debug('Meters loaded');
        this.setupMeterSelectors();
      },
      error: (err) => {
        this.log.error('Could not load meters ', err);
      },
    });

    // a contract_date és a tenancy_starts_at mezőknek a mai dátumot kell tartalmazniuk
    this.contractForm.controls.contract_date.setValue(
      format(new Date(), 'yyyy-MM-dd')
    );
    this.contractForm.controls.tenancy_starts_at.setValue(
      format(new Date(), 'yyyy-MM-dd')
    );

    //letiltjuk ez end mezőt
    this.contractForm.controls.tenancy_ends_at.disable();

    // feliratkozunk a contractForm open_ended mező változásaira
    this.contractForm.controls.open_ended.valueChanges.subscribe((value) => {
      //this.log.debug('Contract form open end changed: ', value);
      // ha a határozatlan idejű checkbox változik, akkor elmentjük az értékét
      if (value !== undefined && value !== null) {
        this.isOpenEnded = value;
        //tenancy_ends_at mezőt csak akkor kell engedélyezni, ha nem határozatlan idejű a szerződés
        if (this.isOpenEnded) {
          this.contractForm.controls.tenancy_ends_at.disable();
          // ha disabled, akkor töröljük a mező értékéet, mintha mi sem történt volna
          this.contractForm.controls.tenancy_ends_at.setValue('');
        } else {
          this.contractForm.controls.tenancy_ends_at.enable();
          this.contractForm.controls.tenancy_ends_at.setValue(
            format(new Date(), 'yyyy-MM-dd')
          );
        }
      }
    });
  }

  setupMeterSelectors() {
    if (!this.isMeterTypeLoading && !this.isMeterLoading) {
      // ha mindkét lista betöltött, akkor a selectort is beállítjuk
      this.selectedMeterType = this.meterTypeSelectItems[0];
      this.filterMetersByType();
    }
  }

  filterMetersByType() {
    // ez a metódus kiválogatja a megfelelő mérőket a kiválasztott típus alapján
    if (this.selectedMeterType) {
      this.meterSelectItems = this.meters.filter(
        (meter) => meter.meterType?.['@id'] === this.selectedMeterType?.val
      );
    }
  }

  // ez keresi a megfelelő mérőket a beírt szöveg alapján
  customSearchForSelect(term: string, item: IMeterModel) {
    term = term.toLowerCase();
    return (
      item.id.toString().indexOf(term) > -1 ||
      (item.meterType?.name?.toLowerCase() ?? '').indexOf(term) > -1
    );
  }

  saveProperty() {
    if (!this.createdProperty) {
      // csinálunk egy IFlatModel-t a form értékeiből
      // minden maradhat kivéve a size, azt stringgé kell alakítani
      const property = {
        ...this.propertyForm.value,
        flatNumber: this.propertyForm.value.flat_number,
        size: this.propertyForm.value.size?.toString(),
        flatType: { '@id': '/admin/api/flat_types/1', id: 1 } as IFlatTypeModel,
      } as IFlatModel;

      this.log.debug('Saving property:', property);
      this.flatService.createFlat(property).subscribe({
        next: (data) => {
          this.log.debug('Property saved: ', data);
          this.createdProperty = data;
        },
        error: (err) => {
          this.log.error('Could not save property ', err);
        },
      });
    } else {
      this.log.debug('Property already saved');
    }
  }

  addMetersToProperty() {
    // a kiválasztott mérőket hozzáadjuk a létrehozott ingatlanhoz
    if (this.createdProperty && this.selectedMeters.length > 0) {
      const updatedProperty = {
        ['@id']: this.createdProperty['@id'],
        id: this.createdProperty.id,
        meters: this.selectedMeters,
      };
      this.log.debug('Adding meters to property');
      this.flatService.updateFlat(updatedProperty).subscribe({
        next: (data) => {
          this.log.debug('Meters added to property: ', data);
          this.router.navigate(['/properties', this.createdProperty?.id]);
        },
        error: (err) => {
          this.log.error('Could not add meters to property ', err);
          this.router.navigate(['/properties', this.createdProperty?.id]);
        },
      });
    }
  }

  saveContract() {
    if (!this.createdContract) {
      if (this.contractForm.valid && this.createdProperty) {
        const contract = {
          contract_number: this.contractForm.value.contract_number ?? '',
          tenancy_starts_at: this.contractForm.value.tenancy_starts_at,
          tenancy_ends_at: this.contractForm.value.tenancy_ends_at,
          contract_date: this.contractForm.value.contract_date,
          tenants: this.contractForm.value.tenants,
          flats: [this.createdProperty['@id']],
          open_ended: this.contractForm.value.open_ended,
        };

        this.log.debug('Saving contract', contract);
        this.contractService.createItem(contract).subscribe({
          next: (data) => {
            this.log.debug('Contract saved: ', data);
            this.arrangeFlatRelations(undefined, data);
          },
          error: (err) => {
            this.log.error('Could not save contract ', err);
          },
        });
      }
    } else {
      this.log.debug('Contract already saved');
    }
  }

  ngAfterViewInit(): void {
    //TODO: ezt az undormány preline szart lecserélni. Itt is, meg máshol is.
    setTimeout(() => {
      const stepperSelector = document.querySelector('#stepper');
      if (stepperSelector) {
        this.log.debug('Stepper found');
        this.stepper = new HSStepper(stepperSelector as HTMLElement);
        this.setupStepper();
      }
    }, 200);
  }

  setupStepper() {
    if (this.stepper) {
      this.stepper.on('beforeNext', (index: number) => {
        this.log.debug('[Stepper]: beforeNext: ' + index);
        this.next(index);
      });
      this.stepper.on('finish', () => {
        this.log.debug('[Stepper]: finish');
        if (this.createdProperty?.id) {
          this.addMetersToProperty();
        } else {
          this.toastr.error('Could not save property');
        }
      });
    } else {
      this.log.error('Stepper not found');
    }
  }

  next(index: number) {
    if (index !== null && index !== undefined) {
      this.currentStep = index;
    }
    // ha most mentünk a második lapra, akkor mentjük az elsőt
    if (this.currentStep === 1) {
      this.saveProperty();
    } else if (this.currentStep === 2) {
      this.saveContract();
    }
  }

  previous(index: number) {
    if (index !== null && index !== undefined) {
      this.currentStep = index;
    }
  }

  private arrangeFlatRelations(
    oldContract: IContractModel | undefined,
    newContract: IContractModel
  ) {
    const oldUsers = oldContract
      ? oldContract.tenants?.map((tenant) => tenant.costBearerUser)
      : [];
    const newUsers = newContract.tenants?.map(
      (tenant) => tenant.costBearerUser
    );

    this.log.debug('Old users:', oldUsers);
    this.log.debug('New users:', newUsers);

    const usersToAdd = newUsers?.filter(
      (user) => !oldUsers?.some((oldUser) => oldUser?.id === user?.id)
    );
    const usersToRemove = oldUsers?.filter(
      (user) => !newUsers?.some((newUser) => newUser?.id === user?.id)
    );

    this.log.debug('Users to add:', usersToAdd);
    this.log.debug('Users to remove:', usersToRemove);

    // TODO: nem kezeli az eltávolítást
    // TODO: nem kezeli az eltávolítást, ha a tenant táblázat során keresztül törlünk
    // TODO: ez duplikált kód a property-detail-ből

    if (newContract.flats && newContract.flats[0].id) {
      this.rentalService.getItemsForFlat(newContract.flats[0].id).subscribe({
        next: (rents) => {
          this.log.debug('Rents:', rents);
          usersToAdd?.forEach((userToAdd) => {
            // megnézzük, hogy a userToAdd bérel-e már
            if (rents.some((rent) => rent.user?.id === userToAdd?.id)) {
              // ha igen, akkor nem kell hozzáadni
              this.log.debug('User already rents the flat:', userToAdd);
            } else {
              // ha nem, akkor hozzá kell adni
              this.log.debug('User does not rent the flat:', userToAdd);
              // csinálunk egy új bérlést
              const newRental = {
                user: userToAdd,
                // TODO: erős feltétel, hogy csak egy lakás legyen
                flat: newContract.flats?.[0],
                open_ended: newContract.open_ended,
                rental_start: newContract.tenancy_starts_at,
                rental_end: newContract.tenancy_ends_at,
              };
              this.rentalService.createItem(newRental).subscribe({
                next: () => {
                  this.log.debug('Rental added:', newRental);
                },
                error: (err) => {
                  this.log.error('Error adding rental:', err);
                },
              });
            }
          });
        },
        error: (err) => {
          this.log.error('Error getting rents:', err);
        },
      });
    } else {
      this.log.error('No flat id found');
    }
  }
}
