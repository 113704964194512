<tr>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    <ui-badge
      [color]="
        displayedBuilding === 'Lehel'
          ? 'blue'
          : displayedBuilding === 'Kunigunda'
          ? 'yellow'
          : 'black'
      "
      badgeType="soft"
      cornerType="rounded"
      class="w-24 inline-block"
      >{{ displayedBuilding }}</ui-badge
    >
    {{ pitem.flat | flatFormat }}
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-left"
  >
    {{ pitem.cleaning_date }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    <ui-badge
      [color]="
        pitem.cleaningType?.id == 1
          ? 'gray'
          : pitem.cleaningType?.id == 2
          ? 'teal'
          : pitem.cleaningType?.id == 3
          ? 'blue'
          : pitem.cleaningType?.id == 4
          ? 'yellow'
          : pitem.cleaningType?.id == 5
          ? 'orange'
          : 'red'
      "
      >{{ pitem.cleaningType?.name }}</ui-badge
    >
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    <ui-switch
      switchId="pitemdone"
      [checked]="!!pitem.cleaning_done"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800"
  >
    <div class="hs-tooltip inline-block">
      <span class="hs-tooltip-toggle"
        >{{ pitem.cleaning_note | slice : 0 : 30 }}@if (pitem.cleaning_note &&
        pitem.cleaning_note.length > 30){ ... }</span
      >
      <span
        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm"
        role="tooltip"
      >
        {{ pitem.cleaning_note }}
      </span>
    </div>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800"
  >
    <div class="hs-tooltip inline-block">
      <span class="hs-tooltip-toggle"
        >{{ pitem.cleaner_note | slice : 0 : 30 }}@if (pitem.cleaner_note &&
        pitem.cleaner_note.length > 30){ ... }</span
      >
      <span
        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm"
        role="tooltip"
      >
        {{ pitem.cleaner_note }}
      </span>
    </div>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium"
  >
    <div class="inline-flex rounded-lg shadow-sm w-max">
      <ui-button
        btnType="inline"
        (click)="handleView(pitem)"
        inlinePos="left"
        iconSrc="assets/img/eye.svg"
        data-hs-overlay="#hs-cleaning-schedule-editor-modal"
      >
        <span class="shrink-0 text-xs"> View </span>
      </ui-button>
      <ui-button
        btnType="inline"
        (click)="handleEdit(pitem)"
        iconSrc="assets/img/pencil.svg"
        data-hs-overlay="#hs-cleaning-schedule-editor-modal"
      >
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleDelete(pitem)"
        inlinePos="right"
        iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-cleaning-schedule-confirmation-modal"
      >
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </td>
</tr>
