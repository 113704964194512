import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PropertyDetailComponent } from './pages/property-detail/property-detail.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './services/guards/auth.guard';
import { ErrorComponent } from './pages/error/error.component';
import { DocumentInventoryComponent } from './pages/document-inventory/document-inventory.component';
import { InvoicingComponent } from './pages/invoicing/invoicing.component';
import { BookingComponent } from './pages/booking/booking.component';
import { CheckinsComponent } from './pages/checkins/checkins.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { ParkingComponent } from './pages/parking/parking.component';
import { CleaningComponent } from './pages/cleaning/cleaning.component';
import { IssuesComponent } from './pages/issues/issues.component';
import { TenantsComponent } from './pages/tenants/tenants.component';
import { BookingOverviewComponent } from './pages/booking-overview/booking-overview.component';
import { ArrivalsDeparturesComponent } from './pages/arrivals-departures/arrivals-departures.component';
import { IssueNotesComponent } from './components/issue-notes/issue-notes.component';
import { IssueDetailComponent } from './pages/issue-detail/issue-detail.component';
import { BookingDetailComponent } from './pages/booking-detail/booking-detail.component';
import { BookingEditComponent } from './pages/booking-edit/booking-edit.component';
import { UsersComponent } from './pages/users/users.component';
import { FlatTypeComponent } from './pages/flat-type/flat-type.component';
import { PropertiesComponent } from './pages/properties/properties.component';
import { CleaningTypeComponent } from './pages/cleaning-type/cleaning-type.component';
import { IssueStatusComponent } from './pages/issue-status/issue-status.component';
import { FloorComponent } from './pages/floor/floor.component';
import { TaxComponent } from './pages/tax/tax.component';
import { UnitComponent } from './pages/unit/unit.component';
import { NewPropertyWizardComponent } from './pages/new-property-wizard/new-property-wizard.component';
import { MeterDetailComponent } from './pages/meter-detail/meter-detail.component';
import { MetersPageComponent } from './pages/meters-page/meters-page.component';
import { PropertyIssuesComponent } from './pages/property-issues/property-issues.component';
import { SamplePageComponent } from './pages/sample-page/sample-page.component';
import { PropertyBillingComponent } from './pages/property-billing/property-billing.component';

// ide vesszük fel az útvonalakat
// 'canActivate: [AuthGuard]' kerüljön minden route-ra, ami belépést követel
export const routes: Routes = [
  { path: 'login', component: LoginComponent, title: 'Login' },
  { path: 'overview', component: DashboardComponent, title: 'Dashboard', canActivate: [AuthGuard]},
  { path: 'properties', component: PropertiesComponent, title: 'Property details', canActivate: [AuthGuard] },
  { path: 'properties/:id', component: PropertyDetailComponent, title: 'Property details', canActivate: [AuthGuard] },
  { path: 'properties/:id/document-inventory', component: DocumentInventoryComponent, title: 'Document Inventory', canActivate: [AuthGuard]},
  { path: 'properties/:id/issues', component: PropertyIssuesComponent, title: 'Issues', canActivate: [AuthGuard] },
  { path: 'properties/:id/billing', component: PropertyBillingComponent, title: 'Issues', canActivate: [AuthGuard] },
  { path: 'invoicing', component: InvoicingComponent, title: 'Invoicing', canActivate: [AuthGuard]},
  { path: 'bookings', component: BookingComponent, title: 'Booking', canActivate: [AuthGuard] },
  { path: 'bookings/:id', component: BookingDetailComponent, title: 'Booking Detail', canActivate: [AuthGuard]},
  { path: 'bookings/:id/edit', component: BookingEditComponent, title: 'Booking Info', canActivate: [AuthGuard ]},
  { path: 'checkins', component: CheckinsComponent, title: 'Checkins', canActivate: [AuthGuard] },
  { path: '', component: OverviewComponent, title: 'Overview', canActivate: [AuthGuard]},
  { path: 'parking', component: ParkingComponent, title: 'Parking', canActivate: [AuthGuard] },
  { path: 'cleaning', component: CleaningComponent, title: 'Cleaning', canActivate: [AuthGuard] },
  { path: 'issues', component: IssuesComponent, title: 'Issues', canActivate: [AuthGuard] },
  { path: 'issues/:id', component: IssueDetailComponent, title: 'Issue Details', canActivate: [AuthGuard] },
  { path: 'issues/:id/edit', component: IssueDetailComponent, title: 'Issue Details', canActivate: [AuthGuard] },
  { path: 'tenants', component: TenantsComponent, title: 'Tenants', canActivate: [AuthGuard] },
  { path: 'booking-overview', component: BookingOverviewComponent, title: 'Booking Overview', canActivate: [AuthGuard] },
  { path: 'arrival-departure', component: ArrivalsDeparturesComponent, title: 'Arrivals & Departures', canActivate: [AuthGuard] },
  { path: 'issue-notes', component: IssueNotesComponent, title: 'Issue Notes', canActivate: [AuthGuard] },
  { path: 'issue-detail', component: IssueDetailComponent, title: 'Issue Detail', canActivate: [AuthGuard]  },
  { path: 'users', component: UsersComponent, title: 'Users', canActivate: [AuthGuard]},
  { path: 'flat-type', component: FlatTypeComponent, title: 'Flat type', canActivate: [AuthGuard] },
  { path: 'cleaning-type', component: CleaningTypeComponent, title: 'Cleaning type', canActivate: [AuthGuard] },
  { path: 'issue-status', component: IssueStatusComponent, title: 'Issue Status', canActivate: [AuthGuard] },
  { path: 'floor', component: FloorComponent, title: 'Floor', canActivate: [AuthGuard] },
  { path: 'tax', component: TaxComponent, title: 'Tax', canActivate: [AuthGuard] },
  { path: 'unit', component: UnitComponent, title: 'Units', canActivate: [AuthGuard] },
  { path: 'new-property', component: NewPropertyWizardComponent, title: 'New Property', canActivate: [AuthGuard] },
  { path: 'meters', component: MetersPageComponent, title: 'Meter', canActivate: [AuthGuard]  },
  { path: 'meters/:id', component: MeterDetailComponent, title: 'Meter Detail', canActivate: [AuthGuard] },
  { path: 'sample-page', component: SamplePageComponent, title: 'Sample Page', canActivate: [AuthGuard] },
  { path: '**', component: ErrorComponent, title: 'Error'},

];
