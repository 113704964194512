import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { IParkingRequestModel } from './parking-request-model.interface';
import { ParkingRequestTransformService } from './parking-request-transform.service';
import { BookingTransformService } from '../booking/booking-transform.service';

@Injectable({
  providedIn: 'root',
})
export class ParkingRequestClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: ParkingRequestTransformService,
    private bookingTransformer: BookingTransformService
  ) {}

  /**
   * A parkolási kérelmek lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A parkolási kérelmek lekért oldala, nyers válaszban
   */
  private fetchParkingRequests(from?: string, to?: string, page?: number) {
    let params = new HttpParams();
    if (to) {
      params = params.set('start_date[before]', to);
    }
    if (from) {
      params = params.set('end_date[after]', from);
    }
    if (page) {
      params = params.set('page', page.toString());
    }
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.parkingRequests), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a parkolási kérelmek első oldalát, hogy megtudja az összes parkolási kérelem számát
   * @returns Az összes parkolási kérelem számossága
   */
  getTotalItems(from?: string, to?: string): Observable<number> {
    if (!this.totalItems || !!from || !!to) {
      return this.fetchParkingRequests(from, to).pipe(
        map((response: any) => {
          if (!from && !to) {
            this.totalItems = response['hydra:totalItems'];
          }
          return response['hydra:totalItems'];
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  private getRawParkingRequests(
    from?: string,
    to?: string,
    page?: number
  ): Observable<IParkingRequestModel[]> {
    return this.fetchParkingRequests(from, to, page).pipe(
      map((response: any) => {
        const parkingRequests = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
        return parkingRequests;
      })
    );
  }

  /**
   * Lekéri a parkolási kérelmeket
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A parkolási kérelmek oldala
   */
  getParkingRequests(
    from?: string,
    to?: string,
    page?: number
  ): Observable<IParkingRequestModel[]> {
    return this.getRawParkingRequests(from, to, page).pipe(
      map((requests: any) => {
        return requests.map((request: any) =>
          this.transformer.transformParkingRequest(request)
        );
      }),
      map((requests: any) => {
        // Az összes requesten belüli booking átalakítása, ha van request.booking
        return requests.map((request: any) => {
          if (request.booking) {
            request.booking = this.bookingTransformer.transformBooking(
              request.booking
            );
          }
          return request;
        });
      })
    );
  }

  /**
   * Egy parkolási kérelem törlése
   * @param id A törlendő parkolási kérelem azonosítója
   * @returns A törlés eredménye
   */
  deleteParkingRequest(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.parkingRequest) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy parkolási kérelem létrehozása
   * @param parkingRequest A létrehozandó parkolási kérelem adatai
   * @returns A létrehozott parkolási kérelem
   */
  createParkingRequest(
    parkingRequest: IParkingRequestModel
  ): Observable<IParkingRequestModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.parkingRequests),
        this.transformer.serializeParkingRequest(parkingRequest),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: any) => response as IParkingRequestModel)
      );
  }

  /**
   * Egy parkolási kérelem módosítása
   * @param parkingRequest A módosítandó parkolási kérelem adatai
   * @returns A módosított parkolási kérelem
   */
  updateParkingRequest(
    parkingRequest: IParkingRequestModel
  ): Observable<IParkingRequestModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.parkingRequest) +
          parkingRequest.id,
        this.transformer.serializeParkingRequest(parkingRequest),
        { headers }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: any) => response as IParkingRequestModel)
      );
  }

  getAllParkingRequests(
    from?: string,
    to?: string
  ): Observable<IParkingRequestModel[]> {
    // use the totalItems to get all the pages
    return this.getTotalItems(from, to).pipe(
      switchMap((totalItems) => {
        let pages = Math.ceil(totalItems / 30);
        let requests = [];
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getParkingRequests(from, to, i));
        }
        return forkJoin(requests).pipe(
          map((requests: any) => {
            let parkingRequests = [] as IParkingRequestModel[];
            for (let request of requests) {
              parkingRequests.push(...request);
            }
            return parkingRequests;
          })
        );
      })
    );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'ParkingRequestClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform parking request operation')
    );
  };
}
