import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertiesTableComponent } from '../../components/tables/properties-table/properties-table.component';
import { RouterLink } from '@angular/router';
import { LucideAngularModule } from 'lucide-angular';
import { IIssueModel } from '../../services/api/issue/issue-model.interface';
import {
  FlatClientService,
  FlatFilterType,
} from '../../services/api/flat/flat-client.service';
import { IssueClientService } from '../../services/api/issue/issue-client.service';
import { LogService } from '../../services/utils/log.service';
import { CommonModule } from '@angular/common';
import { IContractModel } from '../../services/api/contract/contract-model.interface';
import { ContractClientService } from '../../services/api/contract/contract-client.service';
import { HardCodedConfigService } from '../../services/utils/hard-coded-config.service';

@Component({
  selector: 'overview',
  standalone: true,
  imports: [
    LayoutMainComponent,
    PropertiesTableComponent,
    RouterLink,
    LucideAngularModule,
    CommonModule,
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.css',
})
export class OverviewComponent {
  contracts = [] as IContractModel[];
  issues = [] as IIssueModel[];
  leasedProperties = new Set<number>();
  numberOfContractsEndingSoon = 0;
  propertiesWithContractsEndingSoon = [] as number[];
  numberOfProperties = 0;
  numberOfUnleasedProperties = 0;
  openIssues = 0;

  // TODO mockadatok, ezeket majd a szerverről kell lekérni
  numberOfContractsEndingSoonLastWeek = 10;
  numberOfPropertiesLastWeek = 95;
  numberOfUnleasedPropertiesLastWeek = 80;

  isHappyAboutOpenIssues = false;
  isLoadingContracts = true;
  isLoadingOpenIssues = true;
  isLoadingProperties = true;
  isLoadingUnleasedProperties = true;

  // Csak a nyitott hibajegyeket kérjük ide
  issuesTableFilter = { status: { id: 1 } as IIssueModel };

  constructor(
    private readonly log: LogService,
    private readonly flatService: FlatClientService,
    private readonly issueService: IssueClientService,
    private readonly contractService: ContractClientService,
    private readonly hardcodedConfigService: HardCodedConfigService
  ) {}

  ngOnInit() {
    this.isLoadingContracts = true;
    this.isLoadingUnleasedProperties = true;
    this.isLoadingOpenIssues = true;
    this.isLoadingProperties = true;

    // ez most minden propertyt beszámol,
    this.flatService.getTotalItems(FlatFilterType.BOTH).subscribe({
      next: (numberOfFlats) => {
        this.log.debug('Flats loaded', numberOfFlats);
        this.numberOfProperties = numberOfFlats;
        this.isLoadingProperties = false;
        // a szerződéseket is lekérjük, de csak azután, hogy tudjuk mennyi a property
        this.contractService.getAllItems().subscribe({
          next: (contracts) => {
            this.log.debug('Contracts loaded', contracts);
            this.contracts = contracts;
            this.calculateUnleasedPropertiesAndContractsEnding();
          },
        });
      },
    });

    this.issueService.getAllIssues().subscribe({
      next: (issues) => {
        this.log.debug('Issues loaded', issues);
        this.issues = issues;
        setTimeout(() => {
          this.calculateOpenIssues();
        }, 1000);
      },
    });
  }

  calculateOpenIssues() {
    this.log.debug('Calculating open issues', this.issues);
    this.openIssues = this.issues.filter(
      (issue) =>
        issue.issueStatus?.id !==
        this.hardcodedConfigService.CLOSED_ISSUE_STATUS_ID
    ).length;
    if (this.openIssues / this.issues.length > 0.3) {
      this.isHappyAboutOpenIssues = false;
    } else {
      this.isHappyAboutOpenIssues = true;
    }
    this.isLoadingOpenIssues = false;
  }

  calculateUnleasedPropertiesAndContractsEnding() {
    this.log.debug(
      'Calculating unleased properties and contracts',
      this.contracts
    );
    this.numberOfContractsEndingSoon = 0;
    this.propertiesWithContractsEndingSoon = [];
    this.contracts.forEach((contract: IContractModel) => {
      if (contract.flats) {
        contract.flats.forEach((flat) => {
          this.leasedProperties.add(flat.id);
        });
      }
      if (contract.tenancy_ends_at) {
        const endDate = new Date(contract.tenancy_ends_at);
        const now = new Date();
        if (
          endDate.getTime() - now.getTime() < 1000 * 60 * 60 * 24 * 60 &&
          endDate.getTime() > now.getTime()
        ) {
          this.numberOfContractsEndingSoon++;
          if (contract.flats) {
            contract.flats.forEach((flat) => {
              this.propertiesWithContractsEndingSoon.push(flat.id);
            });
          }
        }
      }
    });
    this.numberOfUnleasedProperties =
      this.numberOfProperties - this.leasedProperties.size;
    this.isLoadingUnleasedProperties = false;
    this.isLoadingContracts = false;
  }
}
