<div class="flex justify-between m-4">
  <div class="mb-4">
    <h2 class="font-bold text-lg">Meter readings</h2>
  </div>
  <!-- Upload Button -->
  <div class="flex items-center gap-x-2">
    <ui-button size="xsmall" iconSrc="assets/img/arrow-up-tray.svg"
      iconClass="invert saturate-0 brightness-0 contrast-125" data-hs-overlay="#hs-meters-reading-editor-modal"
      (click)="handleCreate()">Manual Enter</ui-button>
  </div>
</div>
<div class="overflow-x-auto sm:mx-4 sm:mb-4">
  <!-- Table -->
  <table class="min-w-full divide-y divide-gray-200 mb-2">
    <thead class="bg-gray-50">
      <tr class="border-t border-gray-200 divide-x divide-gray-200">
        <!-- TH Value-->
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button id="hs-pro-dutnms" type="button"
              class="text-gray-500 uppercase text-xs justify-end w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
              Value
              <img src="assets/img/up-down.svg" alt="Name" class="flex-shrink-0 w-3.5 h-3.5" />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms">
              <div class="p-1">
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 justify-center">
                  <img src="assets/img/arrow-up.svg" alt="Sort ascending" class="flex-shrink-0 w-3.5 h-3.5" />
                  Sort ascending
                </button>
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100">
                  <img src="assets/img/arrow-down.svg" alt="Sort descending" class="flex-shrink-0 w-3.5 h-3.5" />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <!-- /TH -->
        <!-- TH Timestamp-->
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button id="hs-pro-dutnms" type="button"
              class="text-gray-500 uppercase text-xs justify-end w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
              Timestamp
              <img src="assets/img/up-down.svg" alt="Name" class="flex-shrink-0 w-3.5 h-3.5" />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms">
              <div class="p-1">
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 justify-center">
                  <img src="assets/img/arrow-up.svg" alt="Sort ascending" class="flex-shrink-0 w-3.5 h-3.5" />
                  Sort ascending
                </button>
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100">
                  <img src="assets/img/arrow-down.svg" alt="Sort descending" class="flex-shrink-0 w-3.5 h-3.5" />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <!-- /TH -->
        <!-- TH Sampling type-->
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button id="hs-pro-dutnms" type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
              Sampling type
              <img src="assets/img/up-down.svg" alt="Name" class="flex-shrink-0 w-3.5 h-3.5" />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms">
              <div class="p-1">
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 justify-center">
                  <img src="assets/img/arrow-up.svg" alt="Sort ascending" class="flex-shrink-0 w-3.5 h-3.5" />
                  Sort ascending
                </button>
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100">
                  <img src="assets/img/arrow-down.svg" alt="Sort descending" class="flex-shrink-0 w-3.5 h-3.5" />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <!-- /TH -->
        <!-- TH Photo-->
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button id="hs-pro-dutnms" type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
              Photo
              <img src="assets/img/up-down.svg" alt="Name" class="flex-shrink-0 w-3.5 h-3.5" />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms">
              <div class="p-1">
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 justify-center">
                  <img src="assets/img/arrow-up.svg" alt="Sort ascending" class="flex-shrink-0 w-3.5 h-3.5" />
                  Sort ascending
                </button>
                <button type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100">
                  <img src="assets/img/arrow-down.svg" alt="Sort descending" class="flex-shrink-0 w-3.5 h-3.5" />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <!-- /TH -->

        <th scope="col" class="px-4 py-2 leading-4 text-right">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown relative inline-flex cursor-pointer">
            <button id="hs-pro-dutactions" type="button"
              class="text-gray-500 uppercase text-xs w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100 justify-end">
              Actions
            </button>
          </div>
          <!-- End Sort Dropdown -->
        </th>
      </tr>
    </thead>
    @if(isLoading){
    <tr>
      <td colspan="100">
        <div class="w-full flex justify-center content-center mt-16 min-h-28">
          <img src="assets/img/loading.gif" alt="loading" class="h-8 w-auto" />
          <div class="ml-4">Loading...</div>
        </div>
      </td>
    </tr>

    } @else{ @for (item of readings; track item.id) {

    <meter-reading-table-row (onEdit)="handleEdit($event)" (onDelete)="prepareDelete($event)" [pitem]="item"
      class="divide-y divide-gray-200">
    </meter-reading-table-row>
    } @empty {
    <tr>
      <td class="text-center" colspan="100">
        <!--  EMPTY STATE  -->
        <table-empty-state noDataTitle="No readings found"></table-empty-state>
        <!--  END EMPTY STATE  -->
      </td>
    </tr>

    } }
  </table>
</div>
<div class="mx-4 mb-4">
  <table-pager [totalItems]="totalItems" [isLoading]="isPagerLoading"
    (onPageChange)="handlePagination($event)"></table-pager>
</div>
<!-- ========== SECONDARY CONTENT ========== -->
<!-- Meter Reading Editor Modal -->
<meters-reading-editor [reading]="selectedReading" [mode]="editorMode" [meter]="meter" (onCreate)="refresh()"
  (onSave)="refresh()"></meters-reading-editor>
<!-- End Meter Reading Editor Modal -->
<!-- Delete Modal -->
<confirmation-dialog type="negative" hsId="hs-delete-meter-confirmation-modal" title="Delete confirmation" message="Are you sure you want to delete meter {{
      selectedReading.id
    }}? This action cannot be undone." confirmButtonText="Yes, Delete"
  (confirmed)="performDelete()"></confirmation-dialog>
<!-- End Delete Modal -->
<!-- ========== END SECONDARY CONTENT ========== -->