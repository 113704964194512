import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../api/api.service';
import { AuthenticationService } from '../authentication.service';
import { environment } from '../../../environments/environment';

/**
 * Azokra a route-okra, amik nincsenek benne az "excludeRoutes" array-ban, automatikusan hozzáadja a 'bearer' auth token-t
 *
 * HTTP interceptor leírás:
 * https://medium.com/@swapnil.s.pakolu/angular-interceptors-multiple-interceptors-and-6-code-examples-of-interceptors-59e745b684ec
 */
@Injectable()
export class BearertokenInterceptor implements HttpInterceptor {
  excludeRoutes: Array<string>;

  constructor(private authService: AuthenticationService) {
    // ezekre a route-okra NEM kell a bearer token
    this.excludeRoutes = [environment.baseUrl + API_ENDPOINTS.login];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldBeIntercepted(request.url)) {
      return this.addBearerToken(request, next);
    } else {
      return next.handle(request);
    }
  } // intercept end

  private shouldBeIntercepted(url: string) {
    if (url === environment.smsApiUrl) {
      return false;
    }
    return !this.excludeRoutes.some((route) => {
      const urlObj = new URL(url);
      const routeObj = new URL(route);
      return urlObj.pathname === routeObj.pathname;
    });
  }

  private addBearerToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let userAuthToken = this.authService.getUserToken();
    const authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${userAuthToken}`,
      },
    });
    return next.handle(authRequest);
  }
}
