import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { IInvoiceModel } from '../../../../services/api/invoice/invoice-model.interface';
import { DecimalPipe } from '@angular/common';
import { CurrencyValueCorrectorPipe } from '../../../../services/utils/currency-value-corrector.pipe';
import { LogService } from '../../../../services/utils/log.service';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { RouterLink } from '@angular/router';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { BillingAddressPipe } from '../../../../services/utils/billing-address.pipe';
import { InvoiceClientService } from '../../../../services/api/invoice/invoice-client.service';
import { ToastrService } from 'ngx-toastr';
import { InvoiceTransformService } from '../../../../services/api/invoice/invoice-transform.service';

@Component({
  selector: 'invoice-table-row',
  standalone: true,
  templateUrl: './invoice-table-row.component.html',
  styleUrl: './invoice-table-row.component.css',
  imports: [
    UiSwitchComponent,
    DecimalPipe,
    CurrencyValueCorrectorPipe,
    UiButtonComponent,
    RouterLink,
    UiBadgeComponent,
    BillingAddressPipe,
  ],
})
export class InvoiceTableRowComponent {
  @Input() pitem: IInvoiceModel = {} as IInvoiceModel;
  @Input() actions: 'normal' | 'demo' = 'normal';
  @Output() onDelete = new EventEmitter<IInvoiceModel>();
  @Output() onEdit = new EventEmitter<IInvoiceModel>();
  @Output() onView = new EventEmitter<IInvoiceModel>();
  @Output() onSend = new EventEmitter<IInvoiceModel>();
  constructor(
    private readonly log: LogService,
    private readonly invoiceClientService: InvoiceClientService,
    private readonly toastr: ToastrService,
    private readonly transformer: InvoiceTransformService
  ) {}

  handleEdit(pitem: IInvoiceModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IInvoiceModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IInvoiceModel) {
    this.onDelete.emit(pitem);
  }

  togglePaid() {
    this.pitem.paid = !this.pitem.paid;
    // csináljunk egy üres objektumot, amit csak a paid mezővel és id-val töltünk fel, mert csak ezt fogjuk felülcsapni
    const invoice: IInvoiceModel = {
      '@id': this.pitem['@id'],
      id: this.pitem.id,
      paid: this.pitem.paid,
    };

    this.invoiceClientService.updateInvoice(invoice).subscribe({
      next: (invoice: IInvoiceModel) => {
        this.log.info('Invoice updated');
        this.toastr.success('Invoice updated');
        this.pitem = this.transformer.transformInvoice(invoice);
      },
      error: (error) => {
        this.toastr.error('Error updating invoice');
        this.log.error('Error updating invoice', error);
        this.pitem.paid = !this.pitem.paid;
      },
    });
  }

  handleMessaging() {
    this.onSend.emit(this.pitem);
  }
}
