import { Injectable } from '@angular/core';
import { GenericApiClientService } from '../generic-api-client.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import { API_ENDPOINTS, ApiService } from '../api.service';
import { ITenantModel } from './tenant-model.interface';
import { TenantTransformService } from './tenant-transform.service';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { HardCodedConfigService } from '../../utils/hard-coded-config.service';
import { BillingAddressClientService } from '../billing-address/billing-address-client.service';
import { UserClientService } from '../user/user-client.service';
import { TenantNotificationService } from '../../utils/model-notifications/tenant-notification.service';

@Injectable({
  providedIn: 'root',
})
export class TenantClientService extends GenericApiClientService<ITenantModel> {
  constructor(
    http: HttpClient,
    apiService: ApiService,
    log: LogService,
    transformer: TenantTransformService,
    private hardCodedConfig: HardCodedConfigService,
    private billingAddressService: BillingAddressClientService,
    private userClientService: UserClientService,
    private tenantNotificationService: TenantNotificationService
  ) {
    super(http, apiService, log, transformer);
    this.itemsUrl = apiService.getUrlFor(API_ENDPOINTS.tenants);
    this.singleItemUrl = apiService.getUrlFor(API_ENDPOINTS.tenant);
  }

  override createItem(item: any): Observable<ITenantModel> {
    return super.createItem(item).pipe(
      tap((createdTenant) => {
        this.log.debug(
          'Tenant created, checking billingAddress',
          createdTenant
        );
        // szólunk annak, aki feliratkozott, hogy új tenant jött létre
        this.tenantNotificationService.notifyTenantCreated(createdTenant);
      }),
      switchMap((createdTenant) => {
        if (createdTenant.costBearerUser) {
          if (!createdTenant.costBearerUser.billingAddress) {
            this.log.debug('User does not have billing address, creating one');
            const billingAddress = this.hardCodedConfig.DEFAULT_BILLING_ADDRESS;
            billingAddress.name = createdTenant.costBearerUser?.name;
            billingAddress.company_name = createdTenant.companyName;
            billingAddress.tax_number = createdTenant.taxNumber;

            return this.billingAddressService
              .createBillingAddress(billingAddress)
              .pipe(
                switchMap((createdBillingAddress) => {
                  this.log.debug(
                    'Billing address created',
                    createdBillingAddress
                  );
                  return this.userClientService
                    .updateUser({
                      '@id': createdTenant.costBearerUser!['@id'],
                      id: createdTenant.costBearerUser!.id,
                      billingAddress: createdBillingAddress,
                    })
                    .pipe(
                      switchMap((updatedUser) => {
                        this.log.debug(
                          'User belonging to the new Tenant has been updated with billing address',
                          updatedUser
                        );
                        return of(createdTenant);
                      }),
                      catchError((error) => {
                        this.log.error(
                          'Error updating tenant user with billing address',
                          error
                        );
                        return of(createdTenant);
                      })
                    );
                }),
                catchError((error) => {
                  this.log.error(
                    'Error creating billing address for tenant user',
                    error
                  );
                  return of(createdTenant);
                })
              );
          } else {
            this.log.debug('User already has billing address');
            return of(createdTenant);
          }
        } else {
          this.log.error('Tenant does not have costBearerUser');
          return of(createdTenant);
        }
      }),
      catchError((error) => {
        this.log.error('Error creating tenant', error);
        return of(error);
      })
    );
  }
}
