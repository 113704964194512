import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './services/httpinterceptors';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import {
  ArrowRightFromLine,
  ArrowRightToLine,
  Building,
  Building2,
  CalendarCheck2,
  CalendarClock,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleHelp,
  ClipboardCheck,
  ClipboardList,
  DatabaseZap,
  DollarSign,
  Droplet,
  Droplets,
  Folder,
  Gauge,
  House,
  HousePlus,
  HousePlusIcon,
  Image,
  Lightbulb,
  LockOpen,
  LucideAngularModule,
  MapPin,
  MessagesSquare,
  Pin,
  Plug,
  PlugZap,
  PlugZap2,
  Plus,
  Router,
  ScrollText,
  Tags,
  ThermometerSnowflake,
  ThermometerSun,
  TriangleAlert,
  Users,
  Wrench,
  X,
} from 'lucide-angular';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    httpInterceptorProviders,
    provideAnimations(), // required animations providers
    provideToastr(), // Toastr providers
    importProvidersFrom(
      // Itt kell felsorolni azokat az ikonokat, amiket használni szeretnénk
      LucideAngularModule.pick({
        ArrowRightFromLine,
        ArrowRightToLine,
        Building,
        Building2,
        CalendarCheck2,
        CalendarClock,
        ChevronDown,
        ChevronLeft,
        ChevronRight,
        ChevronUp,
        CircleHelp,
        ClipboardCheck,
        ClipboardList,
        DatabaseZap,
        DollarSign,
        Droplet,
        Droplets,
        Folder,
        Gauge,
        House,
        HousePlus,
        HousePlusIcon,
        Image,
        Lightbulb,
        LockOpen,
        MapPin,
        MessagesSquare,
        Pin,
        Plug,
        PlugZap,
        PlugZap2,
        Plus,
        Router,
        ScrollText,
        Tags,
        ThermometerSnowflake,
        ThermometerSun,
        TriangleAlert,
        Users,
        Wrench,
        X,
      })
    ),
  ],
};
