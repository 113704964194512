<div class="">
  <div class="p-2">
    <form
      id="booking-billing-info"
      [formGroup]="bookingBillingForm"
    >
      <!--  form text  -->
      <div class="mb-4">
        <label for="booking-billing-name" class="block mb-2 font-bold"
          >Name</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="text"
          placeholder="Enter the name for billing"
          name="booking-billing-name"
          formControlName="billing_name"
          value="{{ booking?.billing_name }}"
        />
      </div>
      <!--  // form text  -->

      <!--  form select  -->
      <label for="booking-billing-country" class="block mb-2 font-bold"
        >Country</label
      >
      <ng-select
        class="w-full max-w-lg inline-block mb-4"
        [items]="countries"
        bindLabel="title"
        bindValue="val"
        formControlName="billing_country"
        [virtualScroll]="true"
        placeholder="Select Country"
      >
        <ng-template ng-label-tmp let-item="item">
          <div>
            <div class="flex items-center">
              <div class="me-2">
                <img
                  class="inline-block size-4 rounded-full"
                  [src]="item.options?.icon"
                  [alt]="item.options?.description"
                />
              </div>
              <div class="text-gray-800">
                {{ item.title }}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div>
            <div class="flex items-center">
              <div class="me-2">
                <img
                  class="inline-block size-4 rounded-full"
                  [src]="item.options?.icon"
                  [alt]="item.options?.description"
                />
              </div>
              <div class="text-gray-800">
                {{ item.title }}
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <!--  // form select  -->

      <!--  form text  -->
      <div class="mb-4">
        <label for="booking-billing-po-code" class="block mb-2 font-bold"
          >Zip Code</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="text"
          placeholder="Like '12345' or '12345-6789'"
          name="booking-billing-po-code"
          formControlName="billing_pocode"
          value="{{ booking?.billing_pocode }}"
        />
      </div>
      <!--  // form text  -->

      <!--  form text  -->
      <div class="mb-4">
        <label for="booking-billing-county" class="block mb-2 font-bold"
          >County</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="text"
          placeholder="Enter the county for billing"
          name="booking-billing-county"
          formControlName="billing_county"
          value="{{ booking?.billing_county }}"
        />
      </div>
      <!--  // form text  -->

      <!--  form text  -->
      <div class="mb-4">
        <label for="booking-billing-city" class="block mb-2 font-bold"
          >City</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="text"
          placeholder="Like 'New Yor'k' or 'Los Angeles'"
          name="booking-billing-city"
          formControlName="billing_city"
          value="{{ booking?.billing_city }}"
        />
      </div>
      <!--  // form text  -->

      <!--  form text  -->
      <div class="mb-4">
        <label for="booking-billing-address-line1" class="block mb-2 font-bold"
          >Billing Address Line 1</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="text"
          placeholder="Something practical, like “SmartApart II/4. - ECO"
          name="booking-billing-address-line1"
          formControlName="billing_address_line1"
          value="{{ booking?.billing_address_line1 }}"
        />
      </div>
      <!--  // form text  -->

      <!--  form text  -->
      <div class="mb-4">
        <label for="booking-billing-address-line2" class="block mb-2 font-bold"
          >Billing Address Line 2</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="text"
          placeholder="Billing Address line 2"
          name="booking-billing-address-line2"
          formControlName="billing_address_line2"
          value="{{ booking?.billing_address_line2 }}"
        />
      </div>
      <!--  // form text  -->

      <div class="w-full max-w-lg text-right">
        <ui-button 
          (click)="saveBookingBillingInfo()" 
          iconSrc="assets/img/save.svg" 
          iconClass="invert sature-0 brightness-0 contrast-125" 
          size="xsmall"
          [disabled]="bookingBillingForm.invalid" 
          class="inline-block" >
            Save
        </ui-button>
      </div>
    </form>
  </div>
</div>
