import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertyAndContractBoxComponent } from '../../components/property-and-contract-box/property-and-contract-box.component';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';
import { LogService } from '../../services/utils/log.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FlatClientService } from '../../services/api/flat/flat-client.service';
import { IFlatModel } from '../../services/api/flat/flat-model.interface';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { InvoiceClientService } from '../../services/api/invoice/invoice-client.service';
import { IInvoiceModel } from '../../services/api/invoice/invoice-model.interface';
import { BillingClientService } from '../../services/api/billing/billing-client.service';
import { IBillingModel } from '../../services/api/billing/billing-model.interface';
import { InvoiceTableRowComponent } from '../../components/tables/invoice-table/invoice-table-row/invoice-table-row.component';
import { TextMessageService } from '../../services/messaging/text-message.service';
import { CurrencyValueCorrectorPipe } from '../../services/utils/currency-value-corrector.pipe';
import { ContractClientService } from '../../services/api/contract/contract-client.service';
import { IContractModel } from '../../services/api/contract/contract-model.interface';

@Component({
  selector: 'property-billing',
  standalone: true,
  templateUrl: './property-billing.component.html',
  styleUrl: './property-billing.component.css',
  imports: [
    LayoutMainComponent,
    PropertyAndContractBoxComponent,
    TableEmptyStateComponent,
    RouterLink,
    UiButtonComponent,
    CommonModule,
    InvoiceTableRowComponent,
  ],
  providers: [DecimalPipe, CurrencyValueCorrectorPipe],
})
export class PropertyBillingComponent {
  contractIRI: string = '';
  flat!: IFlatModel;
  propertyTitle = '';
  isLoading = true;
  isLoadingInvoices = true;
  invoices: IInvoiceModel[] = [];
  billings: IBillingModel[] = [];

  // TODO: ezt a számot még ki kell bányászni valahonnan
  customerPhoneNumber = '+36x0xxxxxxx';

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private flatService: FlatClientService,
    private billingService: BillingClientService,
    private invoiceService: InvoiceClientService,
    private contractService: ContractClientService,
    private formatter: ModelFormatterService,
    private toastr: ToastrService,
    private readonly messagingService: TextMessageService,
    private readonly decimalPipe: DecimalPipe,
    private readonly currencyValueCorrectorPipe: CurrencyValueCorrectorPipe
  ) {}

  ngOnInit() {
    this.isLoading = true;
    //kiszedjük a lakás id-jét a paraméterek közül
    this.route.params.subscribe((params) => {
      let id = params['id'];
      //lekérjük a lakást
      this.flatService.getFlat(id).subscribe({
        next: (flat) => {
          this.flat = flat;
          this.propertyTitle = this.formatter.getFormattedFlat(
            this.flat,
            false
          );
          this.log.debug('Flat loaded', flat);
          this.refresh();
          this.isLoading = false;
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }

  refresh() {
    // TODO: itt erre API támogatás kell, mert ez messze van a hatékonytól.
    this.billingService.getAllItems().subscribe({
      next: (billings: IBillingModel[]) => {
        // csak azt tartjuk meg, ami ehhez a contract-hoz tartozik
        this.billings = billings.filter((billing) => {
          return billing.contract?.['@id'] === this.contractIRI;
        });
        this.log.debug('Billings loaded', billings);
        this.invoiceService.getAllInvoices().subscribe({
          next: (invoices: IInvoiceModel[]) => {
            this.invoices = invoices.filter((invoice) => {
              // ha az invoice-ban nincs billing, akkor kiesik
              if (!invoice.billing) {
                return false;
              } else {
                // ha az invoice-ban van billing és az szerepel is a billings-ben, akkor bent marad
                return this.billings.some((billing) => {
                  return billing.id === invoice.billing?.id;
                });
              }
            });
            this.log.debug('Invoices loaded', invoices);
            this.isLoadingInvoices = false;
          },
          error: (err) => {
            this.toastr.error('There was an error loading the invoices');
            this.log.error('Error loading invoices for flat', err);
          },
        });
      },
      error: (err) => {
        this.toastr.error('There was an error loading the invoices');
        this.log.error('Error loading billings for flat', err);
      },
    });

    // szerezzünk egy telszámot a customerhez
    if (this.contractIRI) {
      this.log.debug('Loading contract', this.contractIRI);
      this.contractService.getItemByIri(this.contractIRI).subscribe({
        next: (contract: IContractModel) => {
          const potentialPhoneNumbers: string[] = [];
          if (contract.tenants)
            for (let i = 0; i < contract.tenants.length; i++) {
              const pNumber = contract.tenants[i].tax_number ?? '';
              if (pNumber) {
                potentialPhoneNumbers.push(pNumber);
              }
            }
          // nézzük végig van-e benne értelmes telefonszám és az első ilyet vegyük
          // TODO fixme: ez egy nagyon egyszerű ellenőrzés, ami nem biztos, hogy elég
          for (let i = 0; i < potentialPhoneNumbers.length; i++) {
            if (potentialPhoneNumbers[i].length > 8) {
              this.customerPhoneNumber = potentialPhoneNumbers[i];
              break;
            }
          }
          this.log.debug(
            'Customer phone number loaded',
            this.customerPhoneNumber
          );
        },
        error: (err) => {
          this.log.error('Error loading customer phone number', err);
        },
      });
    }
  }

  handleContractChange(contractItem: string) {
    this.log.debug('Contract ID received', contractItem);
    this.contractIRI = contractItem;
    this.refresh();
  }

  handleMessaging(invoice: IInvoiceModel) {
    if (!invoice.paid) {
      this.log.debug('Text Messaging user');

      this.messagingService
        .sendInvoiceReminder(
          '#' + invoice.id,
          this.currencyValueCorrectorPipe.transform(
            this.decimalPipe.transform(invoice.gross_total, '1.2-2')
          ) +
            ' ' +
            invoice.currency?.symbol,
          this.customerPhoneNumber
        )
        .subscribe({
          next: () => {
            this.toastr.success(
              'Text message sent to: ' + this.customerPhoneNumber
            );
          },
          error: (error) => {
            this.toastr.error('Error sending text message');
            this.log.error('Error sending text message', error);
          },
        });
    }
  }
}
