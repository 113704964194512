import { 
  Component, 
  Input, 
  Output, 
  EventEmitter, 
  ChangeDetectionStrategy } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'report-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiSwitchComponent,
    NgSelectModule,

  ],
  templateUrl: './report-editor.component.html',
  styleUrl: './report-editor.component.css'
})
export class ReportEditorComponent {
//  @Input() reportRequest!: IxxxModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

}
