import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, Observable, map } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  ApiService,
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
} from '../api.service';
import { IReadingModel } from './reading-model.interface';
import { ReadingTransformService } from './reading-transform.service';
import { GenericApiClientService } from '../generic-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class ReadingClientService extends GenericApiClientService<IReadingModel> {
  constructor(
    http: HttpClient,
    apiService: ApiService,
    log: LogService,
    transformer: ReadingTransformService
  ) {
    super(http, apiService, log, transformer);
    this.itemsUrl = apiService.getUrlFor(API_ENDPOINTS.readings);
    this.singleItemUrl = apiService.getUrlFor(API_ENDPOINTS.reading);
  }

  /**
   * Adott mérőhőz tartozó értékek lekérése a szerverről
   * @param meterId A mérő azonosítója
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns Az elemek lekért oldala, nyers válaszban
   */
  private fetchItemsForMeter(meterId: number, page?: number): any {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    const url =
      this.apiService.getUrlFor(API_ENDPOINTS.meter) + meterId + '/consuptions';
    return this.http
      .get(url, {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  getMeterReadings(
    meterId: number,
    page?: number
  ): Observable<IReadingModel[]> {
    return this.fetchItemsForMeter(meterId, page).pipe(
      map((response: any) => {
        this.log.debug('Readings for meter', meterId, response);
        return response['hydra:member'].map((item: IReadingModel) =>
          this.transformer!.transformItem(item)
        );
      })
    );
  }

  getTotalItemsForMeter(meterId: number): Observable<number> {
    return this.fetchItemsForMeter(meterId).pipe(
      map((response: any) => {
        return response['hydra:totalItems'];
      })
    );
  }

  getLastReading(meterId: number): Observable<IReadingModel> {
    return this.getMeterReadings(meterId).pipe(
      map((readings) => {
        if (readings.length === 0) {
          readings = [
            {
              reading: 'N/A',
              id: 0,
              '@id': '',
            },
          ];
        }
        // csak azért figyelmeztet, mert az Abstract ősben nincs feltétlenül ilyen
        // azért van !, mert biztosak vagyunk benne, hogy van transformerünk
        return this.transformer!.transformItem(readings[0]);
      })
    );
  }

  /**
   * Feltölti a fájlt az óraálláshoz. Multipart upload a "uploadable_file" mezőbe
   * @param readingId Az inventory elem azonosítója
   * @param file A feltöltendő fájl
   * @returns A feltöltés eredménye, IReadingModel, amiben megtalálható a file neve
   */
  uploadReadingFile(readingId: number, file: File): Observable<IReadingModel> {
    const formData = new FormData();
    formData.append('uploadable_file', file);
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.reading) +
          readingId +
          '/upload',
        formData
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IReadingModel)
      );
  }

  /**
   * Visszaadja a reading fájl URL-jét
   * @param filename Egy readinghez tartozó fájlnév
   * @returns Az URL amint betölthető a fájl
   */
  getReadingFileUrl(filename: string): string {
    return this.apiService.getUrlFor(API_ENDPOINTS.readingFile) + filename;
  }
}
