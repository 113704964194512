<layout-main id="content" role="main">
    <!-- BREADCRUMB -->
    <ol class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
        aria-label="Breadcrumb">
        <li class="inline-flex items-center leading-5">
            <a class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
                routerLink="/">Home
            </a>
            <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default">/</span>
        </li>
        <li class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
            aria-current="page">
            Sample
        </li>
    </ol>
    <!-- BREADCRUMB -->

    <!--  **** Booking Overview MAIN **** -->
    <div class="mb-4">
        <!--  TAB HEAD *******************************************************************************************************************-->

        <nav class="flex space-x-2 mb-4 xs:mx-4 sm:ml-0 overflow-x-auto" aria-label="Tabs" role="tablist">
            <ui-button id="tabs-with-underline-item-2" data-hs-tab="#tabs-with-underline-1"
                aria-controls="tabs-with-underline-2" role="tab" btnType="tab" iconSrc="assets/img/login-blue.svg"
                iconAlt="Billing adresses tab icon" class="active w-auto">Buttons <ui-badge>2</ui-badge></ui-button>
            <!-- TAB3 head  -->
            <ui-button id="tabs-with-underline-item-3" data-hs-tab="#tabs-with-underline-2"
                aria-controls="tabs-with-underline-3" role="tab" btnType="tab" iconSrc="assets/img/users-blue.svg"
                iconAlt="Payment transactions tab icon">Inputs <ui-badge>5</ui-badge></ui-button>
            <!-- TAB4 head  -->
            <ui-button id="tabs-with-underline-item-4" data-hs-tab="#tabs-with-underline-3"
                aria-controls="tabs-with-underline-4" role="tab" btnType="tab" iconSrc="assets/img/logout-blue.svg"
                iconAlt="Payment transactions tab icon">Badge<ui-badge>9</ui-badge></ui-button>
        </nav>
        <div class="bg-white border shadow-sm sm:rounded-xl p-4">

            <!--  ** 1 BUTTON  *************************************************************************************************************************-->
            <div id="tabs-with-underline-1" role="tabpanel" aria-labelledby="tabs-with-underline-item-1">
                <div>
                    <h2 class="mb-4 font-bold">Button Type</h2>
                    <div>
                        <ui-button btnType="solid" iconSrc="assets/img/bell.svg" iconClass=""
                            class="mb-4 mr-2 inline-block">Solid</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Orange</ui-button>
                        <ui-button btnType="green" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Green</ui-button>
                        <ui-button btnType="solid-negative" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Solid negative</ui-button>
                    </div>
                    <div>
                        <ui-button btnType="outline" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Outline</ui-button>
                        <ui-button btnType="outline-negative" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Outline negative</ui-button>
                    </div>

                    <div>
                        <ui-button btnType="tab" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">tab1</ui-button>
                        <ui-button btnType="tab" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">tab2</ui-button>
                        <ui-button btnType="tab" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">tab3</ui-button>
                    </div>
                    <div>
                        <ui-button btnType="ghost" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Ghost</ui-button>
                    </div>
                    <div>
                        <ui-button btnType="soft" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Soft</ui-button>
                    </div>
                    <div>
                        <ui-button btnType="inline" iconSrc="assets/img/bell.svg" class="mb-4 mr-2">Inline</ui-button>
                    </div>

                    <h2 class="mb-4 font-bold">Icon Pos</h2>
                    <div>
                        <ui-button btnType="orange" iconPos="left" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Orange left</ui-button>
                        <ui-button btnType="orange" iconPos="center" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Orange center</ui-button>
                        <ui-button btnType="orange" iconPos="right" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">Orange right</ui-button>
                    </div>
                    <h2 class="mb-4 font-bold">Size</h2>
                    <div>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" size="xsmall"
                            class="mb-4 mr-2 inline-block">xsmall</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" size="small"
                            class="mb-4 mr-2 inline-block">small</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" size="large"
                            class="mb-4 mr-2 inline-block">Large (default)</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg"
                            class="mb-4 mr-2 inline-block">(default)</ui-button>
                    </div>
                    <h2 class="mb-4 font-bold">Inline Pos</h2>
                    <div>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" inlinePos="left"
                            class="mb-4 mr-2 inline-block">Left</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" inlinePos="right"
                            class="mb-4 mr-2 inline-block">Right</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" inlinePos="solo"
                            class="mb-4 mr-2 inline-block">Solo</ui-button>
                        <ui-button btnType="orange" iconSrc="assets/img/bell.svg" inlinePos="center"
                            class="mb-4 mr-2 inline-block">Center</ui-button>

                    </div>
                </div>
            </div>

        <!--  // 1 BUTTON  -->

        <!--  B2 INPUT  *************************************************************************************************************************-->

        <div id="tabs-with-underline-2" class="hidden" role="tabpanel" aria-labelledby="tabs-with-underline-item-2">
            <div class="p-2">

                <!--  form select   -->
                <label for="booking-reservation-status" class="block font-bold">NG select</label>

                <ng-select class="w-full max-w-lg inline-block mb-4" labelForId="booking-reservation-status"
                    bindLabel="title" bindValue="val" [clearable]="false" formControlName="bookingStatus"
                    placeholder="Select booking status">
                </ng-select>

                <!--  form date  -->
                <div class="sm:flex gap-x-4 max-w-lg">
                    <div class="basis-1/2">
                        <label for="booking-reservation-start_date" class="block mb-2 font-bold">Start Date</label>
                        <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2" type="date"
                            placeholder="" name="booking-reservation-start_date" [value]="2024.04.12"
                            formControlName="start_date" />
                    </div>
                    <div class="basis-1/2">
                        <label for="booking-reservation-end_date" class="block mb-2 font-bold">End Date</label>
                        <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2" type="date"
                            placeholder="" name="booking-reservation-end_date" [value]="2024.05.06"
                            formControlName="end_date" />
                    </div>
                </div>
                <!--  // form date  -->

                <!--  form number  -->
                <label for="booking-reservation-adult_occupants" class="block mb-2 font-bold">Number</label>
                <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2" type="number"
                    placeholder="" name="booking-reservation-adult_occupants" [value]="2"
                    formControlName="adult_occupants" />
                <!--  // form number -->

                <!--  form number  -->
                <label for="booking-reservation-adult_occupants" class="block mb-2 font-bold">Ui Switch</label>
                <div class="mb-4">
                    <ui-switch [checked]="false" formControlName="paid" class="mr-4"></ui-switch>
                    <ui-switch [checked]="true" formControlName="paid" class="mr-4"></ui-switch>
                    <ui-switch [checked]="true" formControlName="paid" [disabled]=true ></ui-switch>
                </div>
                <!--  // form number -->

                <!--  form number  -->
                <label for="booking-reservation-adult_occupants" class="block mb-2 font-bold">Ui Switch</label>
                <div class="mb-4">
                    <ui-switch [checked]="false" type="solid"  formControlName="paid" class="mr-4"></ui-switch>
                    <ui-switch [checked]="true"  type="solid" formControlName="paid" class="mr-4"></ui-switch>
                    <ui-switch [checked]="true"  type="solid"  formControlName="paid" [disabled]=true ></ui-switch>
                </div>
                <!--  // form number -->                

                <!--  form number  -->
                <label for="booking-reservation-child_occupants" class="block mb-2 font-bold">Child Occupants</label>
                <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2" type="number"
                    placeholder="" name="booking-reservation-child_occupants" [value]="4"
                    formControlName="child_occupants" />
                <!--  // form number -->

                <!--  form number  -->
                <label for="booking-reservation-number_of_rooms" class="block mb-2 font-bold">Number of rooms</label>
                <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2" type="number"
                    placeholder="" name="booking-reservation-number_of_rooms" [value]="2"
                    formControlName="number_of_rooms" />
                <!--  // form number  -->

                <!--  form text  -->
                <label for="booking-reservation-code" class="block mb-2 font-bold">Text disabled</label>
                <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg bg-gray-200"
                    type="text" placeholder="" name="booking-reservation-code" disabled [value]="1234" />
                <!--  // form text  -->

                <!--  form text  -->
                <label for="booking-reservation-foreign_reservation_code" class="block mb-2 font-bold">Text</label>
                <input class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg" type="text"
                    placeholder="" name="booking-reservation-foreign_reservation_code" [value]="23"
                    formControlName="foreign_reservation_code" />
                <!--  // form text  -->

                <div class="w-full max-w-lg text-right">
                    <button type="submit"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        form="booking-reservation-form">
                        Save
                    </button>
                </div>

            </div>

        </div>

        <!-- // 2 INPUT -->
        <!--  BADGE 4  *************************************************************************************************************************-->

        <div id="tabs-with-underline-3" class="hidden" role="tabpanel" aria-labelledby="tabs-with-underline-item-3">
            <h2 class="mb-4 font-bold">Corner Type</h2>
            <div class="mb-2">
                <ui-badge cornerType="rounded" badgeType="soft" color="red" iconSrc="assets/img/bell.svg">Rounded</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="red" iconSrc="assets/img/bell.svg">Full</ui-badge>
            </div>            
            <h2 class="mb-4 font-bold">Icon Pos</h2>
            <div class="mb-2">
                <ui-badge cornerType="rounded" badgeType="soft" color="red" iconPos="left" iconSrc="assets/img/bell.svg">Left</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="red" iconPos="right" iconSrc="assets/img/bell.svg">Right</ui-badge>
            </div>     
            <h2 class="mb-4 font-bold">Badge Type</h2>
            <div class="mb-2">
                <ui-badge cornerType="rounded" badgeType="soft" color="red" iconPos="left" iconSrc="assets/img/bell.svg">Soft</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="solid"  color="red" iconPos="left" iconSrc="assets/img/bell.svg">Solid</ui-badge>
            </div>                                  
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="outline"  color="red" iconPos="left" iconSrc="assets/img/bell.svg">Outline</ui-badge>
            </div>                                              
            <h2 class="mb-4 font-bold">Color</h2>
            <div class="mb-2">
                <ui-badge cornerType="rounded" badgeType="soft" color="black" iconPos="left" iconSrc="assets/img/bell.svg">Black</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="blue" iconPos="left" iconSrc="assets/img/bell.svg">Blue</ui-badge>
            </div>                                  
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="gray" iconPos="left" iconSrc="assets/img/bell.svg">Gray</ui-badge>
            </div>                                                          
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="green" iconPos="left" iconSrc="assets/img/bell.svg">Green</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="orange" iconPos="left" iconSrc="assets/img/bell.svg">Orange</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="red" iconPos="left" iconSrc="assets/img/bell.svg">Red</ui-badge>
            </div>            
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="teal" iconPos="left" iconSrc="assets/img/bell.svg">Teal</ui-badge>
            </div>            
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="white" iconPos="left" iconSrc="assets/img/bell.svg">White</ui-badge>
            </div>
            <div class="mb-2">
                <ui-badge cornerType="full"  badgeType="soft"  color="yellow" iconPos="left" iconSrc="assets/img/bell.svg">Yellow</ui-badge>
            </div>            

        </div>
        <!-- // 4 -->
        </div>
    </div>






</layout-main>