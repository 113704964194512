<tr class="">
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <div class="w-full flex items-center gap-x-3">
      <div class="grow">
        <span class="text-sm font-medium">
          {{ pitem.name }}
        </span>
      </div>
    </div>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle w-max"
  >
    <!-- No contract -->
    @if(!pitem.hasContract){
    <ui-badge
      color="red"
      badgeType="solid"
      cornerType="rounded"
      iconSrc="assets/img/alert-triangle.svg"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 ml-2 w-max"
      >No active contract!</ui-badge
    >
    }@else{
    <!-- open tickets -->
    @if(isLoading){
    <div
      class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
    } @if(openIssues > 0 && !pitem.contractEndsSoon){
    <ui-badge
      color="blue"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 mx-2 w-max"
      >Has <span class="font-semibold">{{ openIssues }}</span> open
      tickets</ui-badge
    >
    }
    @if(pitem.contractEndsSoon){
      <ui-badge
      color="yellow"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 mx-2"
      >Contract ends in <span class="font-semibold">60</span> days</ui-badge
    >
    }
    @if (openIssues > 0 && pitem.contractEndsSoon ) {
      <ui-badge
      color="gray"
      badgeType="soft"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 mx-2"
      >+2</ui-badge
    >
    }
  }
    <!-- bills -->
    <!--  @if(pitem.id === 3){
    <ui-badge
      color="yellow"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 mx-2"
      >Contract ends in <span class="font-semibold">60</span> days</ui-badge
    ><ui-badge
    color="gray"
    badgeType="soft"
    cornerType="rounded"
    iconClass="h-4 invert"
    class="inline-block flex-shrink-0"
  >+2</ui-badge> } -->
    <!-- meters -->
    <!--     @if(pitem.id === 4){
    <ui-badge
      color="red"
      badgeType="solid"
      cornerType="rounded"
      iconSrc="assets/img/alert-triangle.svg"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 ml-2"
      >Has <span class="font-semibold">3</span> overdue bills </ui-badge
    >} -->
    <!-- readings -->
    <!--     @if(pitem.id === 5){
    <ui-badge
      color="yellow"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 mx-2"
    >
      Missed <span class="font-semibold">2</span> meter readings</ui-badge
    ><ui-badge
    color="gray"
    badgeType="soft"
    cornerType="rounded"
    iconClass="h-4 invert"
    class="inline-block flex-shrink-0"
  >+1</ui-badge> } -->
    <!-- higher usage -->
    <!--     @if(pitem.id === 6){<ui-badge
      color="yellow"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 mx-2"
    >
      High utility usage suspected </ui-badge
    >} -->
    <!-- others -->
    <!--     @if(pitem.id !== 4 && pitem.id !== 5 && pitem.id !== 7 && pitem.id !== 9 &&
    pitem.id !==10){
    <span class="text-sm">
      {{ pitem.status }}
    </span>
    } -->
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(contract){
      <span class="w-max inline-block">
    <img
      src="assets/img/avatar.png"
      alt="avatar"
      class="inline-block h-8 w-8 mr-2 rounded-full"
    /><!-- TBD -->
    <span class="text-sm">
      {{ contract.tenants?.[0]?.name }}
    </span>
    <ui-badge [color]="'green'" [badgeType]="'soft'" >9.9</ui-badge>
  </span>
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(contract?.tenants?.[0]?.costBearerUser){
    <div class="flex items-center gap-x-2 w-max">
      <img
        src="assets/img/phone.svg"
        alt="phone"
        class="inline-block h-5 leading-4"
      />
      <span class="text-xs text-teal-800">{{ contract?.tenants?.[0]?.tax_number ?? "-" }}</span>
    </div>
    <div class="flex items-center gap-x-2 w-max">
      <img
        src="assets/img/envelope.svg"
        alt="mail"
        class="inline-block h-5 leading-4"
      />
      <span class="text-xs text-teal-600">{{
        contract?.tenants?.[0]?.costBearerUser?.email
      }}</span>
    </div>
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle text-right"
  >
    @if(contract){
    <div class="inline-flex rounded-lg shadow-sm w-max justify-end">
      <button
        type="button"
        class="py-1 px-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
        (click)="onEditProperty()"
      >
        <img
          src="assets/img/home.svg"
          alt="home"
          class="inline-block h-5 leading-4"
        />
      </button>
      <button
        type="button"
        class="py-1 px-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
        (click)="onTenantInfo()"
      >
        <img
          src="assets/img/user.svg"
          alt="user"
          class="inline-block h-5 leading-4"
        />
      </button>
      <button
        type="button"
        class="py-1 px-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
        (click)="onEditBilling()"
      >
        <img
          src="assets/img/clipboard-edit.svg"
          alt="clipboard"
          class="inline-block h-5 leading-4"
        />
      </button>
    </div>
    <div
      class="hs-dropdown relative inline-flex [--auto-close:inside] [--placement:top-right]"
    >
      <button
        id="hs-pro-dutdm12"
        type="button"
        class="w-7 h-7 inline-flex justify-center items-center gap-x-2 rounded-lg bg-white hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50"
      >
        ...
      </button>

      <div
        class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-60 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] [0_10px_40px_10px_rgba(0,0,0,0.2)]"
        aria-labelledby="hs-pro-dutdm12"
      >
        <div class="p-1">
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
            (click)="onEditProperty()"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/home.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />
              Edit Property
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
            (click)="onTenantInfo()"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/user.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Tenant Info
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
            (click)="onEditBilling()"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/clipboard-edit.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Edit Billing
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
            (click)="onListOfBills()"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/clipboard-list.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Issued Bills
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <!--         <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/file-x.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Lease Termination
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div> -->
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
            (click)="onUtilityInfo()"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/gauge.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Utility Usage
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
            (click)="onInventoryInfo()"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/folder.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Inventory
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>
          <!--           <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/file-clock.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Previous Contracts
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div> -->
        </div>
      </div>
    </div>
    }@else {
    <div class="h-7 flex justify-end mr-7">
      <ui-button btnType="inline" inlinePos="solo" (click)="onEditProperty()">
        Add Contract
      </ui-button>
    </div>
    }
  </td>
</tr>
