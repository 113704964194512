import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ITenantModel } from '../../api/tenant/tenant-model.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Ez a service a tenant modellekkel kapcsolatos értesítéseket kezeli.
 * Az értesítések a tenant modellek létrehozásakor sülnek el. Így más service-ek, komponensek értesülhetnek a tenant modellek létrehozásáról.
 */
export class TenantNotificationService {
  private tenantCreatedSource = new Subject<ITenantModel>();

  tenantCreated$ = this.tenantCreatedSource.asObservable();

  notifyTenantCreated(tenant: ITenantModel) {
    this.tenantCreatedSource.next(tenant);
  }
}
