<div class="mb-4 max-w-2xl">
  <div class="bg-white border shadow-sm rounded-xl pt-6">
    <div class="flex justify-between items-center px-6 pb-6 border-b">
      <h3 class="font-bold text-gray-800">Chat History</h3>
    </div>
    <!-- Chat Bubble -->
    <ul class="space-y-5 p-4">
      @for (item of issueNotes; track item.id) {
<!--       <div class="font-bold border-b-2 mt-4 pb-1 mb-2">
        {{ item.created_at | date : "yyyy-MM-dd" }}
      </div> -->

      <!-- Check if system status change -->
      @if(false) {
      <!-- Chat -->
      <li class="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
        <span
          class="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600 mt-2"
        >
          <span class="text-sm font-medium text-white leading-none">SYS</span>
        </span>
        <!-- Card -->
        <div>
          <div
            class="bg-white border min-w-52 border-gray-400 rounded-2xl px-3 py-2 space-y-2 dark:bg-neutral-900 dark:border-neutral-700"
          >
            <div class="flex flex-wrap justify-between mb-2">
              <h4 class="text-gray-600 font-bold mr-3 leading-6 ">
                {{ item.createdByUser?.name }}
                <ui-badge [color]="'red'">Closed</ui-badge>
              </h4>
              <div class="text-xs leading-6 ">
                {{ item.created_at | date : "yyyy-MM-dd HH:mm" }}
              </div>
            </div>
            {{ item.note }}
          </div>
        </div>
        <!-- End Card -->
      </li>
      <!-- End Chat -->
      } @else { @if(item.createdByUser?.id != issue.issuerUser?.id) {
      <!-- Chat -->
      <li class="flex ms-auto gap-x-2 sm:gap-x-4">
        <div class="grow text-end space-y-2">
          <!-- Card -->
          <div
            class="inline-block min-w-52 bg-slate-100 rounded-2xl px-3 py-2 shadow-sm"
          >
            <div class="flex flex-wrap justify-between mb-2">
              <h4 class="text-gray-600 font-bold mr-3 leading-6">
                {{ item.createdByUser?.name }}
              </h4>
              <div class="text-xs text-gray-500 leading-6">
                {{ item.created_at | date : "yyyy-MM-dd HH:mm" }}
              </div>
            </div>
            {{ item.note }}
          </div>
          <!-- End Card -->
        </div>

        <span
          class="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-brand-colored-canvas border-gray-200 border mt-2"
        >
          <span class="text-sm font-medium text-brand-colored-canvas-text leading-none">{{ item.createdByUser?.name?.charAt(0) }}</span>
        </span>
      </li>
      <!-- End Chat -->
      } @else {
      <!-- Chat -->
      <li class="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
        <span
          class="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600 mt-2"
        >
          <span class="text-sm font-medium text-white leading-none"> {{ item.createdByUser?.name?.charAt(0) }}</span>
        </span>
        <!-- Card -->
        <div>
          <div
            class="bg-white border min-w-52 border-gray-200 rounded-2xl px-3 py-2 space-y-2 dark:bg-neutral-900 dark:border-neutral-700"
          >
            <div class="flex flex-wrap justify-between mb-2">
              <h4 class="text-gray-600 font-bold mr-3 leading-6">
                {{ item.createdByUser?.name }}
              </h4>
              <div class="text-xs text-gray-400 leading-6">
                {{ item.created_at | date : "yyyy-MM-dd HH:mm" }}
              </div>
            </div>
            {{ item.note }}
          </div>
        </div>
        <!-- End Card -->
      </li>
      <!-- End Chat -->
      } } }
    </ul>
    <!-- End Chat Bubble -->

    <!-- COMMENT BOX -->
    @if(mode === 'edit') {
    <div class="p-4">
      <label for="textarea-label" class="block text-sm font-medium mb-2"
        >Comment</label
      >
      <form
        id="comment-form"
        [formGroup]="commentForm"
      >
        <div class="">
          <textarea
            id="textarea-label"
            class="py-3 px-4 block border-gray-200 rounded-lg text-sm w-full focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            rows="3"
            placeholder="Type your note here&mldr;"
            formControlName="note"
          ></textarea>
          <ui-button
            class="flex-none block text-right mt-4"
            iconSrc="assets/img/send.svg"
            iconClass="h-4 invert saturate-0  brightness-0 contrast-125"
            size="small"
            [disabled]="commentForm.invalid"
            (click)="sendNote()"
          >
            Send Note
          </ui-button>
        </div>
      </form>
    </div>
    }
    <!-- END COMMENT BOX -->
  </div>
</div>
