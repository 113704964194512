<tr>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm font-bold text-gray-800 text-right"
  >
  @if(!pitem.reading) {
    <span class="text-xs lowercase text-gray-500">N/A</span>
    } @else {
    <span class="text-base font-bold font-mono tracking-wider">{{
      pitem.reading
    }}</span>
    <span class="text-xs font-bold tracking-wider text-gray-500">
      {{ pitem.meter?.meterType?.unit?.name }}</span
    >
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle text-right"
  >
    {{ pitem.read_at }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <!-- Manual or Automatic -->Manual
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <!-- Photos -->
    @if( attachedFile ) {
      <a href="{{ attachedFile }}" target="_blank">
      <i-lucide
      name="Image"
      class="w-6 h-6 inline-block"
      ></i-lucide>
    </a>
    } 
    @else {
      &ndash;
    }
     
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
        btnType="inline"
        (click)="handleEdit(pitem)"
        iconSrc="assets/img/pencil.svg"
        data-hs-overlay="#hs-editor-modal"
        inlinePos="left"
      >
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleDelete(pitem)"
        inlinePos="right"
        iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-confirmation-modal"
      >
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </td>
</tr>
