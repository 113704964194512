import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertiesTableComponent } from '../../components/tables/properties-table/properties-table.component';
import { RouterLink } from '@angular/router';
import { FlatTableComponent } from '../../components/tables/flat-table/flat-table.component';

@Component({
  selector: 'properties',
  standalone: true,
  imports: [
    LayoutMainComponent,
    PropertiesTableComponent,
    RouterLink,
    FlatTableComponent,
  ],
  templateUrl: './properties.component.html',
  styleUrl: './properties.component.css',
})
export class PropertiesComponent {
  isFiltered = false;
  filter: string[] = [];

  constructor() {}

  ngOnInit() {
    // routingból kiszedjük a queryparamot
    const urlParams = new URLSearchParams(window.location.search);
    this.isFiltered = urlParams.has('id');
    const ids = urlParams.getAll('id');
    this.filter = ids.length ? ids : [];
  }
}
