<div class="p-4">
  <h2 class="font-bold text-lg">Parking overview</h2>
  <div>A quick glance about the parking lot utilization</div>
  <hr class="my-4" />

  @if(isLoading) {
  <div class="w-full flex justify-center content-center mt-16 min-h-28">
    <img src="assets/img/loading.gif" alt="loading" class="h-8 w-auto" />
    <div class="ml-4">Loading...</div>
  </div>
  } @else{
  <!-- LEHEL *************************************************************** -->
  <h4 class="font-bold text-lg mb-4">Lehel</h4>
  @if (lehelP1OccupancyData.length) {
  <div class="flex overflow-x-auto pb-4">
    <div class="flex flex-row">
      <div class="w-24 text-center">
        <div class="text-sm text-left p-1 w-24 h-12">&nbsp;</div>
        <div class="text-sm text-left p-1 w-24 h-8 align-middle">
          <!-- TODO: A színt, a kezdőbetűt, a szintet és a kapacitást dinamikusan kezelni -->
          <ui-badge color="blue">L</ui-badge> P1 ({{ lehelP1Max }})
        </div>
        <div class="text-sm text-left p-1 w-24 h-8 align-middle">
          <ui-badge color="blue">L</ui-badge> P2 ({{ lehelP2Max }})
        </div>
      </div>

      @for(item of lehelP1OccupancyData; track item.date; let i = $index){
      <div class="group">
        <div class="text-xs p-1 text-gray-500 text-center font-bold w-12 h-12">
          {{ item.date }}
        </div>
        <!-- P1 -->
        <div
          class="text-sm font-medium p-1 text-center border-l border-t last:border-b group-last:border-e border-gray-300 w-12 h-8 align-middle"
          [ngClass]="
            item.free < 0
              ? 'bg-red-100'
              : item.free === 0
              ? 'bg-yellow-100'
              : item.free < lehelP1Max / 2
              ? 'bg-blue-100'
              : 'bg-green-100'
          "
        >
          {{ item.used }}
        </div>
        <!-- P2 -->
        <div
          class="text-sm font-medium p-1 text-center border-l border-t last:border-b group-last:border-e border-gray-300 w-12 h-8 align-middle"
          [ngClass]="
            lehelP2OccupancyData[i].free < 0
              ? 'bg-red-100'
              : item.free === 0
              ? 'bg-yellow-100'
              : item.free < lehelP2Max / 2
              ? 'bg-blue-100'
              : 'bg-green-100'
          "
        >
          {{ lehelP2OccupancyData[i].used }}
        </div>
      </div>
      }
    </div>
  </div>
  <div class="flex">
    <ui-badge class="mt-2 mr-2" color="green">Less than 50% occupied</ui-badge>
    <ui-badge class="mt-2 mr-2" color="blue">More than 50% occupied</ui-badge>
    <ui-badge class="mt-2 mr-2" color="yellow">Full</ui-badge>
    <ui-badge class="mt-2 mr-2" color="red">Overbooked</ui-badge>
  </div>
  } @else {
  <div class="text-center text-gray-500">No parking requests</div>
  }
  <!-- Kunigunda *************************************************************** -->
  <h4 class="font-bold text-lg mb-4 mt-8">Kunigunda</h4>
  @if(kunigundaOccupancyData.length) {
  <div class="flex overflow-x-auto pb-4">
    <div class="flex flex-row">
      <div class="w-24 text-center">
        <div class="text-sm text-left p-1 w-24 h-12">&nbsp;</div>
        <div class="text-sm text-left p-1 w-24 h-8 align-middle">
          <!-- TODO: A színt, a kezdőbetűt, a szintet és a kapacitást dinamikusan kezelni -->
          <ui-badge color="yellow">K</ui-badge> P1 ({{ kunigundaMax }})
        </div>
        <!--           <div class="text-sm text-left p-1 w-24 h-8 align-middle">
            <ui-badge color="blue">L</ui-badge> P2 (5)
          </div> -->
      </div>

      @for(item of kunigundaOccupancyData; track item.date){
      <div class="group">
        <div class="text-xs p-1 text-gray-500 text-center font-bold w-12 h-12">
          {{ item.date }}
        </div>
        <div
          class="text-sm font-medium p-1 text-center border-l border-t last:border-b group-last:border-e border-gray-300 w-12 h-8 align-middle"
          [ngClass]="
            item.free < 0
              ? 'bg-red-100'
              : item.free === 0
              ? 'bg-yellow-100'
              : item.free < kunigundaMax / 2
              ? 'bg-blue-100'
              : 'bg-green-100'
          "
        >
          {{ item.used }}
        </div>
        <!--           <div
            class="text-sm font-medium p-1 text-center last:border-b border-l border-t group-last:border-e border-gray-300 w-12h-8 align-middle"
            [ngClass]="
              item.free < 0
                ? 'bg-red-100'
                : item.free === 0
                ? 'bg-blue-100'
                : item.free < lehelMax / 2
                ? 'bg-yellow-100'
                : 'bg-green-100'
            "
          >
            {{ item.free }}
          </div> -->
      </div>
      }
    </div>
  </div>
  <div class="flex">
    <ui-badge class="mt-2 mr-2" color="green">Less than 50% occupied</ui-badge>
    <ui-badge class="mt-2 mr-2" color="blue">More than 50% occupied</ui-badge>
    <ui-badge class="mt-2 mr-2" color="yellow">Full</ui-badge>
    <ui-badge class="mt-2 mr-2" color="red">Overbooked</ui-badge>
  </div>
  } @else {
  <div class="text-center text-gray-500">No parking requests</div>
  } }
</div>
