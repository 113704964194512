import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { IInventoryItemModel } from '../../../services/api/inventory-item/inventory-item-model.interface';
import { DatePipe } from '@angular/common';
import { API_ENDPOINTS, ApiService } from '../../../services/api/api.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { ToastrService } from 'ngx-toastr';
import { InventoryItemClientService } from '../../../services/api/inventory-item/inventory-item-client.service';
import { LogService } from '../../../services/utils/log.service';

@Component({
  selector: 'document-inventory-table-row',
  standalone: true,
  templateUrl: './document-inventory-table-row.component.html',
  styleUrl: './document-inventory-table-row.component.css',
  imports: [DatePipe, UiSwitchComponent],
})
export class DocumentInventoryTableRowComponent {
  @Input() item: IInventoryItemModel = {} as IInventoryItemModel;
  @Output() onDelete: EventEmitter<IInventoryItemModel> = new EventEmitter();

  constructor(
    private readonly apiService: ApiService,
    private readonly renderer: Renderer2,
    private readonly toastr: ToastrService,
    private readonly log: LogService,
    private readonly inventoryItemClientService: InventoryItemClientService
  ) {}

  handleDownload() {
    const url =
      this.apiService.getUrlFor(API_ENDPOINTS.inventoryFile) +
      this.item.filename;
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', this.item.filename ?? 'file');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  toggleAccess() {
    this.item.private = !this.item.private;
    // csináljunk egy üres objektumot, amit csak a paid mezővel és id-val töltünk fel, mert csak ezt fogjuk felülcsapni
    const inventoryItem: IInventoryItemModel = {
      '@id': this.item['@id'],
      id: this.item.id,
      private: this.item.private,
    };

    this.inventoryItemClientService
      .updateInventoryItem(inventoryItem)
      .subscribe({
        next: (inventoryItem: IInventoryItemModel) => {
          this.log.info('Inventory item updated');
          this.toastr.success('Inventory item updated');
          this.item = inventoryItem;
        },
        error: (error) => {
          this.toastr.error('Error updating invoice');
          this.log.error('Error updating invoice', error);
          this.item.private = !this.item.private;
        },
      });
  }

  handleDelete() {
    this.onDelete.emit(this.item);
  }
}
