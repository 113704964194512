<tr>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm font-bold text-gray-800 align-middle"
  >
    {{ pitem.id }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(displayedBuilding){
    <ui-badge
      [color]="
        displayedBuilding === 'Lehel'
          ? 'blue'
          : displayedBuilding === 'Kunigunda'
          ? 'yellow'
          : 'black'
      "
      badgeType="soft"
      cornerType="rounded"
      class="w-24 inline-block"
      >{{ displayedBuilding }}</ui-badge
    >
    }
    {{ pitem.flat | flatFormat }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-right text-gray-800 align-middle"
  >
    <ui-badge
      [color]="
        pitem.meterType?.id == 1 || pitem.meterType?.id == 2
          ? 'yellow'
          : pitem.meterType?.id == 4
          ? 'orange'
          : pitem.meterType?.id == 5
          ? 'white'
          : pitem.meterType?.id == 6
          ? 'red'
          : pitem.meterType?.id == 3
          ? 'blue'
          : 'black'
      "
    >
      {{ pitem.meterType?.name }}
    </ui-badge>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-right text-gray-800 align-middle"
  >
    @if(lastReadingValue == 'N/A') {
    <span class="text-xs lowercase text-gray-500">N/A</span>
    } @else {
    <span class="text-base font-bold font-mono tracking-wider">{{
      lastReadingValue
    }}</span>
    <span class="text-xs font-bold tracking-wider text-gray-500">
      {{ pitem.meterType?.unit?.name }}</span
    >
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(lastReadingTime == 'N/A') {
    <span class="text-xs lowercase text-gray-500">N/A</span>
    } @else {
    {{ lastReadingTime }}
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle w-max"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
      btnType="inline"
      (click)="handleView()"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      data-hs-overlay="#hs-billing-address-editor-modal"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
      <ui-button
        btnType="inline"
        (click)="handleEdit(pitem)"
        iconSrc="assets/img/pencil.svg"
        data-hs-overlay="#hs-editor-modal"
      >
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleDelete(pitem)"
        inlinePos="right"
        iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-confirmation-modal"
      >
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </td>
</tr>
