import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertiesTableComponent } from '../../components/tables/properties-table/properties-table.component';
import { CheckinClientService } from '../../services/api/checkin/checkin-client.service';
import {
  FlatClientService,
  FlatFilterType,
} from '../../services/api/flat/flat-client.service';
import { LogService } from '../../services/utils/log.service';
import { IssueClientService } from '../../services/api/issue/issue-client.service';
import { IIssueModel } from '../../services/api/issue/issue-model.interface';
import { CommonModule, PercentPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ICheckinModel } from '../../services/api/checkin/checkin-model.interface';
import { format, isToday, parseISO } from 'date-fns';
import { BookingClientService } from '../../services/api/booking/booking-client.service';
import { IBookingModel } from '../../services/api/booking/booking-model.interface';
import {
  CleaningStatsService,
  ICleaningStats,
} from '../../services/overview-helpers/cleaning/cleaning-stats.service';
import { IssuesTableComponent } from '../../components/tables/issues-table/issues-table.component';
import { LucideAngularModule } from 'lucide-angular';
import { HardCodedConfigService } from '../../services/utils/hard-coded-config.service';

@Component({
  selector: 'dashboard',
  standalone: true,
  imports: [
    LayoutMainComponent,
    PropertiesTableComponent,
    PercentPipe,
    RouterLink,
    CommonModule,
    IssuesTableComponent,
    LucideAngularModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent {
  numberOfFlats = 0;
  issues = [] as IIssueModel[];
  checkins = [] as ICheckinModel[];
  bookings = [] as IBookingModel[];
  openIssues = 0;
  checkinsToday = 0;
  cleaningsToday = {} as ICleaningStats;
  expediaBookings = 0;
  bookingsStartingToday = 0;
  bookingsEndingToday = 0;

  isLoadingCheckins = true;
  isLoadingOpenIssues = true;
  isLoadingProperties = true;
  isLoadingCleanings = true;
  isloadingExpediaBookings = true;
  isLoadingBookingsStartingToday = true;
  isLoadingBookingsEndingToday = true;

  isHappyAboutOpenIssues = false;

  // Csak a nyitott hibajegyeket kérjük ide
  issuesTableFilter = { status: { id: 1 } as IIssueModel };

  constructor(
    private log: LogService,
    private checkinService: CheckinClientService,
    private flatService: FlatClientService,
    private issueService: IssueClientService,
    private bookingService: BookingClientService,
    private cleaningStatsService: CleaningStatsService,
    private hardcodedConfigService: HardCodedConfigService
  ) {}

  ngOnInit() {
    this.isLoadingCheckins = true;
    this.isLoadingOpenIssues = true;
    this.isLoadingProperties = true;
    this.isLoadingCleanings = true;
    this.isloadingExpediaBookings = true;
    this.isLoadingBookingsStartingToday = true;
    this.isLoadingBookingsEndingToday = true;

    const today = new Date();

    // ez most minden propertyt beszámol,
    this.flatService.getTotalItems(FlatFilterType.BOTH).subscribe({
      next: (numberOfFlats) => {
        this.log.debug('Flats loaded', numberOfFlats);
        this.numberOfFlats = numberOfFlats;
        this.isLoadingProperties = false;
      },
    });

    this.issueService.getAllIssues().subscribe({
      next: (issues) => {
        this.log.debug('Issues loaded', issues);
        this.issues = issues;
        setTimeout(() => {
          this.calculateOpenIssues();
        }, 1000);
      },
    });

    this.checkinService
      .getAllCheckins({ createdAt: format(today, 'yyyy-MM-dd') })
      .subscribe({
        next: (checkins) => {
          this.log.debug('Checkins loaded', checkins);
          this.checkins = checkins;
          setTimeout(() => {
            this.calculateCheckinsToday();
          }, 200);
        },
      });

    // Lekérjük a ma kezdődő foglalásokat
    this.bookingService
      .getAllBookings({ startDate: format(today, 'yyyy-MM-dd') })
      .subscribe({
        next: (bookings) => {
          this.log.debug('Bookings starting today loaded', bookings);
          // Csak azt számoljuk bele, ami tuti ma kezdődik és véglegesített
          this.bookingsStartingToday = bookings.filter(
            (booking) =>
              isToday(parseISO(booking.start_date ?? '1970-01-01')) &&
              booking.bookingStatus?.id ==
                this.hardcodedConfigService.FINALIZED_BOOKING_STATUS_ID
          ).length;
          this.isLoadingBookingsStartingToday = false;
          // Expediasokat is számoljuk
          this.expediaBookings = bookings.filter(
            (booking) =>
              booking.bookingSource?.id ==
                this.hardcodedConfigService.EXPEDIA_BOOKING_SOURCE_ID &&
              isToday(parseISO(booking.start_date ?? '1970-01-01')) &&
              booking.bookingStatus?.id ==
                this.hardcodedConfigService.FINALIZED_BOOKING_STATUS_ID
          ).length;
          this.isloadingExpediaBookings = false;
        },
      });

    // Lekérjük a ma véget érő foglalásokat
    this.bookingService
      .getAllBookings({ endDate: format(today, 'yyyy-MM-dd') })
      .subscribe({
        next: (bookings) => {
          this.log.debug('Bookings ending today loaded', bookings);
          // Csa azt számoljuk bele, ami tuti ma véget ér és véglegesített
          this.bookingsEndingToday = bookings.filter(
            (booking) =>
              isToday(parseISO(booking.end_date ?? '9999-12-31')) &&
              booking.bookingStatus?.id ==
                this.hardcodedConfigService.FINALIZED_BOOKING_STATUS_ID
          ).length;
          this.isLoadingBookingsEndingToday = false;
        },
      });

    this.cleaningStatsService.getCleaningStats().subscribe({
      next: (cleaningStats) => {
        if (!this.cleaningStatsService.isLoading()) {
          this.cleaningsToday = cleaningStats;
          this.isLoadingCleanings = false;
        }
      },
      error: (err) => {
        this.log.error('Error loading cleaning stats', err);
      },
    });
  }

  calculateOpenIssues() {
    this.log.debug('Calculating open issues', this.issues);
    this.openIssues = this.issues.filter(
      (issue) =>
        issue.issueStatus?.id !==
        this.hardcodedConfigService.CLOSED_ISSUE_STATUS_ID
    ).length;
    if (this.openIssues / this.issues.length > 0.3) {
      this.isHappyAboutOpenIssues = false;
    } else {
      this.isHappyAboutOpenIssues = true;
    }
    this.isLoadingOpenIssues = false;
  }

  calculateCheckinsToday() {
    this.log.debug('Calculating checkins today', this.checkins);

    this.checkinsToday = this.checkins.filter((checkin) =>
      isToday(parseISO(checkin.created_at ?? ''))
    ).length;
    this.isLoadingCheckins = false;
  }

  calculateExpediaBookings() {
    this.expediaBookings = this.bookings.filter(
      (booking) =>
        booking.bookingSource?.id ==
        this.hardcodedConfigService.EXPEDIA_BOOKING_SOURCE_ID
    ).length;
    this.isloadingExpediaBookings = false;
  }
}
