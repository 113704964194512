<tr>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.name }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.email }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.last_login | date : "medium" }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(pitem.billingAddress) {
    {{ pitem.billingAddress.po_code }}
    {{ pitem.billingAddress.town }}
    {{ pitem.billingAddress.street }}
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <!--@for (item of pitem.roles.; track item) {
        {{ item }}
        }  -->..
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-blue-600 align-middle font-bold cursor-pointer"
  >
    {{ pitem.userOwnsFlats }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.userRentsFlats }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.userOwnsBuildings }}
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle"
  >
  <!-- TODO: Ideiglenesen elrejtve -->
    <div class="inline-flex rounded-lg shadow-sm w-max hidden">
      <ui-button
        btnType="inline"
        (click)="handleView(pitem)"
        inlinePos="left"
        iconSrc="assets/img/eye.svg"
        data-hs-overlay="#hs-creator-modal"
      >
        <span class="shrink-0 text-xs"> View </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleEdit(pitem)"
        iconSrc="assets/img/pencil.svg"
        data-hs-overlay="#hs-creator-modal"
      >
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleDelete(pitem)"
        inlinePos="right"
        iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-confirmation-modal"
      >
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </td>
</tr>
