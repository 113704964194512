<!-- Billing items -->
<div class="bg-white border shadow-sm rounded-xl  p-4" >
  <div class="flex justify-between">
    <div class="">

      <h2 class="block font-bold text-lg mb-2">Billing items</h2>

    </div><!--  -->
    <div class="text-right" style="text-align: right">
      <ui-button size="xsmall" class="text-brand-text" iconSrc='assets/img/save.svg' iconClass="invert  saturate-0  brightness-0 contrast-125">Save Changes</ui-button>
    <!--  <button type="button"
        class=" text-white py-2 px-3 inline-flex align-center items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 hover:bg-blue-700">
        <img src="assets/img/save.svg" alt="save property" class="h-6"> 
      </button>  -->

    </div>
  </div>

  <!-----  ADD ENTRIES  -------------------->
  <div>
    <!-- Variants Card -->
    <div class="flex flex-col overflow-hidden">
      <!-- Header -->
      <div
        class="py-3 px-0 flex justify-between items-center gap-x-5 border-b border-stone-200 ">
        <h2 class="inline-block font-semibold ">
          Variants
        </h2>
      </div>
      <!-- End Header -->

      <!-- Body -->
      <div class="py-3 px-0 space-y-2">


        <!-- List -->
        <div id="hs-wrapper-for-copy">

          <!-- ********************************************************************************************** -->
          <!-- FORM ROW source1!!!!!!!  ********************************************************************* -->

          <div id="hs-content-for-copy"
            class="hidden pt-5 mt-5 border-t border-stone-200 first:pt-0 first:mt-0 first:border-t-0 md:pt-0 md:border-t-0  [--ignore-for-count]">
            <div class="flex items-end gap-2 mb-2 form-row-###">
              <div class="col-span-3 md:col-span-3">
                <!-- Input -->
                <div>
                  <label for="item-type###" class="mb-2 block leading-3 text-xs  ">
                    Item type
                  </label>
                  <select id="item-type###" name="item-type###"
                    class="py-2  px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500
                    disabled:opacity-50 disabled:pointer-events-none "
                    disabled>
                    <option selected>Open this select menu</option>
                    <option selected>Flat rate</option>
                    <option>Metered</option>
                  </select>
                </div>
                <!-- End Input -->
              </div>

              <div class="grow md:col-span-3">
                <!-- Input -->
                <div>
                  <label for="name###" class="mb-1.5 block leading-3 text-xs">
                    Name
                  </label>
                  <input id="name###" name="name###" type="text"
                    class="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm  placeholder:text-stone-500
                    focus:z-10 focus:border-green-600 focus:ring-green-600">
                </div>
                <!-- End Input -->
              </div>

              <div class="col-span-3 md:col-span-3 grow">
                <!-- Input -->
                <div>
                  <label for="price###" class=" mb-1.5 block leading-3 text-xs  ">
                    Price
                  </label>
                  <div class="relative w-full">
                    <input id="price###" name="price###" type="text"
                      class="py-2 ps-3 pe-12 block w-full border border-stone-200 rounded-lg text-sm  placeholder:text-stone-500
                      focus:z-10 focus:border-green-600 focus:ring-green-600">
                    <div
                      class="absolute inset-y-0 end-2.5 flex items-center z-20 ps-[5px] text-sm ">
                      &euro;
                    </div>
                  </div>
                </div>
                <!-- End Input -->
              </div>



              <div class="col-span-full basis-8 grow-0 text-right  self-center p4 shrink-0 md:col-span-1 md:text-end">

                <img src="assets/img/trash-2.svg" alt="save property" class="xButton@@@ h-5 ml-auto" data-rowcounter="###" id="delete###">
              </div>
            </div>
          </div>
          <!-- End Card -->

          <!-- ********************************************************************************************** -->
          <!-- FORM ROW source2!!!!!!!  ********************************************************************* -->
          <div id="hs-content-for-copy2"
            class="meters-form-row###
              hidden pt-5 mt-5 border-t border-stone-200 first:pt-0 first:mt-0 first:border-t-0 md:pt-0 md:border-t-0  [--ignore-for-count]">
            <div class="flex items-end gap-2 mb-2 form-row-###">
              <div class="col-span-2 md:col-span-3">
                <!-- Input -->
                <div>
                  <label for="item-type###" class="mb-2 block leading-3 text-xs  ">
                    Item type
                  </label>
                  <select id="item-type###" name="item-type###"
                    class="py-2 px-4 pe-9 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500
                    disabled:opacity-50 disabled:pointer-events-none border-gray-400 "
                    disabled>
                    <option selected>Open this select menu</option>
                    <option>Flat rate</option>
                    <option selected>Metered</option>
                  </select>
                </div>
                <!-- End Input -->
              </div>

              <div class="col-span-2 md:col-span-3 grow">
                <!-- Input -->
                <div>
                  <label for="connected-meter###" class="mb-2 block leading-3 text-xs  ">
                    Connected meter
                  </label>
                  <select id="connected-meter###" name="connected-meter###"
                    class="py-2 px-4 pe-9 block bg-white w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500
                    disabled:opacity-50 disabled:pointer-events-none ">
                    <option selected>Open this select menu</option>
                    <option>Electricity B34</option>
                    <option>Electricity B36</option>
                  </select>

                </div>
                <!-- End Input -->
              </div>
              <div class="col-span-1 md:col-span-2 grow">
                <!-- Input -->
                <div>
                  <label for="name###" class="mb-2 block leading-3 text-xs  ">
                    Name
                  </label>
                  <input id="name###" name="name###" type="text"
                    class="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600">
                </div>
                <!-- End Input -->
              </div>
              <div class="col-span-1 md:col-span-2 grow">
                <!-- Input -->
                <div>
                  <label for="unit###" class="mb-2 block leading-3 text-xs ">
                    Unit
                  </label>
                  <input id="unit###" name="unit###" type="text"
                    class="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm  placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600">
                </div>
                <!-- End Input -->
              </div>

              <div class="">
                <!-- Input -->
                <div class="grow">
                  <label for="price-per-unit###" class="mb-1.5 block leading-3 text-xs  ">
                    Price/Unit
                  </label>
                  <input id="price-per-unit###" name="price-per-unit###" type="text"
                    class="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600">
                </div>

              </div>
              <!-- End Input -->
              <div class="basis-8 grow-0 shrink-0 md:col-span-1 md:text-end">
                <img src="assets/img/trash-2.svg" alt="save property" class="xButton@@@ h-5 ml-auto cursor-pointer" data-rowcounter="###" (click)="deleteRow()" id="delete###">
              </div>



            </div>
          </div>
          <!-- End Card -->


        </div>
        <!-- End List -->
        <div id="hs-container">

        </div>
      </div>
      <!-- End Body -->

      <!-- Footer -->
      <div
        class="py-3 px-0 flex justify-between items-center gap-x-5 border-b border-stone-200 ">
        <!-- Add Variant -->
        <p>
         <!--  Ezeket nem alakítom át ui buttonra, mert erre írtam egy ts-ben kódot ami beszúrja a form részleteket ami első körben nem működött az ui buttonon   -->
          <button id="copy-markup" (click)="addCodeMeters($event)" type="button" data-container="#hs-container"
            data-source="#hs-content-for-copy" data-hs-copy-markup='{
                                "targetSelector": "#hs-content-for-copy",
                                "wrapperSelector": "#hs-wrapper-for-copy"
                                }'
            class="py-1.5 px-2 inline-flex items-center mr-2 gap-x-1 text-base font-semibold rounded-lg border border-blue-600 bg-white hover:bg-stone-50 
            disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-50 ">
            <img src="assets/img/add.svg" alt="add" class="xButton@@@ h-5 ml-auto cursor-pointer ">
            Add flat rate item
          </button> 
          <button id="copy-markup2" (click)="addCodeMeters($event)" type="button" data-container="#hs-container"
            data-source="#hs-content-for-copy2" data-hs-copy-markup='{
                                "targetSelector": "#hs-content-for-copy2",
                                "wrapperSelector": "#hs-wrapper-for-copy"
                                }'
            class="py-1.5 px-2 inline-flex items-center gap-x-1 text-base font-semibold rounded-lg border border-blue-600 bg-white  hover:bg-stone-50 disabled:opacity-50 
            disabled:pointer-events-none focus:outline-none focus:bg-stone-50 ">
            <img src="assets/img/add.svg" alt="add" class="xButton@@@ h-5 ml-auto cursor-pointer ">            Add meter
          </button>
        </p>
        <!-- End Add Variant -->
      </div>
      <!-- End Footer -->
    </div>
    <!-- End Variants Card -->




  </div>
</div>
<!--// Billing items -->
