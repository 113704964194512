import { Injectable } from '@angular/core';
import { BookingClientService } from '../api/booking/booking-client.service';
import { FlatClientService } from '../api/flat/flat-client.service';
import { InvoiceClientService } from '../api/invoice/invoice-client.service';
import { UserClientService } from '../api/user/user-client.service';
import { CurrencyClientService } from '../api/currency/currency-client.service';
import { LogService } from './log.service';
import { IUiSelectItem } from '../../components/atomic-ui-components/select/ui-select-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModelFormatterService } from './model-formatter.service';
import { IFlatModel } from '../api/flat/flat-model.interface';
import { IUserModel } from '../api/user/user-model.interface';
import { IBookingModel } from '../api/booking/booking-model.interface';
import { IInvoiceModel } from '../api/invoice/invoice-model.interface';
import { ICurrencyModel } from '../api/currency/currency-model.interface';
import { AuthenticationService } from '../authentication.service';
import { BuildingClientService } from '../api/building/building-client.service';
import { ContractClientService } from '../api/contract/contract-client.service';
import { IContractModel } from '../api/contract/contract-model.interface';
import { HardCodedConfigService } from './hard-coded-config.service';
import { BillingScheduleClientService } from '../api/billing-schedule/billing-schedule-client.service';
import { IBillingScheduleModel } from '../api/billing-schedule/billing-schedule-model.interface';
import { TenantClientService } from '../api/tenant/tenant-client.service';
import { ITenantModel } from '../api/tenant/tenant-model.interface';
import { BillingItemTypeClientService } from '../api/billing-item-type/billing-item-type-client.service';
import { IBillingItemTypeModel } from '../api/billing-item-type/billing-item-type-model.interface';
import { UnitClientService } from '../api/unit/unit-client.service';
import { TaxClientService } from '../api/tax/tax-client.service';
import { IUnitModel } from '../api/unit/unit-model.interface';
import { ITaxModel } from '../api/tax/tax-model.interface';
import { MeterTypeClientService } from '../api/meter-type/meter-type-client.service';
import { IMeterTypeModel } from '../api/meter-type/meter-type-model.interface';
import { TenantNotificationService } from './model-notifications/tenant-notification.service';

@Injectable({
  providedIn: 'root',
})
export class SelectItemLoaderService {
  constructor(
    private userService: UserClientService,
    private flatService: FlatClientService,
    private buildingService: BuildingClientService,
    private bookingService: BookingClientService,
    private invoiceService: InvoiceClientService,
    private currencyService: CurrencyClientService,
    private contractService: ContractClientService,
    private billingScheduleService: BillingScheduleClientService,
    private billingItemTypeService: BillingItemTypeClientService,
    private unitService: UnitClientService,
    private taxService: TaxClientService,
    private tenantService: TenantClientService,
    private meterTypeService: MeterTypeClientService,
    private formatter: ModelFormatterService,
    private log: LogService,
    private authService: AuthenticationService,
    private hardCodedConfig: HardCodedConfigService,
    private tenantNotificationService: TenantNotificationService
  ) {
    this.tenantNotificationService.tenantCreated$.subscribe((tenant) => {
      this.invalidateTenantsAndUsers();
    });
  }

  private userSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private userSelectItems: IUiSelectItem[] = [];
  private userUpdateInProgress = false;

  private delegateUserSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>(
    []
  );
  private delegateUserSelectItems: IUiSelectItem[] = [];

  private flatSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private flatSelectItems: IUiSelectItem[] = [];
  private flatUpdateInProgress = false;

  private buildingSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private buildingSelectItems: IUiSelectItem[] = [];
  private buildingUpdateInProgress = false;

  private parkingSpaceSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>(
    []
  );
  private parkingSpaceSelectItems: IUiSelectItem[] = [];
  private parkingSpaceUpdateInProgress = false;

  private bookingSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private bookingSelectItems: IUiSelectItem[] = [];
  private bookingUpdateInProgress = false;

  private invoiceSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private invoiceSelectItems: IUiSelectItem[] = [];
  private invoiceUpdateInProgress = false;

  private currencySelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private currencySelectItems: IUiSelectItem[] = [];
  private currencyUpdateInProgress = false;

  private contractSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private contractSelectItems: IUiSelectItem[] = [];
  private contractUpdateInProgress = false;

  private billingScheduleSelectItemsSubject = new BehaviorSubject<
    IUiSelectItem[]
  >([]);
  private billingScheduleSelectItems: IUiSelectItem[] = [];
  private billingScheduleUpdateInProgress = false;

  private billingItemTypeSelectItemsSubject = new BehaviorSubject<
    IUiSelectItem[]
  >([]);
  private billingItemTypeSelectItems: IUiSelectItem[] = [];
  private billingItemTypeUpdateInProgress = false;

  private unitSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private unitSelectItems: IUiSelectItem[] = [];
  private unitUpdateInProgress = false;

  private taxSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private taxSelectItems: IUiSelectItem[] = [];
  private taxUpdateInProgress = false;

  private tenantSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private tenantSelectItems: IUiSelectItem[] = [];
  private tenantUpdateInProgress = false;

  private meterSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
  private meterTypeSelectItems: IUiSelectItem[] = [];
  private meterTypeUpdateInProgress = false;

  // TODO: kivezetni, mert be van drótozva
  private genderSelectItems = this.hardCodedConfig.GENDER_SELECT_ITEMS;

  private refreshUserSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.userUpdateInProgress) {
      return;
    } else {
      this.userUpdateInProgress = true;
    }
    // reset
    this.userSelectItems = [];
    // Betöltjük a felhasználókat a dropdownhoz
    this.userService.getAllUsers().subscribe({
      // a service egyben adja vissza a felhasználókat
      next: (users: IUserModel[]) => {
        // a felhasználókat elmentjük a select itemekbe alakítva
        this.userSelectItems = users.map((user: IUserModel) => ({
          val: user['@id'],
          title: this.formatter.getFormattedUser(user),
        }));
        this.userSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Users', this.userSelectItems);

        // Külön elmentjük azokat a usereket, akikre issue-t lehet rendelni
        // TODO hardcoded, cserélni kell role-ra
        const issueDelegatedUsers = [
          'gordon@i11.hu',
          'tormap@i11.hu',
          'karbantarto@i11.hu',
          'karbantarto-vezeto@i11.hu',
          'booking@i11.hu',
          'gallgaborfm@gmail.com',
          'ionyikos@gmail.com',
        ];

        this.delegateUserSelectItems = users
          .filter((user: IUserModel) =>
            issueDelegatedUsers.includes(user.email ?? '')
          )
          .filter((user: IUserModel) =>
            issueDelegatedUsers.includes(user.email ?? '')
          )
          .map((user: IUserModel) => ({
            val: user['@id'],
            title: this.formatter.getFormattedUser(user),
          }));
        this.delegateUserSelectItemsSubject.next(this.delegateUserSelectItems);
        this.log.debug(
          'SILO completed: Delageted Users',
          this.delegateUserSelectItems
        );
        this.log.debug(
          'SILO completed: Delageted Users',
          this.delegateUserSelectItems
        );

        this.userUpdateInProgress = false;
        this.userSelectItemsSubject.next(this.userSelectItems);
      },
      error: (err) => {
        this.log.error('SILO error, Users', err);
        this.userUpdateInProgress = false;
      },
    });
  }

  private refreshFlatSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.flatUpdateInProgress) {
      return;
    } else {
      this.flatUpdateInProgress = true;
    }
    // reset
    this.flatSelectItems = [];
    // Betöltjük a lakásokat a dropdownhoz
    this.flatService.getAllFlats().subscribe({
      // a service egyben adja vissza a lakásokat
      next: (flats: IFlatModel[]) => {
        // a lakásokat elmentjük a select itemekbe
        this.flatSelectItems = flats
          // persze át kell alakítani a lakásokat select itemekké
          .map((flat: IFlatModel) => ({
            val: flat['@id'],
            title: this.formatter.getFormattedFlat(flat),
          }));
        this.flatSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Flats', this.flatSelectItems);
        this.flatUpdateInProgress = false;
        this.flatSelectItemsSubject.next(this.flatSelectItems);
      },
      error: (err) => {
        this.log.error('SILO Error, Flats', err);
        this.flatUpdateInProgress = false;
      },
    });
  }

  private refreshBuildingSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.buildingUpdateInProgress) {
      return;
    } else {
      this.buildingUpdateInProgress = true;
    }
    // reset
    this.buildingSelectItems = [];
    // Betöltjük a lakásokat a dropdownhoz
    this.buildingService.getBuildings().subscribe({
      // a service egyben adja vissza a lakásokat
      next: (buildings: IFlatModel[]) => {
        // a lakásokat elmentjük a select itemekbe
        this.buildingSelectItems = buildings
          // persze át kell alakítani a lakásokat select itemekké
          .map((building: IFlatModel) => ({
            val: building['@id'],
            title: building.name,
          }));
        this.buildingSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Buildings', this.buildingSelectItems);
        this.buildingUpdateInProgress = false;
        this.buildingSelectItemsSubject.next(this.buildingSelectItems);
      },
      error: (err) => {
        this.log.error('SILO Error, Buildings', err);
        this.buildingUpdateInProgress = false;
      },
    });
  }

  private refreshParkingSpaceSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.parkingSpaceUpdateInProgress) {
      return;
    } else {
      this.parkingSpaceUpdateInProgress = true;
    }
    // reset
    this.parkingSpaceSelectItems = [];
    // Betöltjük a lakásokat a dropdownhoz
    this.flatService.getAllParkingSpaces().subscribe({
      // a service egyben adja vissza a lakásokat
      next: (flats: IFlatModel[]) => {
        // a lakásokat elmentjük a select itemekbe
        this.parkingSpaceSelectItems = flats
          // persze át kell alakítani a lakásokat select itemekké
          .map((parkingSpace: IFlatModel) => ({
            val: parkingSpace['@id'],
            title:
              this.formatter.getFormattedFlat(parkingSpace) +
              ' - #' +
              parkingSpace.id,
          }));
        this.parkingSpaceSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: ParkingSpaces', this.flatSelectItems);
        this.parkingSpaceUpdateInProgress = false;
        this.parkingSpaceSelectItemsSubject.next(this.parkingSpaceSelectItems);
      },
      error: (err) => {
        this.log.error('SILO Error, ParkingSpaces', err);
        this.parkingSpaceUpdateInProgress = false;
      },
    });
  }

  private refreshBookingSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.bookingUpdateInProgress) {
      return;
    } else {
      this.bookingUpdateInProgress = true;
    }
    // reset
    this.bookingSelectItems = [];
    // Betöltjük a foglalásokat a dropdownhoz
    this.bookingService.getAllBookings().subscribe({
      // a service egyben adja vissza a foglalásokat
      next: (bookings: IBookingModel[]) => {
        // elmentjük a foglalásokat a select itemekbe
        this.bookingSelectItems = bookings
          // de persze előtt át kell alakítani a bookingokat select itemekké
          .map((booking: IBookingModel) => ({
            val: booking['@id'],
            title: this.formatter.getFormattedBooking(booking),
          }));
        this.bookingSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Bookings', this.bookingSelectItems);
        this.bookingUpdateInProgress = false;
        this.bookingSelectItemsSubject.next(this.bookingSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading bookings:', err);
        this.bookingUpdateInProgress = false;
      },
    });
  }

  private refreshInvoiceSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.invoiceUpdateInProgress) {
      return;
    } else {
      this.invoiceUpdateInProgress = true;
    }
    // reset
    this.invoiceSelectItems = [];
    // Betöltjük a számlákat a dropdownhoz
    this.invoiceService.getAllInvoices().subscribe({
      // a service egyben adja vissza a számlákat
      next: (invoices: IInvoiceModel[]) => {
        // a számlákat elmentjük a select itemekbe
        this.invoiceSelectItems = invoices.map((invoice: IInvoiceModel) => ({
          val: invoice['@id'],
          title: this.formatter.getFormattedInvoice(invoice),
        }));
        this.invoiceSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Invoices', this.invoiceSelectItems);
        this.invoiceUpdateInProgress = false;
        this.invoiceSelectItemsSubject.next(this.invoiceSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading invoices:', err);
        this.invoiceUpdateInProgress = false;
      },
    });
  }

  private refreshCurrencySelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.currencyUpdateInProgress) {
      return;
    } else {
      this.currencyUpdateInProgress = true;
    }
    // reset
    this.currencySelectItems = [];
    // Betöltjük a valutákat a dropdownhoz
    this.currencyService.getCurrencies().subscribe({
      // a service egyben adja vissza a valutákat
      next: (currencies: ICurrencyModel[]) => {
        // a valutákat elmentjük a select itemekbe
        this.currencySelectItems = currencies.map(
          (currency: ICurrencyModel) => ({
            val: currency['@id'],
            title: currency.name,
          })
        );
        this.currencySelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Currencies', this.currencySelectItems);
        this.currencyUpdateInProgress = false;
        this.currencySelectItemsSubject.next(this.currencySelectItems);
      },
      error: (err) => {
        this.log.error('Error loading currencies:', err);
        this.currencyUpdateInProgress = false;
      },
    });
  }

  private refreshBillingScheduleSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.billingScheduleUpdateInProgress) {
      return;
    } else {
      this.billingScheduleUpdateInProgress = true;
    }
    // reset
    this.billingScheduleSelectItems = [];
    // Betöltjük a számlázási időszakokat a dropdownhoz
    this.billingScheduleService.getAllItems().subscribe({
      // a service egyben adja vissza a számlázási időszakokat
      next: (billingSchedules: IBillingScheduleModel[]) => {
        // a számlázási időszakokat elmentjük a select itemekbe
        this.billingScheduleSelectItems = billingSchedules.map(
          (billingSchedule: IBillingScheduleModel) => ({
            val: billingSchedule['@id'],
            title: billingSchedule.name,
          })
        );
        this.billingScheduleSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug(
          'SILO completed: BillingSchedules',
          this.billingScheduleSelectItems
        );
        this.billingScheduleUpdateInProgress = false;
        this.billingScheduleSelectItemsSubject.next(
          this.billingScheduleSelectItems
        );
      },
      error: (err) => {
        this.log.error('Error loading billing schedules:', err);
        this.billingScheduleUpdateInProgress = false;
      },
    });
  }

  private refreshBillingItemTypeSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.billingItemTypeUpdateInProgress) {
      return;
    } else {
      this.billingItemTypeUpdateInProgress = true;
    }
    // reset
    this.billingItemTypeSelectItems = [];
    // Betöltjük a számlázási időszakokat a dropdownhoz
    this.billingItemTypeService.getAllItems().subscribe({
      // a service egyben adja vissza a számlázási időszakokat
      next: (billingItemTypes: IBillingItemTypeModel[]) => {
        // a számlázási időszakokat elmentjük a select itemekbe
        this.billingItemTypeSelectItems = billingItemTypes.map(
          (billingItemType: IBillingItemTypeModel) => ({
            val: billingItemType['@id'],
            title: billingItemType.name,
          })
        );
        this.billingItemTypeSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug(
          'SILO completed: BillingItemTypes',
          this.billingItemTypeSelectItems
        );
        this.billingItemTypeUpdateInProgress = false;
        this.billingItemTypeSelectItemsSubject.next(
          this.billingItemTypeSelectItems
        );
      },
      error: (err) => {
        this.log.error('Error loading billing item types:', err);
        this.billingItemTypeUpdateInProgress = false;
      },
    });
  }

  private refreshUnitSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.unitUpdateInProgress) {
      return;
    } else {
      this.unitUpdateInProgress = true;
    }
    // reset
    this.unitSelectItems = [];
    // Betöltjük a mértékegységeket a dropdownhoz
    this.unitService.getAllItems().subscribe({
      // a service egyben adja vissza a mértékegységeket
      next: (units: IUnitModel[]) => {
        // a mértékegységeket elmentjük a select itemekbe
        this.unitSelectItems = units.map((unit: IUnitModel) => ({
          val: unit['@id'],
          title: unit.name,
        }));
        this.unitSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Units', this.unitSelectItems);
        this.unitUpdateInProgress = false;
        this.unitSelectItemsSubject.next(this.unitSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading units:', err);
        this.unitUpdateInProgress = false;
      },
    });
  }

  private refreshTaxSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.taxUpdateInProgress) {
      return;
    } else {
      this.taxUpdateInProgress = true;
    }
    // reset
    this.taxSelectItems = [];
    // Betöltjük a adókat a dropdownhoz
    this.taxService.getAllItems().subscribe({
      // a service egyben adja vissza a adókat
      next: (taxes: ITaxModel[]) => {
        // a adókat elmentjük a select itemekbe
        this.taxSelectItems = taxes.map((tax: IUnitModel) => ({
          val: tax['@id'],
          title: this.formatter.getFormattedTax(tax),
        }));
        this.taxSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Taxes', this.taxSelectItems);
        this.taxUpdateInProgress = false;
        this.taxSelectItemsSubject.next(this.taxSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading taxes:', err);
        this.taxUpdateInProgress = false;
      },
    });
  }

  private refreshContractSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.contractUpdateInProgress) {
      return;
    } else {
      this.contractUpdateInProgress = true;
    }
    // reset
    this.contractSelectItems = [];
    // Betöltjük a szerződéseket a dropdownhoz
    this.contractService.getAllItems().subscribe({
      // a service egyben adja vissza a szerződéseket
      next: (contracts: IContractModel[]) => {
        // elmentjük a szerződéseket a select itemekbe
        this.contractSelectItems = contracts
          // de persze előtt át kell alakítani a szerződéseket select itemekké
          .map(
            (contract: IContractModel) =>
              ({
                val: contract['@id'],
                title: this.formatter.getFormattedContract(contract),
              } as IUiSelectItem)
          );
        this.contractSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Contracts', this.contractSelectItems);
        this.contractUpdateInProgress = false;
        this.contractSelectItemsSubject.next(this.contractSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading contracts:', err);
        this.contractUpdateInProgress = false;
      },
    });
  }

  private refreshTenantSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.tenantUpdateInProgress) {
      return;
    } else {
      this.tenantUpdateInProgress = true;
    }
    // reset
    this.tenantSelectItems = [];
    // Betöltjük a bérlőket a dropdownhoz
    this.tenantService.getAllItems().subscribe({
      // a service egyben adja vissza a bérlőket
      next: (tenants: ITenantModel[]) => {
        // a bérlőket elmentjük a select itemekbe
        this.tenantSelectItems = tenants.map((tenant: ITenantModel) => ({
          val: tenant['@id'],
          title: this.formatter.getFormattedTenant(tenant),
        }));
        this.tenantSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: Tenants', this.tenantSelectItems);
        this.tenantUpdateInProgress = false;
        this.tenantSelectItemsSubject.next(this.tenantSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading tenants:', err);
        this.tenantUpdateInProgress = false;
      },
    });
  }

  private refreshMeterTypeSelectItems() {
    // Ha már fut a frissítés, akkor nem futtatjuk újra
    if (this.meterTypeUpdateInProgress) {
      return;
    } else {
      this.meterTypeUpdateInProgress = true;
    }
    // reset
    this.meterTypeSelectItems = [];
    // Betöltjük a mérőtípusokat a dropdownhoz
    this.meterTypeService.getAllItems().subscribe({
      // a service egyben adja vissza a mérőtípusokat
      next: (meterTypes: IMeterTypeModel[]) => {
        // a mérőtípusokat elmentjük a select itemekbe
        this.meterTypeSelectItems = meterTypes.map(
          (meterType: IMeterTypeModel) => ({
            val: meterType['@id'],
            title: meterType.name,
          })
        );
        this.meterTypeSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('SILO completed: MeterTypes', this.meterTypeSelectItems);
        this.meterTypeUpdateInProgress = false;
        this.meterSelectItemsSubject.next(this.meterTypeSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading meter types:', err);
        this.meterTypeUpdateInProgress = false;
      },
    });
  }

  getUserSelectItems(): Observable<IUiSelectItem[]> {
    if (this.userSelectItems.length === 0) {
      this.refreshUserSelectItems();
    }
    return this.userSelectItemsSubject.asObservable();
  }

  getDelegateUserSelectItems(): Observable<IUiSelectItem[]> {
    if (this.delegateUserSelectItems.length === 0) {
      this.refreshUserSelectItems();
    }
    return this.delegateUserSelectItemsSubject.asObservable();
  }

  getFlatSelectItems(): Observable<IUiSelectItem[]> {
    if (this.flatSelectItems.length === 0) {
      this.refreshFlatSelectItems();
    }
    return this.flatSelectItemsSubject.asObservable();
  }

  getBuildingSelectItems(): Observable<IUiSelectItem[]> {
    if (this.buildingSelectItems.length === 0) {
      this.refreshBuildingSelectItems();
    }
    return this.buildingSelectItemsSubject.asObservable();
  }

  getParkingSPaceSelectItems(): Observable<IUiSelectItem[]> {
    if (this.parkingSpaceSelectItems.length === 0) {
      this.refreshParkingSpaceSelectItems();
    }
    return this.parkingSpaceSelectItemsSubject.asObservable();
  }

  getBookingSelectItems(): Observable<IUiSelectItem[]> {
    if (this.bookingSelectItems.length === 0) {
      this.refreshBookingSelectItems();
    }
    return this.bookingSelectItemsSubject.asObservable();
  }

  getInvoiceSelectItems(): Observable<IUiSelectItem[]> {
    if (this.invoiceSelectItems.length === 0) {
      this.refreshInvoiceSelectItems();
    }
    return this.invoiceSelectItemsSubject.asObservable();
  }

  getCurrencySelectItems(): Observable<IUiSelectItem[]> {
    if (this.currencySelectItems.length === 0) {
      this.refreshCurrencySelectItems();
    }
    return this.currencySelectItemsSubject.asObservable();
  }

  getBillingScheduleSelectItems(): Observable<IUiSelectItem[]> {
    if (this.billingScheduleSelectItems.length === 0) {
      this.refreshBillingScheduleSelectItems();
    }
    return this.billingScheduleSelectItemsSubject.asObservable();
  }

  getBillingItemTypeSelectItems(): Observable<IUiSelectItem[]> {
    if (this.billingItemTypeSelectItems.length === 0) {
      this.refreshBillingItemTypeSelectItems();
    }
    return this.billingItemTypeSelectItemsSubject.asObservable();
  }

  getUnitSelectItems(): Observable<IUiSelectItem[]> {
    if (this.unitSelectItems.length === 0) {
      this.refreshUnitSelectItems();
    }
    return this.unitSelectItemsSubject.asObservable();
  }

  getTaxSelectItems(): Observable<IUiSelectItem[]> {
    if (this.taxSelectItems.length === 0) {
      this.refreshTaxSelectItems();
    }
    return this.taxSelectItemsSubject.asObservable();
  }

  getContractSelectItems(): Observable<IUiSelectItem[]> {
    if (this.contractSelectItems.length === 0) {
      this.refreshContractSelectItems();
    }
    return this.contractSelectItemsSubject.asObservable();
  }

  getContractSelectItemsForFlat(flat: IFlatModel): Observable<IUiSelectItem[]> {
    const contractSelectItemsSubject = new BehaviorSubject<IUiSelectItem[]>([]);
    let contractSelectItems = [];
    if (flat.contracts) {
      // át kell alakítani a szerződéseket select itemekké
      contractSelectItems = flat.contracts.map(
        (contract: IContractModel) =>
          ({
            val: contract['@id'],
            title: this.formatter.getFormattedContract(contract),
          } as IUiSelectItem)
      );
      contractSelectItems.sort((a, b) =>
        a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
      );
      contractSelectItemsSubject.next(contractSelectItems);
      this.log.debug(
        'SILO completed: Contracts for Flat ' + flat.id,
        contractSelectItems
      );
    }
    return contractSelectItemsSubject.asObservable();
  }

  getGenderSelectItems(): IUiSelectItem[] {
    return this.genderSelectItems;
  }

  getSelectItemForCurrentUser(): IUiSelectItem {
    // azt a felhasználót adjuk vissza, akinek a title-je tartalmazza a jelenlegi felhasználó email címét authService.getUserName()
    return (
      this.userSelectItems.find((user) =>
        user.title?.includes(this.authService.getUserName())
      ) ??
      // már ha köztük van persze
      ({
        val: '',
        title: '',
      } as IUiSelectItem)
    );
  }

  getTenantSelectItems(): Observable<IUiSelectItem[]> {
    if (this.tenantSelectItems.length === 0) {
      this.refreshTenantSelectItems();
    }
    return this.tenantSelectItemsSubject.asObservable();
  }

  getMeterTypeSelectItems(): Observable<IUiSelectItem[]> {
    if (this.meterTypeSelectItems.length === 0) {
      this.refreshMeterTypeSelectItems();
    }
    return this.meterSelectItemsSubject.asObservable();
  }

  invalidateTenantsAndUsers() {
    this.log.debug('SILO invalidated: Tenants and Users');
    this.refreshTenantSelectItems();
    this.refreshUserSelectItems();
  }
}
