<div
  id="hs-cleaning-schedule-note-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">Edit Notes</h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-cleaning-schedule-note-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="cleaning-schedule-note-form"
            [formGroup]="cleaningScheduleNoteForm"
            (ngSubmit)="saveCleaningScheduleNote()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Building Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleBuilding"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Building
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <ng-select
                      class="w-full max-w-lg inline-block mb-4"
                      selectId="cleaningScheduleBuilding"
                      [items]="buildingSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="building"
                    />
                  </div>
                </div>
                <!-- End Building Grid -->
                <!-- Flat Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleFlat"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Flat
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <ng-select
                      selectId="cleaningScheduleFlat"
                      [items]="flatSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="flat"
                    ></ng-select>
                  </div>
                </div>
                <!-- End Flat Grid -->

                <!-- Cleaning Date Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleCleaningDate"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Cleaning Date
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    <input
                      id="cleaningScheduleCleaningDate"
                      type="date"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="cleaningSchedule.cleaning_date"
                      formControlName="cleaning_date"
                    />
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Cleaning date Grid -->
                <!-- Cleaning Type Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleType"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Cleaning Type
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <ng-select
                      selectId="cleaningScheduleType"
                      [items]="typeSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="cleaningType"
                    ></ng-select>
                  </div>
                </div>
                <!-- End Cleaning Type Grid -->
                <!-- Done Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningScheduleDone"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Done
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    <ui-switch
                      switchId="cleaningScheduleDone"
                      [checked]="!!cleaningSchedule.cleaning_done"
                      formControlName="cleaning_done"
                    ></ui-switch>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Done Grid -->
                <!-- Cleaner Note Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-12 pb-2">
                    <label
                      for="cleaner_note"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Note by staff ({{
                        cleaningSchedule.updatedByUser?.name ?? "N/A"
                      }})
                    </label>
                  </div>
                  <div
                    class="sm:col-span-10 border-gray-400 border bg-gray-50 rounded-2xl rounded-tl-none p-2"
                  >
                    <textarea
                      id="cleaner_note"
                      type="text"
                      class="py-2 px-3 block w-full border-none bg-gray-50 text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      rows="4"
                      [value]="cleaningSchedule.cleaner_note ?? ''"
                      formControlName="cleaner_note"
                    ></textarea>
                  </div>
                </div>
                <!-- End Cleaner Note Grid -->
                <!-- Cleaner Note Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-12 text-right pb-2">
                    <label
                      for="cleaning_note"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Note by management ({{
                        cleaningSchedule.updatedByUser?.name ?? "N/A"
                      }})
                    </label>
                  </div>
                  <div
                    class="col-start-3 col-end-13 border-gray-400 border bg-gray-50 rounded-2xl rounded-tr-none p-2"
                  >
                    <textarea
                      id="cleaning_note"
                      type="text"
                      class="py-2 px-3 block w-full border-none bg-gray-50 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="cleaningSchedule.cleaning_note ?? ''"
                      formControlName="cleaning_note"
                    ></textarea>
                  </div>
                </div>
                <!-- End Cleaner Note Grid -->
                <!-- Photos Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5 hidden"
                >
                  <div class="sm:col-span-4">
                    <div class="sm:mt-2.5 inline-block text-sm text-gray-500">
                      Photos
                    </div>
                  </div>
                  <div class="sm:col-span-8">
                    <div class="mb-2">
                      <img src="https://picsum.photos/300/200?random=2" />
                    </div>
                    <div class="mb-2">
                      <img src="https://picsum.photos/300/200?random=3" />
                    </div>
                    <div class="text-center">
                      <ui-button>Add image</ui-button>
                    </div>
                  </div>
                </div>

                <!-- End Photos Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-cleaning-schedule-note-editor-modal"
        >
          Close
        </button>
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="cleaningScheduleNoteForm.invalid"
          form="cleaning-schedule-note-form"
          data-hs-overlay="#hs-cleaning-schedule-note-editor-modal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
