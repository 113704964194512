<div class="">
  <div class="p-2">
    <form
      id="booking-payment-info"
      [formGroup]="bookingPaymentForm"
      
    >
      <!--  form switch  -->
      <div class="mb-4">
        <label
          for="booking-payment-currency-type-select"
          class="block mb-2 font-bold"
          >Paid</label
        >
        <ui-switch
          [checked]="!!booking?.paid"
          formControlName="paid"
        ></ui-switch>
      </div>
      <!--  // form switch -->

      <!--  form select  -->
      <div class="mb-4">
        <label
          class="block text-sm mb-2 font-bold"
          for="booking-payment-currency-type-select"
          >Currency</label
        >
        <ng-select
          class="w-full max-w-lg inline-block"
          labelForId="booking-payment-currency-type-select"
          [items]="currencySelectItems"
          bindValue="val"
          bindLabel="title"
          formControlName="currency"
          placeholder="Select currency"
          [loading]="isLoadingCurrencies"
          [clearable]="false"
        />
      </div>
      <!--  // form select  -->

      <!--  form number  -->
      <div class="mb-4">
        <label for="booking-payment-booking-price" class="block mb-2 font-bold"
          >Booking Price</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="number"
          placeholder=""
          name="booking-payment-booking-price"
          value="{{ booking?.booking_price }}"
          formControlName="booking_price"
        />
      </div>
      <!--  // form number  -->

      <!--  form number  -->
      <div class="mb-4">
        <label
          for="booking-payment-booking-price-default-currency"
          class="block mb-2 font-bold"
          >Booking Price in Default Currency</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
          type="number"
          placeholder=""
          name="booking-payment-booking-price-default-currency"
          value="{{ booking?.booking_price_in_default_currency }}"
          formControlName="booking_price_in_default_currency"
        />
      </div>
      <!--  // form number  -->

      <!--  form number  -->
      <div class="mb-4">
        <label
          for="booking-payment-booking-price-exchange-rate"
          class="block mb-2 font-bold"
          >Booking Price Exchange Rate</label
        >
        <input
          class="border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg mb-4"
          type="number"
          placeholder=""
          name="booking-payment-booking-price-exchange-rate"
          value="{{ booking?.booking_price_exchange_rate }}"
          formControlName="booking_price_exchange_rate"
        />
      </div>
      <!--  // form number  -->

      <div class="w-full max-w-lg text-right">
        <ui-button 
          (click)="saveBookingPaymentInfo()" 
          iconSrc="assets/img/save.svg" 
          iconClass="invert sature-0 brightness-0 contrast-125" 
          size="xsmall"
          [disabled]="bookingPaymentForm.invalid"  
          class="inline-block">
            Save
        </ui-button>
      </div>
    </form>
  </div>
</div>
