<tr>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm  cursor-pointer align-middle"
  >
    @if(pitem.booking){ @if(pitem.booking.id){<a
      [routerLink]="['/bookings', pitem.booking.id]"
    >
      {{ pitem.booking.name }}<br />{{
        "(" + pitem.booking.start_date + " - " + pitem.booking.end_date + ")"
      }}</a
    >
    }@else {
    {{ pitem.booking.name }}<br />{{
      "(" + pitem.booking.start_date + " - " + pitem.booking.end_date + ")"
    }}
    } }
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.name }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.email_address }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.country_code }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.city }}
  </td>
  <td class="px-4 py-3 leading-4 align-middle text-center">
    <ui-switch
      [checked]="!!pitem.newsletter_signup"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-end text-xs font-medium align-middle"
  >
  <div class="inline-flex rounded-lg shadow-sm w-max">
    <ui-button
      btnType="inline"
      (click)="handleView(pitem)"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      data-hs-overlay="#hs-checkin-editor-modal"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
    <ui-button
      btnType="inline"
      (click)="handleEdit(pitem)"
      
      iconSrc="assets/img/pencil.svg"
      data-hs-overlay="#hs-checkin-editor-modal"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>

      <ui-button
      btnType="inline"
      (click)="handleDelete(pitem)"
      inlinePos="right"
      
      iconSrc="assets/img/trash-2.svg"
      data-hs-overlay="#hs-delete-confirmation-modal"
      > <span class="shrink-0 text-xs"> Delete </span>
    </ui-button>
    </div>
  </td>
</tr>
