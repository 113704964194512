import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { CheckinClientService } from '../../../services/api/checkin/checkin-client.service';
import { ICheckinModel } from '../../../services/api/checkin/checkin-model.interface';
import { countries } from '../../atomic-ui-components/select/country-data';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';


@Component({
  selector: 'checkin-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiSwitchComponent,
    NgSelectModule,
    UiButtonComponent,
  ],
  templateUrl: './checkin-editor.component.html',
  styleUrl: './checkin-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinEditorComponent implements OnChanges {
  @Input() checkin!: ICheckinModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  countries = countries;

  checkinForm: FormGroup = this.fb.group({
    name: [''],
    email_address: ['', Validators.email],
    country_code: [''],
    city: [''],
    county: [''],
    street_line_1: [''],
    street_line_2: [''],
    po_code: [''],
    billing_name: [''],
    billing_company_name: [''],
    billing_tax_number: [''],
    billing_country: [''],
    billing_county: [''],
    billing_po_code: [''],
    billing_town: [''],
    billing_street_line_1: [''],
    billing_street_line_2: [''],
    newsletter_signup: [{ value: false }],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private checkinService: CheckinClientService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges() {
    this.checkinForm.reset();
    if (this.mode === 'view') {
      this.checkinForm.disable();
    } else {
      this.checkinForm.enable();
    }
    this.checkinForm.patchValue(this.checkin);
  }

  saveCheckin() {
    this.log.info('Saving checkin');
    if (this.checkinForm.valid && this.mode !== 'view') {
      const updatedUntypedCheckin: { [key: string]: any } = {};

      // Csak a megváltozott mezőket küldjük el
      Object.keys(this.checkinForm.controls).forEach((key) => {
        const control = this.checkinForm.get(key);
        if (control?.dirty) {
          updatedUntypedCheckin[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedCheckin).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedCheckin = updatedUntypedCheckin as ICheckinModel;
      this.log.debug('Saving Checkin:', updatedCheckin);
      if (this.mode === 'edit') {
        updatedCheckin.id = this.checkin.id;
        this.checkinService.updateCheckin(updatedCheckin).subscribe({
          next: (data) => {
            this.log.debug('Checkin updated: ', data);
            this.toastr.success('Checkin updated');
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error while updating checkin');
            this.log.error('Error updating checkin:', err);
          },
        });
      } else {
        this.checkinService.createCheckin(updatedCheckin).subscribe({
          next: (data) => {
            this.toastr.success('Checkin created');
            this.log.debug('Checkin created', data);
            this.onCreate.emit();
          },
          error: (err) => {
            this.toastr.error('Error while creating checkin');
            this.log.error('Error creating checkin:', err);
          },
        });
      }
    }
  }
}
