<tr>

  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 text-center"
  >
    <ui-badge
      [color]="
        pitem.bookingStatus?.id == 1
          ? 'black'
          : pitem.bookingStatus?.id == 2
          ? 'yellow'
          : pitem.bookingStatus?.id == 3
          ? 'teal'
          : pitem.bookingStatus?.id == 4
          ? 'blue'
          : pitem.bookingStatus?.id == 5
          ? 'green'
          : 'red'
      "
      [badgeType]="pitem.bookingStatus?.id == 6 ? 'solid' : 'soft'"
      >{{ pitem.bookingStatus?.name }}</ui-badge
    >
  </td>
  <td class="px-4 py-3 ps-4 leading-4 text-sm">
    <ui-switch
      [checked]="!!pitem.checked_in"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-800 leading-4">
    {{ pitem.start_date }}
  </td>
  <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-800 leading-4">
    {{ pitem.end_date }}
  </td>
  <td
    class="px-4 py-3 whitespace-nowrap text-sm text-gray-800 text-right leading-4"
  >
    {{ pitem.adult_occupants }}
  </td>
  <td
    class="px-4 py-3 whitespace-nowrap text-sm text-gray-600 font-mono leading-4"
  >
    <span class="inline-block bg-gray-100 rounded p-1">{{
      pitem.foreign_reservation_code
    }}</span>
  </td>
  <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-800 leading-4">
    {{ pitem.name }}
  </td>
  <td
    class="px-4 py-3 whitespace-nowrap text-xs text-gray-800 text-right leading-4"
  >
    @for (flat of pitem.flats; track flat.id) {
    <ui-badge
      [color]="
        flat.building?.name === 'Lehel'
          ? 'blue'
          : flat.building?.name === 'Kunigunda'
          ? 'yellow'
          : 'black'
      "
      badgeType="soft"
      cornerType="rounded"
      class="inline-block mr-1"
      ><span class="text-xs font-normal">{{ flat | flatFormat}}</span></ui-badge>
    }
  </td>
  <td class="px-4 py-3 ps-4 leading-4 text-xs">
    <ui-switch
      [checked]="!!pitem.paid"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 whitespace-nowrap text-sm  align-middle font-bold cursor-pointer leading-4"
  >
    {{ pitem.user?.name
    }}<span class="block font-normal">{{
      pitem.user?.email
    }}</span>
  </td>

  <td
    class="px-4 py-3 whitespace-nowrap text-end text-sm font-medium leading-4"
  >
    <div class="inline-flex rounded-lg shadow-sm w-max">
      <ui-button
      btnType="inline"
      inlinePos="left"
      routerLink="/bookings/{{ pitem.id }}"
      iconSrc="assets/img/eye.svg"

      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
      <ui-button
      btnType="inline"
      inlinePos="center"
      routerLink="/bookings/{{ pitem.id }}/edit"
      iconSrc="assets/img/pencil.svg"

      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>

      <ui-button
      btnType="inline"
      (click)="handleDelete(pitem)"
      inlinePos="right"

      iconSrc="assets/img/trash-2.svg"
      data-hs-overlay="#hs-delete-confirmation-modal"
      > <span class="shrink-0 text-xs"> Delete </span>
    </ui-button>
    </div>
  </td>
</tr>
