import { Injectable } from '@angular/core';
import { GenericApiClientService } from '../generic-api-client.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import {
  API_CALL_RETRY_COUNT,
  API_ENDPOINTS,
  ApiService,
} from '../api.service';
import { IUserRentsFlatModel } from './user-rents-flat-model.interface';
import { UserRentsFlatTransformService } from './user-rents-flat-transform.service';
import { catchError, map, Observable, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRentsFlatClientService extends GenericApiClientService<IUserRentsFlatModel> {
  constructor(
    http: HttpClient,
    apiService: ApiService,
    log: LogService,
    transformer: UserRentsFlatTransformService
  ) {
    super(http, apiService, log, transformer);
    this.itemsUrl = apiService.getUrlFor(API_ENDPOINTS.userRentsFlats);
    this.singleItemUrl = apiService.getUrlFor(API_ENDPOINTS.userRentsFlat);
  }

  getItemsForFlat(flatId: number): Observable<IUserRentsFlatModel[]> {
    const url =
      this.apiService.getUrlFor(API_ENDPOINTS.flat) +
      flatId +
      '/user_rents_flats';
    return this.http.get<IUserRentsFlatModel[]>(url).pipe(
      retry(API_CALL_RETRY_COUNT),
      catchError(this.handleError),
      map((response: any) => {
        return response['hydra:member'];
      })
    );
  }
}
