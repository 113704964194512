<div
  id="hs-contract-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-2xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Contract"
              : "Edit  Contract"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-contract-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto mt-4 p-4 m-auto">
        <div class="space-y-4">
          <form
            id="contract-form"
            [formGroup]="contractForm"
            (ngSubmit)="saveContract()"
          >
            <!-- Contract Number -->
            <label class="block mb-2 font-bold" for="contract_number"
              >Contract Number</label
            >
            <input
              class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
              type="text"
              placeholder="Unique contract number. Like CNTRCT-K01"
              formControlName="contract_number"
            />
            <!-- End Contract Number -->

            <!-- Tenants -->
            <label class="block mb-2 font-bold" for="floor">Tenants</label>
            <ng-select
              class="mb-4 max-w-lg"
              [items]="tenantSelectItems"
              bindLabel="title"
              bindValue="val"
              [multiple]="true"
              loadingText="Loading..."
              placeholder="Select Tenants"
              formControlName="tenants"
              [virtualScroll]="true"
            ></ng-select>
            <!-- End Tenants -->

            <!-- Contract Date -->
            <label class="block mb-2 font-bold" for="contract_date"
              >Contract Date</label
            >
            <input
              class="mb-4 border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
              type="date"
              formControlName="contract_date"
            />
            <!-- End Contract Date -->

            <!-- Tenancy Start Date -->
            <label class="block mb-2 font-bold" for="tenancy_starts_at"
              >Tenancy Start Date</label
            >
            <input
              class="mb-4 border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
              type="date"
              formControlName="tenancy_starts_at"
            />
            <!-- End Tenancy Start Date -->

            <!--  Open Ended  -->
            <div class="flex gap-4 mb-2 items-center">
              <label class="block font-bold" for="name">Open Ended</label>
              <ui-switch formControlName="open_ended" size="small"></ui-switch>
            </div>
            <!--  End Open Ended  -->

            <!-- Tenancy End Date -->
            <label
              class="block mb-2 mt-4 font-bold"
              for="tenancy_ends_at"
              [ngClass]="{ 'text-gray-400': isOpenEnded }"
              >Tenancy End Date</label
            >
            <input
              class="mb-4 border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
              type="date"
              [ngClass]="{ 'text-gray-400': isOpenEnded }"
              formControlName="tenancy_ends_at"
            />
            <!-- End Tenancy End Date -->
          </form>
          <!--*************************************-->
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-contract-editor-modal"
        >
          Close
        </button>

        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="contractForm.invalid"
          form="contract-form"
          data-hs-overlay="#hs-contract-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
      </div>
    </div>
  </div>
</div>
