import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { IPaymentTransactionModel } from '../../../services/api/payment-transaction/payment-transaction-model.interface';
import { PaymentTransactionClientService } from '../../../services/api/payment-transaction/payment-transaction-client.service';
import { LogService } from '../../../services/utils/log.service';
import { CurrencyValueCorrectorPipe } from '../../../services/utils/currency-value-corrector.pipe';
import { CurrencyClientService } from '../../../services/api/currency/currency-client.service';
import { PaymentProviderClientService } from '../../../services/api/payment-provider/payment-provider-client.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'payment-transaction-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiSwitchComponent,
    CurrencyValueCorrectorPipe,
    NgSelectModule,
  ],
  templateUrl: './payment-transaction-editor.component.html',
  styleUrl: './payment-transaction-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentTransactionEditorComponent {
  @Input() paymentTransaction!: IPaymentTransactionModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  currencySelectItems: IUiSelectItem[] = [];
  paymentProviderSelectItems: IUiSelectItem[] = [];
  userSelectItems: IUiSelectItem[] = [];
  invoiceSelectItems: IUiSelectItem[] = [];
  bookingSelectItems: IUiSelectItem[] = [];

  paymentTransactionForm: FormGroup = this.fb.group({
    price: [''],
    payer_name: [''],
    payer_email: [''],
    paid: [false],
    payment_status: [''],
    response_string: [''],
    response_code: [''],
    transaction_code: [''],
    currency: [''],
    paymentProvider: [''],
    payee_user: [''],
    payer_user: [''],
    invoice: [''],
    booking: [''],
    cleaningRequest: [''],
    parkingRequest: [''],
    created_at: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private paymentTransactionService: PaymentTransactionClientService,
    private currencyService: CurrencyClientService,
    private paymentProviderService: PaymentProviderClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges() {
    this.log.debug('PaymentTransactionEditor Changed', this.paymentTransaction);
    this.paymentTransactionForm.reset();
    if (this.mode === 'view') {
      this.paymentTransactionForm.disable();
    } else {
      this.paymentTransactionForm.enable();
    }
    this.paymentTransactionForm.patchValue(this.paymentTransaction);
    //TODO fixelni a selectet ha edit mód, dirty, nem ment, majd új create van
    this.paymentTransactionForm
      .get('currency')
      ?.setValue(this.paymentTransaction.currency?.['@id']);
    this.paymentTransactionForm
      .get('paymentProvider')
      ?.setValue(this.paymentTransaction.paymentProvider?.['@id']);
    this.paymentTransactionForm
      .get('payee_user')
      ?.setValue(this.paymentTransaction.payee_user?.['@id']);
    this.paymentTransactionForm
      .get('payer_user')
      ?.setValue(this.paymentTransaction.payer_user?.['@id']);
    this.paymentTransactionForm
      .get('invoice')
      ?.setValue(this.paymentTransaction.invoice?.['@id']);
    this.paymentTransactionForm
      .get('booking')
      ?.setValue(this.paymentTransaction.booking?.['@id']);
  }

  ngOnInit() {
    // TODO ezek betötése lehet eltart egy ideig, kezelni kell a töltést majd

    // TODO: SILO-ba szervezni
    this.currencyService.getCurrencies().subscribe({
      next: (currencies) => {
        this.currencySelectItems = currencies.map((currency) => ({
          val: currency['@id'],
          title: (currency.name ?? currency.code) + ' ' + currency.symbol,
        }));
        this.log.debug('Currenciesloaded:', this.currencySelectItems);
      },
      error: (err) => {
        this.log.error('Error loading currencies:', err);
      },
    });

    // TODO: SILO-ba szervezni
    this.paymentProviderService.getPaymentProviders().subscribe({
      next: (paymentProviders) => {
        this.paymentProviderSelectItems = paymentProviders.map(
          (paymentProvider) => ({
            val: paymentProvider['@id'],
            title: paymentProvider.name,
          })
        );
        this.log.debug(
          'Payment providers loaded:',
          this.paymentProviderSelectItems
        );
      },
      error: (err) => {
        this.log.error('Error loading payment providers:', err);
      },
    });

    // Betöltjük a felhasználókat a dropdownhoz
    this.silo.getUserSelectItems().subscribe({
      next: (users) => {
        this.userSelectItems = users;
        this.log.debug('Users loaded into select:', this.userSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading users into select:', err);
      },
    });

    // Betöltjük a számlákat a dropdownhoz
    this.silo.getInvoiceSelectItems().subscribe({
      next: (invoices) => {
        this.invoiceSelectItems = invoices;
        this.log.debug('Invoices loaded into select:', this.invoiceSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading invoices into select:', err);
      },
    });

    // Betöltjük a foglalásokat a dropdownhoz
    this.silo.getBookingSelectItems().subscribe({
      next: (bookings) => {
        this.bookingSelectItems = bookings;
        this.log.debug('Bookings loaded into select:', this.bookingSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading bookings into select:', err);
      },
    });
  }

  savePaymentTransaction() {
    this.log.info('Saving payment transaction');
    if (this.paymentTransactionForm.valid && this.mode !== 'view') {
      const updatedUntypedPaymentTransaction: { [key: string]: any } = {};

      Object.keys(this.paymentTransactionForm.controls).forEach((key) => {
        const control = this.paymentTransactionForm.get(key);
        if (control?.dirty) {
          updatedUntypedPaymentTransaction[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedPaymentTransaction).length === 0) {
        // Nincs módosítás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedPaymentTransaction =
        updatedUntypedPaymentTransaction as IPaymentTransactionModel;

      if (this.mode === 'edit') {
        updatedPaymentTransaction.id = this.paymentTransaction.id;
        this.paymentTransactionService
          .updatePaymentTransaction(updatedPaymentTransaction)
          .subscribe({
            next: (data) => {
              this.toastr.success('Payment transaction updated');
              this.log.debug('Payment transaction updated: ', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating payment transaction');
              this.log.error('Error updating payment transaction', err);
            },
          });
      } else {
        this.paymentTransactionService
          .createPaymentTransaction(updatedPaymentTransaction)
          .subscribe({
            next: (data) => {
              this.toastr.success('Payment transaction created');
              this.log.debug('Payment transaction created: ', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating payment transaction');
              this.log.error('Error creating payment transaction', err);
            },
          });
      }
    }
  }
}
