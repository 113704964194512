import { Component, ViewChild } from '@angular/core';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { UsersTableRowComponent } from './users-table-row/users-table-row.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { UserClientService } from '../../../services/api/user/user-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { UsersCreatorComponent } from '../../modal-editors/users-creator/users-creator.component';
import { ParkingRequestEditorComponent } from '../../modal-editors/parking-request-editor/parking-request-editor.component';
import { IUserModel } from '../../../services/api/user/user-model.interface';


@Component({
  selector: 'users-table',
  standalone: true,
  imports: [
    UsersTableRowComponent,
    UiButtonComponent,
    ReactiveFormsModule,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
    UsersCreatorComponent
],
  templateUrl: './users-table.component.html',
  styleUrl: './users-table.component.css',
})
export class UsersTableComponent {
  @ViewChild(UsersCreatorComponent)
  editor!: ParkingRequestEditorComponent;
  selectedUser!: IUserModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  users: IUserModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private userClientService: UserClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleEdit(pitem: IUserModel) {
    this.selectedUser = pitem;
    this.editorMode = 'edit';
  }

  handleView(pitem: IUserModel) {
    this.selectedUser = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IUserModel) {
    this.selectedUser = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.userClientService.deleteUser(this.selectedUser.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.log.info('User deleted');
        this.users = this.users.filter(
          (user) => user.id !== this.selectedUser.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.users.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('User deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting User');
        this.log.error('Error deleting User', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedUser = {
      '@id': '',
      id: 0,
      email: '',
      name: '',
      szamla_agent_api_key: '',
      last_login: '',
    } as IUserModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.userClientService.getUsers(page).subscribe({
      next: (users) => {
        this.log.debug('Users fetched', users);
        this.users = users;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);

        // ez akkor kell, ha használunk Preline JS dolgokat (pl. modalok)
        setTimeout(() => {
          this.log.info('Users table initialized');
          window.HSStaticMethods.autoInit('overlay');
        }, 100);
      },
      error: (err) => {
        this.log.error('Error fetching users', err);
        this.isLoading = false;
      },
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.userClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
