<layout-main id="content" role="main">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 mt-4 sm:mt-0 mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-blue-600 leading-5"
      >
        Long-term leasing
      </span>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      Overview
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!-- Dashboard content here -->
  <div>
    <!-- GRID DASHBOARD -->
    <div class="">
      <!-- GRID  -->
      <div class="text-gray-800">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
        >
          <!-- CARD OPEN ISSUES 1-->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200">
                    <lucide-angular name="clipboard-check"></lucide-angular>
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >Open Issues</span
                    >
                  </div>
                  @if(isLoadingOpenIssues){
                  <div class="flex space-x-2 items-center animate-pulse">
                    <div class="w-20 h-8 bg-gray-200 rounded"></div>
                  </div>
                  } @else {
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">{{ openIssues }}</div>
                    <div
                      class="rounded-xl space-x-1 px-2 py-1 leading-4 flex items-center"
                      [ngClass]="
                        isHappyAboutOpenIssues ? 'bg-green-200' : 'bg-red-200'
                      "
                    >
                      <span
                        class="text-xs leading-4"
                        [ngClass]="
                          isHappyAboutOpenIssues ? 'green-900' : 'red-900'
                        "
                        >{{ openIssues / issues.length | percent }}</span
                      >
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>

            <hr />
            <a [routerLink]="'/issues'" class="p-2 flex justify-between">
              <span class="text-sm">View Issues</span>
              <span class="">
                <lucide-angular
                  name="chevron-right"
                  class="inline-block h-5 w-5"
                ></lucide-angular>
              </span>
            </a>
          </div>
          <!-- END CARD OPEN ISSUES 1-->
          <!-- CARD PROPERTIES 2-->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl h-28">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200">
                    <lucide-angular name="building2" class="inline-block h-6">
                    </lucide-angular>
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap leading-3"
                      >Properties</span
                    >
                  </div>
                  @if(isLoadingProperties){
                  <div class="flex space-x-2 items-center animate-pulse">
                    <!--  -->
                    <div class="w-20 h-8 bg-gray-200 rounded"></div>
                  </div>
                  } @else {
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">
                      {{ numberOfProperties }}
                    </div>
                    <div
                      class="rounded-xl space-x-1 px-2 py-1 leading-4 flex items-center"
                      [ngClass]="
                        numberOfPropertiesLastWeek < numberOfProperties
                          ? 'bg-green-200'
                          : 'bg-red-200'
                      "
                    >
                      <span
                        class="text-xs leading-4"
                        [ngClass]="
                          isHappyAboutOpenIssues ? 'green-900' : 'red-900'
                        "
                        >{{
                          numberOfProperties - numberOfPropertiesLastWeek > 0
                            ? "+"
                            : ""
                        }}{{
                          (numberOfProperties - numberOfPropertiesLastWeek) /
                            numberOfProperties | percent
                        }}</span
                      >
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>

            <hr />
            <a [routerLink]="'/properties'" class="p-2 flex justify-between">
              <span class="text-sm">View Properties</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!-- END CARD PROPERTIES 2-->

          <!-- CARD CONTRACTS ENDING SOON 3 -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl h-28">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200">
                    <lucide-angular
                      name="calendar-clock"
                      class="inline-block"
                    ></lucide-angular>
                  </div>
                </div>
                <div class="grow shrink">
                  <div
                    class="uppercase text-xs mb-0 content-center flex space-x-1"
                  >
                    <span class="overflow-hidden whitespace-nowrap leading-3"
                      >Contracts ending soon</span
                    >
                  </div>
                  @if(isLoadingContracts){
                  <div class="flex space-x-2 items-center animate-pulse">
                    <div class="w-20 h-8 bg-gray-200 rounded"></div>
                  </div>
                  } @else {
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">
                      {{ numberOfContractsEndingSoon }}
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>

            <hr />
            <a
              [routerLink]="['/properties']"
              [queryParams]="{ id: propertiesWithContractsEndingSoon }"
              class="p-2 flex justify-between"
            >
              <span class="text-sm">View Properties</span>
              <span class="">
                <lucide-angular
                  name="chevron-right"
                  class="inline-block h-5 w-5"
                ></lucide-angular>
              </span>
            </a>
          </div>
          <!--  END CARD CONTRACTS ENDING SOON 3 -->
          <!-- CARD UNLEASED PROPERTIES 4 -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl h-28">
            <div class="p-2 md:p-2">
              <div class="flex">
                <div class="basis-13 grow-0 shrink-0 mr-3">
                  <div class="p-2 rounded-lg bg-gray-200">
                    <lucide-angular
                      name="dollar-sign"
                      class="inline-block"
                    ></lucide-angular>
                  </div>
                </div>
                <div class="grow shrink">
                  <div class="uppercase text-xs mb-0 leading-3 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap leading-3"
                      >Unleased Properties</span
                    >
                  </div>
                  @if(isLoadingUnleasedProperties){
                  <div class="flex space-x-2 items-center animate-pulse">
                    <div class="w-20 h-8 bg-gray-200 rounded"></div>
                  </div>
                  } @else {
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">
                      {{ numberOfUnleasedProperties }}
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>

            <hr />
            <a [routerLink]="'/properties'" class="p-2 flex justify-between">
              <span class="text-sm">View Properties</span>
              <span class="">
                <lucide-angular
                  name="chevron-right"
                  class="inline-block h-5 w-5"
                ></lucide-angular>
              </span>
            </a>
          </div>
          <!--  END CARD UNLEASED PROPERTIES 4 -->
          <!-- CARD ADD REPORT -->
          <div
            class="flex bg-gray-100 shadow-sm rounded-xl cursor-pointer h-28 items-center border-white border-4"
            data-hs-overlay="#hs-editor-modal"
          >
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div
                    class="p-2 rounded-lg bg-gray-100 text-center neutral-700"
                  >
                    <lucide-angular
                      name="plus"
                      class="inline-block"
                      size="42"
                    ></lucide-angular>
                  </div>
                </div>
                <div class="col-span-3 self-center">
                  <div class="text-lg font-bold leading-3">
                    <span class="overflow-hidden whitespace-nowrap leading-3"
                      >Add new Report</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END CARD ADD REPORT -->
        </div>
      </div>
    </div>
    <!-- // GRID DASHBOARD -->
  </div>
  <div
    class="p-4 bg-white border border-gray-200 shadow-sm rounded-xl text-gray-800"
  >
    <properties-table></properties-table>
  </div>
</layout-main>
