import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-button.component.html',
  styleUrl: './ui-button.component.css',
})
export class UiButtonComponent {
  @Input() disabled: boolean = false;
  @Input() iconSrc: string = '';
  @Input() iconAlt: string = '';
  @Input() iconClass: string = '';
  @Input() iconPos: 'left' | 'center' | 'right' = 'left';
  @Input() size: 'small' | 'xsmall' | 'default' | 'large' = 'default';
  @Input() inlinePos: 'left' | 'right' | 'solo' | 'center' = 'center';
  @Input() btnType:
    | 'green'
    | 'orange'
    | 'solid'
    | 'solid-negative'
    | 'outline'
    | 'outline-negative'
    | 'tab'
    | 'ghost'
    | 'soft'
    | 'inline'
    | 'link' = 'solid';

  getButtonClasses() {
    // Az inline kivételes eset:
    if (this.btnType === 'inline') {
      let classes =
        'py-1 px-2 flex shrink-0 items-center gap-x-2 -ms-px text-xs font-semibold focus:z-10 border border-gray-200 bg-white text-gray-800 active:bg-slate-100 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none w-max';
      if (this.inlinePos === 'left') {
        classes += ' rounded-s-lg ms-0';
      } else if (this.inlinePos === 'solo') {
        classes += ' rounded-lg';
      } else if (this.inlinePos === 'right') {
        classes += ' rounded-e-lg';
      }
      return classes;
    }

    /** Alap osztályok */
    let classes =
      'inline-block flex items-center gap-x-2 rounded-lg border disabled:opacity-50 disabled:pointer-events-none w-max';

    /** Méret szerinti osztályok */
    switch (this.size) {
      case 'small':
        classes += ' py-2 px-3 text-sm ';
        break;
      case 'xsmall':
        classes += ' py-2 px-3 text-xs ';
        break;
      case 'large':
        classes += ' p-4 sm:p-5 text-sm ';
        break;
      default:
        classes += ' sm:py-3 sm:px-4 text-sm xs:py-2 xs:px-2  ';
        break;
    }

    /** Típus szerinti osztályok */
    // Ha outline, akkor szürke/piros keret, minden más esetben nincs border alapból
    if (this.btnType === 'outline') {
      classes += ' border-gray-200';
    } else if (this.btnType === 'outline-negative') {
      classes += ' border-gray-200';
    } else {
      classes += ' border-transparent';
    }

    // Ha tab, akkor nem félkövér a szöveg, minden más esetben igen
    // Tabnál az active állapotok majd félkövéresítik
    if (this.btnType === 'tab') {
      classes += ' font-normal';
    } else {
      classes += ' font-semibold';
    }

    switch (this.btnType) {
      case 'solid':
        classes +=
          ' bg-brand-button text-white hover:brightness-75 active:brightness-75';
        break;
      case 'orange':
        classes +=
          ' bg-orange-600 text-white hover:bg-orange-700 active:bg-orange-800';
        break;
      case 'green':
        classes +=
          ' bg-green-600 text-white hover:bg-green-700 active:bg-green-800';
        break;
      case 'solid-negative':
        classes += ' bg-red-500 text-white hover:bg-red-600 active:bg-red-700';
        break;
      case 'outline':
        classes +=
          '  hover:border-brand-colored-canvas-compl hover:text-brand-colored-canvas-text-hover active:bg-brand-colored-canvas-compl';
        break;
      case 'outline-negative':
        classes +=
          ' text-red-500 hover:border-red-600 hover:text-red-600 active:bg-red-100';
        break;
      case 'ghost':
        classes +=
          ' text-gray-800 hover:bg-slate-100  active:bg-slate-200';
        break;
      case 'soft':
        classes +=
          ' bg-slate-100 text-gray-800 hover:bg-brand-button-active  active:bg-slate-300';
        break;
      case 'link':
        classes += ' text-brand-colored-canvas hover:text-brand-colored-canvas active:text-purple-900';
        break;
      case 'tab':
        classes +=
          ' group hs-tab-active:font-semibold text-brand-tab-text  hs-tab-active:text-brand-tab-active-text hs-tab-active:bg-brand-tab-active  focus:outline-none whitespace-nowrap';
        break;
    }
    return classes;
  }

  getIconClasses() {
    // Az inline kivételes eset:
    if (this.btnType === 'inline') {
      return 'inline-block h-4 stroke-gray-500 leading-4';
    }

    let classes =
      'sm:mr-2 xs:mr-1 grayscale hover:grayscale-0' + ' ' + this.iconClass;

    // tab esetén kezeljük, hogy az aktív tabok ikonjai színesek legyenek és hoverre is
    if (this.btnType === 'tab') {
      classes += ' hs-tab-active:grayscale-0 group-hover:grayscale-0';
    }
    if (this.size != 'xsmall') {
      classes += ' h-6 ';
    } else {
      classes += ' h-4 ';
    }

    return classes;
  }
}
