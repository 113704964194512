import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
  IApiQueryDateRange,
  IApiQueryOrdering,
} from '../api.service';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { IBookingModel } from './booking-model.interface';
import { BookingTransformService } from './booking-transform.service';
import { format } from 'date-fns';

/**
 * A foglalások lekérdezéséhez használt query paraméterek
 * @param start_date A foglalás kezdő dátuma. Ha string, akkor az egyenlő dátumot keresi, ha IApiQueryDateRange, akkor a megadott intervallumon belülit keres
 * @param end_date A foglalás befejező dátuma. Ha string, akkor az egyenlő dátumot keresi, ha IApiQueryDateRange, akkor a megadott intervallumon belülit keres
 */
export interface IBookingQueryParams {
  startDate?: string | IApiQueryDateRange;
  endDate?: string | IApiQueryDateRange;
  bookingSourceId?: string;
  order?: IApiQueryOrdering;
}

@Injectable({
  providedIn: 'root',
})
export class BookingClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: BookingTransformService
  ) {}

  /**
   * A foglalások lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @returns A foglalások lekért oldala, nyers válaszban
   */
  private fetchBookings(
    queryParams: IBookingQueryParams | undefined | null,
    page?: number
  ) {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (queryParams) {
      params = this.apiService.appendDateParams(
        params,
        'start_date',
        queryParams.startDate
      );
      params = this.apiService.appendDateParams(
        params,
        'end_date',
        queryParams.endDate
      );
    }
    // Alapértelmezetten egyébként end_date szerint csökkenően jönnek vissza a foglalások
    if (queryParams?.order) {
      params = params.set(
        'order[' + queryParams?.order?.field + ']',
        queryParams?.order?.direction + ''
      );
    }

    let url = queryParams?.bookingSourceId
      ? this.apiService.getUrlFor(API_ENDPOINTS.bookingSource) +
        queryParams.bookingSourceId +
        '/bookings'
      : this.apiService.getUrlFor(API_ENDPOINTS.bookings);

    return this.http
      .get(url, { params })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a foglalások első oldalát, hogy megtudja az összes foglalás számát
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @returns Az összes foglalás számossága
   **/
  getTotalItems(queryParams?: IBookingQueryParams): Observable<number> {
    if (!this.totalItems || queryParams) {
      return this.fetchBookings(queryParams).pipe(
        map((response: any) => {
          if (!queryParams) {
            this.totalItems = response['hydra:totalItems'];
          }
          return response['hydra:totalItems'];
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * A foglalások lekérése úgy, hogy a dátum/idő információk helyes formátumban legyenek
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A foglalások adatai
   */
  getBookings(
    queryParams?: IBookingQueryParams | undefined | null,
    page?: number
  ): Observable<IBookingModel[]> {
    return this.getRawBookings(queryParams, page).pipe(
      map((bookings) => {
        return bookings.map((booking) =>
          this.transformer.transformBooking(booking)
        );
      })
    );
  }

  /**
   * Az összes foglalás lekérése
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @returns Az összes foglalás adatai
   */
  getAllBookings(
    queryParams?: IBookingQueryParams
  ): Observable<IBookingModel[]> {
    // megtudjuk hány booking van összesen
    return this.getTotalItems(queryParams).pipe(
      switchMap((totalItems) => {
        let pages = Math.ceil(totalItems / 30);
        let requests = [];

        if (pages === 0) {
          // nincs egy oldal sem
          return new Observable<IBookingModel[]>((observer) => {
            observer.next([]);
            observer.complete();
          });
        }

        // minden oldalra külön request
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getBookings(queryParams, i));
        }

        // az összes request egyesítése
        return forkJoin(requests).pipe(
          map((results) => {
            let bookings = [] as IBookingModel[];
            for (let result of results) {
              bookings.push(...result);
            }
            return bookings;
          })
        );
      })
    );
  }

  /**
   * A foglalások lekérése nyers, nem átalakított formában
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A foglalások adatai nyers, nem átalakított formában
   */
  private getRawBookings(
    queryParams?: IBookingQueryParams | undefined | null,
    page?: number
  ): Observable<IBookingModel[]> {
    return this.fetchBookings(queryParams, page).pipe(
      map((response: any) => {
        const bookings = response['hydra:member'];
        return bookings;
      })
    );
  }

  /**
   * Egy foglalás lekérése
   * @param id A foglalás azonosítója
   * @returns A foglalás adatai
   */
  getBooking(id: number): Observable<IBookingModel> {
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.booking) + id)
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((booking) => this.transformer.transformBooking(booking))
      );
  }

  /**
   * Egy foglalás lekérése IRI alapján
   * @param iri A foglalás IRI-ja
   * @returns A foglalás adatai
   */
  getBookingByIri(iri: string): Observable<IBookingModel> {
    return this.http.get(this.apiService.getBaseUrl() + iri).pipe(
      retry(API_CALL_RETRY_COUNT),
      catchError(this.handleError),
      map((booking) => this.transformer.transformBooking(booking))
    );
  }

  /**
   * Egy számla törlése
   * @param id A törlendő számla azonosítója
   * @returns A törlés sikeressége
   * */
  deleteBooking(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.booking) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Új foglalás létrehozása
   * @param booking A létrehozandó foglalás adatai
   * @returns A létrehozott foglalás adatai
   */
  createBooking(booking: IBookingModel): Observable<IBookingModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.bookings),
        this.transformer.serializeBooking(booking),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IBookingModel)
      );
  }

  /**
   * Egy foglalás módosítása
   * @param booking A módosítandó foglalás adatai
   * @returns A módosított foglalás adatai
   */
  updateBooking(booking: IBookingModel): Observable<IBookingModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.booking) + booking.id,
        this.transformer.serializeBooking(booking),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IBookingModel)
      );
  }

  /**
   * Egy foglalás kicsekkolása
   * @param bookingId A kicsekkolandó foglalás azonosítója
   * @returns A kicsekkolt foglalás adatai
   */
  checkoutBooking(bookingId: number): Observable<IBookingModel> {
    return this.updateBooking({
      id: bookingId,
      checkedOut: true,
      checkoutTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    } as IBookingModel);
  }

  /**
   * Egy foglalás kicsekkolásának visszavonása
   * @param bookingId A vissza-csekkolandó foglalás azonosítója
   * @returns A vissza-csekkolt foglalás adatai
   */
  unCheckoutBooking(bookingId: number): Observable<IBookingModel> {
    return this.updateBooking({
      id: bookingId,
      checkedOut: false,
      checkoutTime: '',
    } as IBookingModel);
  }

  /**
   * Triggereli a belépési kód email küldését a szerveren
   * @param bookingId A foglalás azonosítója
   * @returns A trigger sikeressége, ami != a kód elküldésének sikerességével
   */
  sendEntryCodeEmail(bookingId: number): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.booking) +
          bookingId +
          '/sendcodeemail',
        { headers }
      )
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Triggereli a belépési kód SMS küldését a szerveren
   * @param bookingId A foglalás azonosítója
   * @returns A trigger sikeressége, ami != a kód elküldésének sikerességével
   */
  sendEntryCodeSms(bookingId: number): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.booking) +
          bookingId +
          '/sendcodesms',
        { headers }
      )
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Triggereli a számla generálást a szerveren
   * @param bookingId A foglalás azonosítója
   * @returns A trigger sikeressége, ami != a számla generálás sikerességével
   */
  generateInvoice(bookingId: number): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.booking) +
          bookingId +
          '/generateinvoice',
        { headers }
      )
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * A releváns, aktívan felügyelt foglalások lekérése
   * @returns A releváns foglalások adatai
   */
  getRelevantBookings(): Observable<IBookingModel[]> {
    const today = new Date();

    // néhány használt példa query paraméterekre
    /*
    Arrivals & Checkins Overview
      const queryParams = {
        startDate: format(today, 'yyyy-MM-dd'),
      } as IBookingQueryParams;

    Departures & Checkouts Overview
      const queryParams = {
        endDate: format(today, 'yyyy-MM-dd'),
      } as IBookingQueryParams;

    Guests Overview
      const queryParams = {
        startDate: { strictly_before: format(today, 'yyyy-MM-dd') },
        endDate: { after: format(today, 'yyyy-MM-dd') },
      } as IBookingQueryParams;
    */

    const queryParams = {
      startDate: { before: format(today, 'yyyy-MM-dd') },
      endDate: { after: format(today, 'yyyy-MM-dd') },
    } as IBookingQueryParams;

    return this.getAllBookings(queryParams);
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'BookingClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform booking operation'));
  };
}
