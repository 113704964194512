import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { InvoiceClientService } from '../../../services/api/invoice/invoice-client.service';
import { IInvoiceModel } from '../../../services/api/invoice/invoice-model.interface';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CurrencyValueCorrectorPipe } from '../../../services/utils/currency-value-corrector.pipe';
import { CurrencyClientService } from '../../../services/api/currency/currency-client.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { ToastrService } from 'ngx-toastr';
import { UiBadgeComponent } from '../../atomic-ui-components/badge/ui-badge.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';

@Component({
  selector: 'invoice-editor',
  standalone: true,
  imports: [
    UiSwitchComponent,
    ReactiveFormsModule,
    DecimalPipe,
    CurrencyValueCorrectorPipe,
    CommonModule,
    UiBadgeComponent,
    NgSelectModule
  ],
  templateUrl: './invoice-editor.component.html',
  styleUrl: './invoice-editor.component.css',
})
export class InvoiceEditorComponent implements OnChanges {
  @Input() invoice!: IInvoiceModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  currencySelectItems: IUiSelectItem[] = [];
  currencySelectItems2: IUiSelectItem[] = [];

  invoiceForm: FormGroup = this.fb.group({
    invoiceItems: [''],
    currency: [''],
    net_total: [''],
    vat_total: [''],
    gross_total: [''],
    fulfillment_date: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private invoiceService: InvoiceClientService,
    private currencyService: CurrencyClientService,
    private toastr: ToastrService,
    private silo: SelectItemLoaderService,
  ) {
    // this.currencySelectItems = this.silo.getCurrencySelectItems
  }

  ngOnChanges() {
    this.log.debug('InvoiceEditor Changed', this.invoice);
    this.invoiceForm.reset();
    if (this.mode === 'view') {
      this.invoiceForm.disable();
    } else {
      this.invoiceForm.enable();
    }
    this.invoiceForm.patchValue(this.invoice);
    //TODO fixelni a currency-t ha edit mód, dirty, nem ment, majd új create van
    this.invoiceForm.get('currency')?.setValue(this.invoice.currency?.['@id']);
  }



  saveInvoice() {
    this.log.info('Saving Invoice');
    if (this.invoiceForm.valid && this.mode !== 'view') {
      const updatedUntypedInvoice: { [key: string]: any } = {};

      Object.keys(this.invoiceForm.controls).forEach((key) => {
        const control = this.invoiceForm.get(key);
        this.log.debug('Control:', key, control?.value, control?.dirty);
        if (control?.dirty) {
          updatedUntypedInvoice[key] = control?.value;
        }
      });
      if (Object.keys(updatedUntypedInvoice).length === 0) {
        // Nincs módosított adat
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedInvoice = updatedUntypedInvoice as IInvoiceModel;

      if (this.mode === 'edit') {
        updatedInvoice.id = this.invoice.id;
        this.invoiceService.updateInvoice(updatedInvoice).subscribe({
          next: (data) => {
            this.toastr.success('Invoice updated');
            this.log.debug('Invoice updated: ', data);
            //TODO fixelni, hogy néha marad a dialógus nyitva
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error while updating invoice');
            this.log.error('Error updating invoice:', err);
          },
        });
      } else {
        this.invoiceService.createInvoice(updatedInvoice).subscribe({
          next: (data) => {
            this.toastr.success('Invoice created');
            this.log.debug('Invoice created: ', data);
            this.onCreate.emit();
          },
          error: (err) => {
            this.toastr.error('Error while creating invoice');
            this.log.error('Error creating invoice:', err);
          },
        });
      }
    }
  }

  ngOnInit() {

    // Betöltjük a currency-t a dropdownhoz
    this.silo.getCurrencySelectItems().subscribe({
      next: (items) => {
        this.currencySelectItems = new Array(...items);
      },
      error: (err) => {
        this.log.error('Error loading currencies select items:', err);
      },
    });  
 

  }

}
