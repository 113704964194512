<div class="bg-white border shadow-sm sm:rounded-xl">
  <div class="flex justify-between m-4">
    <div class="mb-4">
      <h2 class="font-bold text-lg">Tax</h2>
    </div>
    <!-- Upload Button -->
    <div class="flex items-center gap-x-2">
      <ui-button
        size="xsmall"
        iconSrc="assets/img/arrow-up-tray.svg"
        data-hs-overlay="#hs-editor-modal"
        iconClass="invert  saturate-0  brightness-0 contrast-125"
        (click)="handleCreate()"
      >
        Add Tax</ui-button
      >
    </div>
  </div>
  <!-- Filter Group -->
  <div class="flex justify-end mb-4 hidden">
    <!-- Search Input -->
    <div class="relative">
      <div
        class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5"
      >
        <img
          src="assets/img/search.svg"
          alt="search"
          class="flex-shrink-0 w-4 h-4 text-gray-500"
        />
      </div>
      <input
        type="text"
        class="py-[7px] px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        placeholder="Search tax"
      />
    </div>
    <!-- End Search Input -->
    <!-- Filter Dropdown -->
    <div class="hs-dropdown relative inline-flex [--auto-close:inside] ml-4">
      <!-- Filter Button -->
      <button
        id="hs-pro-dptfd"
        type="button"
        class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50"
      >
        <img
          src="assets/img/adjustments-horizontal.svg"
          alt="Filter"
          class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
        />

        Filter
        <span
          class="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full"
        >
          5
        </span>
      </button>
      <!-- End Filter Button -->

      <!-- Dropdown -->
      <div
        class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
        aria-labelledby="hs-pro-dptfd"
      >
        <div class="p-1">
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/user-square.svg"
              alt="Name"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />

            Name
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/envelope.svg"
              alt="Email addresses"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />

            Email addresses
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/description.svg"
              alt="Description"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />
            Description
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/building-office.svg"
              alt="Company"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />
            Company
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/user-ID.svg"
              alt="User ID"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />
            User ID
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/phone.svg"
              alt="Phone numbers"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />

            Phone numbers
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/map-pin.svg"
              alt="Location"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />

            Location
          </a>
          <a
            class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#"
          >
            <img
              src="assets/img/signed-up.svg"
              alt="Signed up as"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />
            Signed up as
          </a>
        </div>
      </div>
      <!-- End Dropdown -->
    </div>
    <!-- End Filter Dropdown -->
  </div>
  <div class="overflow-x-auto sm:mx-4 sm:mb-4">
    <!-- Table -->
    <table class="min-w-full divide-y divide-gray-200 mb-2">
      <thead class="bg-gray-50">
        <tr class="border-t border-gray-200 divide-x divide-gray-200">
          <th scope="col" class="px-4 py-2 leading-4">
            <!-- Sort Dropdown -->
            <div
              class="hs-dropdown## relative inline-flex w-full cursor-pointer"
            >
              <button
                id="hs-pro-dutnms"
                type="button"
                class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
              >
                ID
                <img
                  src="assets/img/up-down.svg"
                  alt="Name"
                  class="flex-shrink-0 w-3.5 h-3.5"
                />
              </button>

              <!-- Dropdown -->
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                aria-labelledby="hs-pro-dutnms"
              >
                <div class="p-1">
                  <button
                    type="button"
                    class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                  >
                    <img
                      src="assets/img/arrow-up.svg"
                      alt="Sort ascending"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                    Sort ascending
                  </button>
                  <button
                    type="button"
                    class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                  >
                    <img
                      src="assets/img/down.svg"
                      alt="Sort descending"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                    Sort descending
                  </button>
                </div>
              </div>
              <!-- End Dropdown -->
            </div>
            <!-- End Sort Dropdown -->
          </th>

          <th scope="col" class="px-4 py-2 leading-4">
            <!-- Sort Dropdown -->
            <div
              class="hs-dropdown## relative inline-flex w-full cursor-pointer"
            >
              <button
                id="hs-pro-dutstatus"
                type="button"
                class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
              >
                Name
                <!--  <img
                    src="assets/img/up-down.svg"
                    alt="Status Information"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  /> -->
              </button>
              <!-- Dropdown -->
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                aria-labelledby="hs-pro-dutstatus"
              >
                <div class="p-1">
                  <button
                    type="button"
                    class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                  >
                    <img
                      src="assets/img/arrow-up.svg"
                      alt="Sort ascending"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                    Sort ascending
                  </button>
                  <button
                    type="button"
                    class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                  >
                    <img
                      src="assets/img/arrow-down.svg"
                      alt="Sort descending"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                    Sort descending
                  </button>
                </div>
              </div>
              <!-- End Dropdown -->
            </div>
            <!-- End Sort Dropdown -->
          </th>
          <th scope="col" class="px-4 py-2 leading-4">
            <!-- Sort Dropdown -->
            <div
              class="hs-dropdown## relative inline-flex w-full cursor-pointer"
            >
              <button
                id="hs-pro-dutstatus"
                type="button"
                class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
              >
                Percentage
                <!--  <img
                  src="assets/img/up-down.svg"
                  alt="Status Information"
                  class="flex-shrink-0 w-3.5 h-3.5"
                /> -->
              </button>
              <!-- Dropdown -->
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                aria-labelledby="hs-pro-dutstatus"
              >
                <div class="p-1">
                  <button
                    type="button"
                    class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                  >
                    <img
                      src="assets/img/arrow-up.svg"
                      alt="Sort ascending"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                    Sort ascending
                  </button>
                  <button
                    type="button"
                    class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                  >
                    <img
                      src="assets/img/arrow-down.svg"
                      alt="Sort descending"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                    Sort descending
                  </button>
                </div>
              </div>
              <!-- End Dropdown -->
            </div>
            <!-- End Sort Dropdown -->
          </th>
          <th scope="col" class="px-4 py-2 leading-4">
            <!-- Sort Dropdown -->
            <div class="hs-dropdown relative ml-auto cursor-pointer">
              <button
                id="hs-pro-dutactions"
                type="button"
                class="text-gray-500 uppercase text-xs justify-end w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
              >
                Actions
              </button>
            </div>
            <!-- End Sort Dropdown -->
          </th>
        </tr>
      </thead>
      @if(isLoading){
      <tr>
        <td colspan="9">
          <div class="w-full flex justify-center content-center mt-16 min-h-28">
            <img
              src="assets/img/loading.gif"
              alt="loading"
              class="h-8 w-auto"
            />
            <div class="ml-4">Loading...</div>
          </div>
        </td>
      </tr>

      } @else { @for (item of tax; track item.id) {

      <tax-table-row
        (onEdit)="handleEdit($event)"
        (onDelete)="prepareDelete($event)"
        [pitem]="item"
        class="divide-y divide-gray-200"
      >
      </tax-table-row>
      } @empty {
      <tr>
        <td class="text-center" colspan="3">
          <table-empty-state noDataTitle="No Tax."> </table-empty-state>
        </td>
      </tr>
      } }
    </table>
  </div>
  <div class="mx-4 mb-4">
    <table-pager
      [totalItems]="totalItems"
      [isLoading]="isPagerLoading"
      (onPageChange)="handlePagination($event)"
    ></table-pager>
  </div>
  <!-- ========== SECONDARY CONTENT ========== -->
  <!-- Tax Editor Modal -->
  <tax-editor
    [tax]="selectedTax"
    [mode]="editorMode"
    (onSave)="refresh()"
    (onCreate)="refresh()"
  ></tax-editor>
  <!-- End Tax Editor Modal -->
  <!-- Delete Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-delete-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to delete Tax {{
      selectedTax.id
    }}? This action cannot be undone."
    confirmButtonText="Yes, Delete"
    (confirmed)="performDelete()"
  ></confirmation-dialog>
  <!-- End Delete Modal -->
  <!-- ========== END SECONDARY CONTENT ========== -->
</div>
