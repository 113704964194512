import { Injectable } from '@angular/core';
import { IFlatModel } from '../api/flat/flat-model.interface';
import { IContractModel } from '../api/contract/contract-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractToolsService {
  constructor() {}

  public getMostRecentContractForFlat(flat: IFlatModel): IContractModel | null {
    if (flat.contracts && flat.contracts.length > 0) {
      const mostRecentContract = flat.contracts.reduce((prev, curr) => {
        if (!prev) {
          return curr;
        }
        const currTenancyStartsAt = curr.tenancy_starts_at ?? 0;
        const prevTenancyStartsAt = prev.tenancy_starts_at ?? 0;
        const currTenancyEndsAt = curr.tenancy_ends_at ?? 0;
        const prevTenancyEndsAt = prev.tenancy_ends_at ?? 0;

        if (
          (currTenancyStartsAt > prevTenancyStartsAt && curr.open_ended) ||
          currTenancyEndsAt > prevTenancyEndsAt
        ) {
          return curr;
        }
        return prev;
      });

      if (mostRecentContract !== undefined && mostRecentContract !== null) {
        return mostRecentContract;
      }
    }
    return null;
  }
}
