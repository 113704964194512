import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { UiBadgeComponent } from '../../components/atomic-ui-components/badge/ui-badge.component';
import { LucideAngularModule } from 'lucide-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchComponent } from '../../components/atomic-ui-components/switch/ui-switch.component';




@Component({
  selector: 'sample-page',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    UiBadgeComponent,
    LucideAngularModule,
    NgSelectModule,
    UiSwitchComponent,

  ],
  templateUrl: './sample-page.component.html',
  styleUrl: './sample-page.component.css'
})
export class SamplePageComponent {

}
