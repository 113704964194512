import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format, parse } from 'date-fns';
import { IReadingModel } from './reading-model.interface';
import { II11Transformer } from '../generic-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class ReadingTransformService implements II11Transformer<IReadingModel> {
  constructor(private log: LogService) {}

  transformItem(reading: IReadingModel): IReadingModel {
    try {
      if (reading.read_at !== undefined) {
        reading.read_at = format(
          parse(reading.read_at, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('ReadingTransformService: read_at ', error);
    }
    // TODO: the rest of the fields
    return reading;
  }

  serializeItem(reading: IReadingModel): IReadingModel {
    const serializedReading: any = { ...reading };

    // Dátum mezők átalakítása ISO stringgé
    if (serializedReading.read_at !== undefined) {
      try {
        serializedReading.readAt = format(
          parse(serializedReading.read_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
        serializedReading.read_at = serializedReading.readAt;
      } catch (error) {
        try {
          serializedReading.readAt = format(
            parse(serializedReading.read_at, "yyyy-MM-dd'T'HH:mm", new Date()),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          );
          serializedReading.read_at = serializedReading.readAt;
        } catch (error) {
          this.log.error('ReadingTransformService: read_at ', error);
        }
      }
    }
    // Többi mező stringgé alakítása
    if (serializedReading.meter) {
      serializedReading.meter = serializedReading.meter['@id'];
    }
    if (serializedReading.reading) {
      serializedReading.reading = serializedReading.reading.toString();
    }

    return serializedReading;
  }
}
