<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span class="flex items-center text-sm cursor-default leading-5">
        Long-term leasing
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        routerLink="/properties"
      >
        Properties
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      @if(flat) {
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        [routerLink]="'/properties/' + flat.id"
      >
        {{ propertyTitle }}
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >} @else {
      <span class="flex items-center text-sm leading-5">Properties</span>
      }
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      Invoices
    </li>
  </ol>
  <!-- BREADCRUMB -->
  @if(isLoading){
  <div
    class="bg-white border shadow-sm rounded-xl p-16 mb-4 align-middle text-center"
  >
    <div
      class="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-sm text-gray-500 mt-4">Loading property details...</p>
  </div>
  } @else{
  <!-- TOP INFO  -->
  <property-and-contract-box
    [flat]="flat"
    (contractChanged)="handleContractChange($event)"
  ></property-and-contract-box>
  <!-- TOP INFO  -->

  <!-- Issues Table -->
  @if(flat){

  <div class="my-4 p-4 bg-white border shadow-sm sm:rounded-xl">
    <div class="mb-4">
      <h2 class="font-bold text-lg">Issued Invoices</h2>
    </div>
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200 round-lg mb-2">
        <thead class="bg-gray-50">
          <tr class="border-t border-gray-200 divide-x divide-gray-200">
            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <button
                  id="hs-pro-dutnms"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  ID
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <button
                  id="hs-pro-dutstatus"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Paid
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>
            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <button
                  id="hs-pro-dutlctn"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Invoice Number
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <button
                  id="hs-pro-dutlctn"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Fulfillment Date
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4 border-none">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <!-- Empty header as there will be no invoice source here -->
                <button
                  id="hs-pro-dutlctn"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                ></button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <button
                  id="hs-pro-duttenant"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Customer Billing Address
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer text-right"
              >
                <button
                  id="hs-pro-dutstatus"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Gross Total
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div
                class="hs-dropdown## relative inline-flex w-full cursor-pointer"
              >
                <button
                  id="hs-pro-dutstatus"
                  type="button"
                  class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Currency
                </button>
              </div>
              <!-- End Sort Dropdown -->
            </th>

            <th scope="col" class="px-4 py-2 leading-4">
              <!-- Sort Dropdown -->
              <div class="hs-dropdown relative w-52 ml-auto">
                <div
                  class="text-gray-500 uppercase text-xs justify-end w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                >
                  Actions
                </div>
              </div>
              <!-- End Sort Dropdown -->
            </th>
          </tr>
        </thead>
        @if(isLoadingInvoices){
        <tr>
          <td colspan="100">
            <div
              class="w-full flex justify-center content-center mt-16 min-h-28"
            >
              <img
                src="assets/img/loading.gif"
                alt="loading"
                class="h-8 w-auto"
              />
              <div class="ml-4">Loading...</div>
            </div>
          </td>
        </tr>

        } @else { @for (item of invoices; track item.id;) {
        <invoice-table-row
          [pitem]="item"
          actions="demo"
          class="divide-y divide-gray-200"
          (onSend)="handleMessaging($event)"
        ></invoice-table-row>
        } @empty {
        <tr>
          <td class="text-center" colspan="100">
            <!--  EMPTY STATE  -->
            <table-empty-state
              noDataTitle="There are no invoices"
            ></table-empty-state>
            <!--  END EMPTY STATE  -->
          </td>
        </tr>
        } }
      </table>
    </div>
  </div>
  }
  <!-- End Issues Table -->

  }
</layout-main>
