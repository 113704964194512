import { Injectable } from '@angular/core';
import { IUserModel } from './user-model.interface';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import {
  API_CALL_RETRY_COUNT,
  API_ENDPOINTS,
  ApiService,
} from '../api.service';
import {
  retry,
  catchError,
  Observable,
  map,
  throwError,
  forkJoin,
  switchMap,
} from 'rxjs';
import { UserTransformService } from './user-transform.service';

@Injectable({
  providedIn: 'root',
})
export class UserClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: UserTransformService
  ) {}

  /**
   * Felhasználók lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A felhasználók lekért oldala, nyers válaszban
   */
  private fetchUsers(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.users), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a felhasználók első oldalát, hogy megtudja az összes felhasználó számát
   * @returns Az összes felhasználó számossága
   **/
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchUsers().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Az összes felhasználó lekérése
   * @returns Az összes felhasználó adatai
   */
  getAllUsers(): Observable<IUserModel[]> {
    // megtudjuk hány user van összesen
    return this.getTotalItems().pipe(
      switchMap((totalItems) => {
        // kiszámoljuk hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];
        // minden oldalra kérünk egy-egy lekérést
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getUsers(i));
        }

        // visszaadjuk a felhasználókat
        return forkJoin(requests).pipe(
          map((results) => {
            let users: IUserModel[] = [];
            for (let result of results) {
              users.push(...result);
            }
            return users;
          })
        );
      })
    );
  }

  /**
   * Felhasználók lekérése
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A felhasználók listája
   */
  getUsers(page?: number): Observable<IUserModel[]> {
    return this.fetchUsers(page).pipe(
      map((response: any) => {
        return response['hydra:member'];
      })
    );
  }

  /**
   * Felhasználó lekérése azonosító alapján
   * @param id A felhasználó azonosítója
   * @returns A felhasználó adatai
   */
  getUser(id: number): Observable<IUserModel> {
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.user) + id)
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: any) => {
          return response['hydra:member'];
        })
      );
  }

  /**
   * Felhasználó lekérése IRI alapján
   * @param iri A felhasználó IRI-ja
   * @returns A felhasználó adatai
   */
  getUserByIri(iri: string): Observable<IUserModel> {
    return this.http.get(this.apiService.getBaseUrl() + iri).pipe(
      retry(API_CALL_RETRY_COUNT),
      catchError(this.handleError),
      map((response: any) => {
        return response as IUserModel;
      })
    );
  }
  /**
   * Egy felhasználó törlése
   * @param id A törlendő felhasználó azonosítója
   * @returns A törlés eredménye
   */
  deleteUser(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.user) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy felhasználó létrehozása
   * @param user Az új felhasználó adatai
   * @returns Az új felhasználó adatai
   */
  createUser(user: IUserModel): Observable<IUserModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(this.apiService.getUrlFor(API_ENDPOINTS.users), this.transformer.serializeItem(user), {
        headers,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IUserModel)
      );
  }

  /**
   * Egy felhasználó módosítása
   * @param user Az módosítandó felhasználó adatai
   * @returns Az módosított felhasználó adatai
   */
  updateUser(user: IUserModel): Observable<IUserModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(this.apiService.getUrlFor(API_ENDPOINTS.user) + user.id, this.transformer.serializeItem(user), {
        headers,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IUserModel)
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError(error: HttpErrorResponse) {
    this.log.error(
      'UserClientService error',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform user operation'));
  }
}
