<div class="">
  <div class="p-2">
    <form
      id="booking-flat-form"
      [formGroup]="bookingFlatForm"
    >
      <!--  Flat select  -->
      <label class="block mb-2 font-bold" for="booking-rooms-flats-select"
        >Rooms/apartments</label
      >
      <ng-select
        [items]="flatSelectItems"
        class="mb-4"
        labelForId="booking-rooms-flats-select"
        bindLabel="title"
        bindValue="val"
        [multiple]="true"
        placeholder="Select rooms/apartments"
        clearAllText="Clear"
        formControlName="flats"
        [virtualScroll]="true"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div>{{ item.title }}</div>
        </ng-template>
      </ng-select>

      <!--  Flat select  -->

      <div class="w-full max-w-lg text-right">
        <ui-button 
          (click)="saveBookingFlatInfo()" 
          iconSrc="assets/img/save.svg" 
          iconClass="invert sature-0 brightness-0 contrast-125" 
          size="xsmall"
          [disabled]="bookingFlatForm.invalid" 
          class="inline-block">
            Save
        </ui-button>        
      </div>
    </form>
  </div>
</div>
