<div class="mb-4 max-w-2xl">
  <div class="bg-white border shadow-sm rounded-xl">
    <div class="flex justify-between items-center py-6 px-6 border-b">
      <h3 class="font-bold text-gray-800">
        {{ mode === "edit" ? "Edit Issue" : "Issue #" + issue.id }}
      </h3>
    </div>
    <div class="p-4 overflow-y-auto">
      <div class="space-y-4">
        <form
          id="cleaning-request-form"
          [formGroup]="issueForm"
          (ngSubmit)="saveIssue()"
        >
          <div class="py-2 sm:py-4 sm:px-3">
            <div class="pt-2 space-y-5">
              <!-- Creator User Grid -->
              <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                <div class="sm:col-span-4">
                  <label
                    for="issueReporterUser"
                    class="sm:mt-2.5 inline-block text-sm text-gray-500"
                  >
                    Creator User
                  </label>
                </div>
                <div class="sm:col-span-8">
                  @if (mode === 'view') {
                  <p class="py-2 px-3 block w-full font-semibold">
                    @if(issue.issuerUser?.name){
                    {{
                      issue.issuerUser?.name +
                        " (" +
                        issue.issuerUser?.email +
                        ")"
                    }}
                    }@else { &ndash; }
                  </p>
                  } @else {

                  <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    [items]="userSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="issuerUser"
                    [placeholder]="
                      userSelectItems.length ? 'Select User' : 'No Users'
                    "
                    [virtualScroll]="true"
                  />
                  }
                </div>
              </div>
              <!-- End Creator User Grid -->
              <!-- Date Grid -->
              @if (mode === 'view') {
              <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                <div class="sm:col-span-4">
                  <label
                    for="issue.ateOfReporting"
                    class="sm:mt-2.5 inline-block text-sm text-gray-500"
                  >
                    Date Of Reporting
                  </label>
                </div>
                <div class="sm:col-span-8">
                  <p class="py-2 px-3 block w-full font-semibold">
                    {{ issue.created_at }}
                  </p>
                </div>
              </div>
              }
              <!-- End Grid -->
              <!-- Flat Grid-->
              <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                <div class="sm:col-span-4">
                  <label
                    for="issueFlat"
                    class="sm:mt-2.5 inline-block text-sm text-gray-500"
                  >
                    Flat
                  </label>
                </div>
                <div class="sm:col-span-8">
                  @if (mode === 'view') {
                  <p class="py-2 px-3 block w-full font-semibold">
                    @if(issue.flat?.flat_number){
                    {{ issue.flat?.flat_number + " " + issue.flat?.name }}
                    }@else { &ndash; }
                  </p>
                  } @else {
                  <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    [items]="flatSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="flat"
                    [virtualScroll]="true"
                    [placeholder]="
                      flatSelectItems.length ? 'Select Flat' : 'No Flats'
                    "
                  />
                  }
                </div>
              </div>
              <!-- End Flat Grid -->
              <!-- Issue Status Grid -->
              <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                <div class="sm:col-span-4">
                  <label
                    for="issueStatus"
                    class="sm:mt-2.5 inline-block text-sm text-gray-500"
                  >
                    Issue Status
                  </label>
                </div>
                <div class="sm:col-span-8">
                  @if (mode === 'view') {
                  <p class="py-2 px-3 block w-full font-semibold">
                    @if(issue.issueStatus?.name){
                    {{ issue.issueStatus?.name }}
                    }@else { &ndash; }
                  </p>
                  } @else {
                  <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    [items]="statusSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="issueStatus"
                    [placeholder]="
                      statusSelectItems.length ? 'Select Status' : 'No Status'
                    "
                  />
                  }
                </div>
              </div>
              <!-- End Issue Status Grid -->
              <!-- Delegated User Grid -->
              <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                <div class="sm:col-span-4">
                  <label
                    for="issueDelegatedUsers"
                    class="sm:mt-2.5 inline-block text-sm text-gray-500"
                  >
                    Assigned to
                  </label>
                </div>
                <div class="sm:col-span-8">
                  @if (mode === 'view') {
                  <p class="py-2 px-3 block w-full font-semibold">
                    @if(issue.delegatedUsers?.length){
                      <ul>
                        @for(user of issue.delegatedUsers; track user.id){
                        <li class="mb-2 text-blue-600 font-bold">
                              <span>{{ user.name }}</span>
                              <span class="font-normal">{{
                                " (" +
                                user?.email +
                                ")"
                            }}</span>
                        </li>
                        } @empty { &ndash; }
                      </ul>
                      } @else { &ndash; }
                  </p>
                  } @else {

                  <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    [items]="delegateUserSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="delegatedUsers"
                    [placeholder]="
                      userSelectItems.length ? 'Select Users' : 'No Users'
                    "
                    [virtualScroll]="true"
                    [multiple]="true"
                  />
                  }
                </div>
              </div>
              <!-- End Delegated User Grid -->
              <!-- Note Grid -->
              <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                <div class="sm:col-span-4">
                  <label
                    for="issueNote"
                    class="sm:mt-2.5 inline-block text-sm text-gray-500"
                  >
                    Note
                  </label>
                </div>
                <div class="sm:col-span-8">
                  @if (mode === 'view') {
                  <p class="py-2 px-3 block w-full font-semibold">
                    {{ issue.note }}
                  </p>
                  } @else {
                  <textarea
                    id="issueNote"
                    type="text"
                    class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder=""
                    [value]="issue.note"
                    formControlName="note"
                  ></textarea>
                  <!-- issue.note  -->
                  }
                </div>
              </div>
              <!-- End Note Grid -->
            </div>
          </div>
        </form>
      </div>
    </div>
    @if (mode == 'edit') {
    <div class="flex justify-end items-center gap-x-2 pb-4 px-5">
      <ui-button
        class="mx-2 mb-2"
        [disabled]="issueForm.invalid"
        (click)="saveIssue()"
      >
        Save
      </ui-button>
    </div>
    }
  </div>
</div>
